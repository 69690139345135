import React,{useState,useEffect} from "react";
import bgimage from '../../Images/Transfer/transfercover.jfif';
import Layout from "../../Components/Layout/Layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faDollar,faCar,} from '@fortawesome/free-solid-svg-icons';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector } from "react-redux";
import { RangeSlider } from 'rsuite';
import TransferCard from "../../Components/Transfer/TransferCard";
import TransferNew from "../../Components/SearchBar/TransferNew";

function TransferListing(){
  var minValue1=0;
  var maxValue1 = 0;
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const TransferDetail=useSelector((state) => state.hotels.TrSeResponse);
  if(TransferDetail?.transfers_list !== 0){
     var amounts = TransferDetail?.transfers_list.map(item => item.total_fare_markup);
        if(amounts.length !==0){
          minValue1 = Math.min(...amounts);
          maxValue1 = Math.max(...amounts);
        };
  };
  const [minValue, setMinValue] = useState(minValue1);
  const [showModifySearch, setShowModifySearch] = useState(false);
  const [showPriceFilter, setShowPriceFilter] = useState(false);
  const [showClassFilter, setShowClassFilter] = useState(false);
  const [maxValue, setMaxValue] = useState(maxValue1);
  const [SessionID, setSessionID] = useState(TransferDetail?.sessionID);
  const [rangeValue, setRangeValue] = useState([minValue1, maxValue1]);
  const [FilterData, setFilterData] = useState(TransferDetail?.transfers_list);
  const [vehicleClass, setVehicleClass] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);


  const handleChange = (newRangeValue) => {
    setRangeValue(newRangeValue);
    setMinValue(newRangeValue[0]);
    setMaxValue(newRangeValue[1]);
  };
  
  useEffect(()=>{
    setFilterData(TransferDetail?.transfers_list);
  },[TransferDetail]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
      if (checked) {
        setSelectedCheckboxes([...selectedCheckboxes, name]);
      } else {
        setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== name));
      }
  };

  const filterbyPrice=()=>{
    setShowPriceFilter(false);
    const filteredTours = TransferDetail?.transfers_list.filter(item => {
      const price = item.total_fare_markup;
        return price >= minValue && price <= maxValue;
    });
    setFilterData(filteredTours);
  };

  const filterbyClass=()=>{
    setShowClassFilter(false);
      if(selectedCheckboxes.length !==0){
        const filtered = TransferDetail?.transfers_list.filter(item => selectedCheckboxes.includes(item.vehicle_Name));
        setFilterData(filtered);
      }else{
        setFilterData(TransferDetail?.transfers_list);
      }
  };

    useEffect(()=>{
      VehicleClassfilter();
    },[TransferDetail]);

  const VehicleClassfilter=()=>{

    const VehicleCount = {};
    for (const vehicle of TransferDetail?.transfers_list) {
      
      const vehicleType =vehicle.vehicle_Name;
      if(vehicleType !==undefined){
          if (VehicleCount.hasOwnProperty(vehicleType)) {
            VehicleCount[vehicleType]++;
          } else {
            VehicleCount[vehicleType] = 1;
          }
       }
   
    setVehicleClass(VehicleCount);
  }
  };

  const ShowSearchBar=()=>{
    setShowModifySearch(!showModifySearch);
  };

  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice =price;
    } else {
      const conversionrate = GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency];
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0);
      var baseprice =newprice;
    }
    return baseprice
  };

  const TogglePriceFilter=()=>{
    setShowPriceFilter(!showPriceFilter)
  };

  const ToggleClassFilter=()=>{
    setShowClassFilter(!showClassFilter);
  };
    return(
        <>
            <Layout>
            <Modal isOpen={showPriceFilter} toggle={TogglePriceFilter}>
              <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
              <ModalBody>
              <div className='widget widget_price_filter'>
                          <div className='mb-0'>
                            <RangeSlider
                                value={rangeValue}
                                onChange={handleChange}
                                min={minValue1-1}
                                tooltip={false}
                                max={maxValue1+1}
                                step={1}
                              />
                                <div className='pt-2'>
                              <div className='fw-bold mb-2'>
                                Min: <span id='kt_slider_basic_min'>{renderPrice(minValue)}</span>
                              </div>
                              <div className='fw-bold mb-2'>
                                Max: <span id='kt_slider_basic_max'>{renderPrice(maxValue)}</span>
                              </div>
                            </div>
                            <button onClick={filterbyPrice}  className='btn select-styling search-btn1 mb-1'>Filter</button>
                          </div>
                        </div>
              </ModalBody>
            </Modal>
            <Modal isOpen={showClassFilter} toggle={ToggleClassFilter}>
              <ModalHeader toggle={ToggleClassFilter}>Vehicles</ModalHeader>
              <ModalBody>
              <div className='widget widget_has_radio_checkbox'>
                    <ul>
                    {Object.entries(vehicleClass).map(([code, count]) => (
                          <li key={code}>
                            <label>
                              <input
                                type='checkbox'
                                className="custom-textbox"
                                checked={selectedCheckboxes.includes(code)} // Check if checkbox is selected
                                onChange={handleCheckboxChange}
                                name={code}
                                value={code}
                              />
                              <i className='awe-icon awe-icon-check'></i>
                              <span className='rating'>{' '}
                              {code} ({count})
                              </span>
                            </label>
                          </li>
                        ))}

                    </ul>
                    <button onClick={filterbyClass}  className='btn select-styling search-btn1 mb-1'>Filter</button>

                  </div>
              </ModalBody>
            </Modal>
             <div className='container-fluid px-4'>
                <div className="row mt-2 p-3"> <TransferNew/></div>
                <div className='row mt-3'>
                    <div className='col-md-3 col-md-pull-9'>
                      <div class="mobile-Filter-info">
                          <ul>
                              <li><p onClick={TogglePriceFilter}><FontAwesomeIcon icon={faDollar}/> Price</p></li>
                              <li><p onClick={ToggleClassFilter}><FontAwesomeIcon icon={faCar}/><span > Vehicle Class</span></p></li>
                          </ul>
                      </div>
                      <div className='page-sidebar hide-page_filter'>
                        <h3 class="uitk-heading uitk-heading-5 my-3">Filter by</h3>
                        
                        {/* <div class="uitk-spacing field-section uitk-spacing-padding-blockend-four">
                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                            <h4 class="uitk-heading uitk-heading-7">Car type</h4>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-emphasis-theme uitk-spacing uitk-spacing-padding-blockstart-four uitk-spacing-padding-blockend-two uitk-spacing-padding-inlinestart-two uitk-layout-flex-item">From</div>
                          </div>
                          <fieldset class="uitk-checkbox-group">
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="sedan" id="sedan" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" />
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="sedan">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">Sedan</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$41</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="suv_grouped" id="suv_grouped" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" />
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="suv_grouped">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">SUV</span>
                                      <span class="is-visually-hidden">SUV cars from $46</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$46</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="van_grouped" id="van_grouped" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0"/>
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="van_grouped">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">Van</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$43</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="wagon" id="wagon" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0"/>
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="wagon">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">Station Wagon</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$45</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="luxury_grouped" id="luxury_grouped" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" />
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="luxury_grouped">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">Luxury</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$121</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div> */}
                        {/* <div class="uitk-spacing field-section uitk-spacing-padding-blockend-four">
                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                            <h4 class="uitk-heading uitk-heading-7">Capacity</h4>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-emphasis-theme uitk-spacing uitk-spacing-padding-blockstart-four uitk-spacing-padding-blockend-two uitk-spacing-padding-inlinestart-two uitk-layout-flex-item">From</div>
                          </div>
                          <fieldset class="uitk-checkbox-group">
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="TWO_TO_FIVE" id="TWO_TO_FIVE" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" />
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="TWO_TO_FIVE">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">2-5 passengers</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$41</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="SIX_OR_MORE" id="SIX_OR_MORE" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" />
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="SIX_OR_MORE">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">6 or more passengers</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$152</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div> */}
                        {/* <div class="uitk-spacing field-section uitk-spacing-padding-blockend-four">
                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                            <h4 class="uitk-heading uitk-heading-7">Traveler ratings</h4>
                            <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-emphasis-theme uitk-spacing uitk-spacing-padding-blockstart-four uitk-spacing-padding-blockend-two uitk-spacing-padding-inlinestart-two uitk-layout-flex-item">From</div>
                          </div>
                          <fieldset class="uitk-checkbox-group">
                            <legend class="is-visually-hidden">Traveler ratings</legend>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="PERCENT_70" id="PERCENT_70" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" />
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="PERCENT_70">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">70% positive &amp; up</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$47</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                              <input type="checkbox" name="PERCENT_40" id="PERCENT_40" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0"/>
                              <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                <label class="uitk-checkbox-label uitk-checkbox-label-haslabelsuffix" for="PERCENT_40">
                                  <span class="uitk-checkbox-label-content">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-space-between">
                                      <span class="" aria-hidden="true">40% positive &amp; up</span>
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-layout-flex-item uitk-spacing uitk-spacing-padding-inlinestart-two">
                                        <div aria-hidden="true">$41</div>
                                      </div>
                                    </div>
                                  </span>
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div> */}
                        <div className='widget widget_price_filter' style={{borderBottom:'0'}}>
                          <div className='mb-0'>
                            <h3 className='form-label'>Price Filter</h3>
                            <RangeSlider
                                value={rangeValue}
                                onChange={handleChange}
                                min={minValue1-1}
                                tooltip={false}
                                max={maxValue1+1}
                                step={1}
                              />
                              <div className='pt-2 d-flex' style={{justifyContent:'space-between'}}>
                                <div className='fw-bold mb-2'>
                                  Min: <span id='kt_slider_basic_min'>{renderPrice(minValue)}</span>
                                </div>
                                <div className='fw-bold mb-2'>
                                  Max: <span id='kt_slider_basic_max'>{renderPrice(maxValue)}</span>
                                </div>
                              </div>
                            <a onClick={filterbyPrice} className='select-styling mb-1'>Filter</a>
                          </div>
                        </div>
                        <div className='widget widget_has_radio_checkbox' style={{borderBottom:'0'}}>
                          <h3>Vehicles</h3>
                          <ul>
                          {Object.entries(vehicleClass).map(([code, count]) => (
                                <li key={code}>
                                  <label>
                                    <input
                                      type='checkbox'
                                      className="custom-textbox"
                                      checked={selectedCheckboxes.includes(code)} // Check if checkbox is selected
                                      onChange={handleCheckboxChange}
                                      name={code}
                                      value={code}
                                    />
                                    <i className='awe-icon awe-icon-check'></i>
                                    <span className='rating'>{' '}
                                    {code} ({count})
                                    </span>
                                  </label>
                                </li>
                              ))}
                          </ul>
                          <a onClick={filterbyClass}  className='select-styling mb-1'>Filter</a>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-9 col-md-push-3'>
                        <h6 className='title font-size-24 ms-3 tc' id='tours_result'>
                          {TransferDetail?.transfers_list.length} Results -Total includes taxes and fees
                          <section data-stid="results-header" class="uitk-spacing uitk-spacing-margin-blockstart-one">
                            <header style={{boxShadow:'none !important'}}>
                              <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid tour-main">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-5">
                                  <div data-stid="results-header-messages">
                                    <a href="https://www.expedia.com/lp/b/sort-order-info" target="_blank" rel="noopener" analytics="[object Object]" class="uitk-more-info-trigger uitk-more-info-trigger-size-small uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading">
                                      <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme"><b style={{fontWeight:'bold'}}>How our sort order works</b>
                                        <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlinestart-one uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                        </svg>
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-sort">
                                  <div class="">
                                    <form class="uitk-form">
                                      <div>
                                        <div class="uitk-field uitk-field-select-field has-floatedLabel-label">
                                          <label for="sort-filter-dropdown-sort" class="uitk-field-label is-visually-hidden">Sort by</label>
                                          <select id="sort-filter-dropdown-sort" name="sort" class="uitk-field-select">
                                            <option selected="" value="RECOMMENDED" aria-selected="true" data-opt-id="RECOMMENDED">Recommended</option>
                                            <option value="PRICE_LOW_TO_HIGH" aria-selected="false" data-opt-id="PRICE_LOW_TO_HIGH">Price: low to high</option>
                                            <option value="PRICE_HIGH_TO_LOW" aria-selected="false" data-opt-id="PRICE_HIGH_TO_LOW">Price: high to low</option>
                                            <option value="DISTANCE" aria-selected="false" data-opt-id="DISTANCE">Distance from downtown</option>
                                            <option value="REVIEW_RELEVANT" aria-selected="false" data-opt-id="REVIEW_RELEVANT">Guest rating + our picks</option>
                                            <option value="PROPERTY_CLASS" aria-selected="false" data-opt-id="PROPERTY_CLASS">Star rating</option>
                                            <option value="PACKAGE_SAVINGS" aria-selected="false" data-opt-id="PACKAGE_SAVINGS">Package discount</option>
                                          </select>
                                          <svg class="uitk-icon uitk-field-select-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                          </svg>
                                          <div class="uitk-field-label" aria-hidden="true">Sort by</div>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </header>
                          </section>
                        </h6>
                        <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 mt-3">
                            <div data-testid="one-key-banner-1" id="one-key-banner-1" className="px-0">
                                <div>
                                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme" data-stid="one-key-message-card">
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                                            <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                                <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                                    <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                                </div>
                                            </div>
                                            <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                                    <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                                        <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                                            <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">Earn OneKeyCash on top of airline miles when you sign in and book a flight</h2>
                                                        </div>
                                                    </div>
                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                                        <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                                          <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <TransferCard  TransferData={FilterData} sessionID= {SessionID}/>
                    </div>
                </div>
             </div>
        </Layout>
        </>
    );
}

export default TransferListing;