import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faLocation } from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Hotels/bg.jpg'
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ApiEndPoint,Hotelapitoken } from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
function HotelDetail () {
  const location = useLocation();
  const dispatch = useDispatch();
  const todayDate = new Date();
  var endpoint=ApiEndPoint();
  const { index } = location.state || {};
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeButton, setActiveButton] = useState(null);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPrice, setShowPrice] = useState(true);
  const [baseCName, setBaseCName] = useState('GBP');
  const [counter, setCounter] = useState(0);

  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([]);

  const navigate = useNavigate();
  const hoteldetail = useSelector(state => state.hotels.hoteldetail);
  const seleectedhotelfromlist = useSelector(
    state => state.hotels.hotels.hotels_list[index]
  );

  let { id } = useParams();
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const ReduxSearchData = useSelector(state => state.hotels.hsearch);
  
  let Checkin = moment(ReduxSearchData.check_in);
  let checkout = moment(ReduxSearchData.check_out);
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'));
  const latitude = hoteldetail.latitude;
  const longitude = hoteldetail.longitude;
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed';
 
  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName)
  };
  const handleButtonClick2 = (buttonName) => {
    setActiveButton(buttonName)
  };

  useEffect(() => {
    let NewArray = [];
    let occurrences = {};
    // Iterate over the adults and childs arrays
  for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
    let adult = ReduxSearchData.Adults[i];
    let child = ReduxSearchData.children[i];
 // Create a unique identifier for the pair
 let pairKey = `${adult}-${child}`;

 // Update the occurrences object
 occurrences[pairKey] = (occurrences[pairKey] || 0) + 1;

 // Only add to the result if the current qty is the maximum
 if (occurrences[pairKey] === 1) {
  NewArray.push({ adult, child, qty: occurrences[pairKey],status:false });
 } else {
     // Update the qty for the existing entry
     NewArray.find(entry => entry.adult === adult && entry.child === child).qty = occurrences[pairKey];
 }
}

    setResult(NewArray);
  }, [ReduxSearchData]);
  // const selectedRoomData = (index, event) => {
  //   event.preventDefault()
  //   event.stopPropagation()
    
  //   if (selectedRooms.some(room => room.index === index)) {
  //     const selectedroom = hoteldetail.rooms_options[index]
  //     setCounter(counter - selectedroom.rooms_qty)
  //     // Check if there's a room with the same index in selectedRooms
  //     const updatedSelectedRooms = selectedRooms.filter(
  //       room => room.index !== index
  //     )
  //     const newroom = hotelSelectedRooms.filter(item => item.index !== index)
  //     // Update the selectedRooms state with the new array
  //     setSelectedRooms(updatedSelectedRooms)
  //     setHotelSelectedRooms(newroom)
  //   } else {
  //     if (counter < ReduxSearchData.room) {
        
  //       const selectedroom = hoteldetail.rooms_options[index]
  //       const key = { rateKey: selectedroom.booking_req_id, index: index }
  //       const newSelectedRoom = {
  //         room_rate_key: JSON.stringify(key),
  //         rooms_qty: selectedroom.rooms_qty,
  //         index: index,
  //         roomdetail:selectedroom.adults+selectedroom.childs
  //       }
  //       setCounter(counter + selectedroom.rooms_qty)
  //       setSelectedRooms([...selectedRooms, newSelectedRoom])
  //       setHotelSelectedRooms([...hotelSelectedRooms, key])
  //     } else {
  //       toast.error('Select the room according to the search criteria.', {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  //     }
  //   }
  // };
  const selectedRoomData=(index, event)=>{
    event.preventDefault();
    event.stopPropagation();
    const selectedroom = hoteldetail.rooms_options[index];
      for (let i = 0; i < result.length; i++) {
        if(result[i].adult===selectedroom.adults &&  result[i].child===selectedroom.childs  &&  result[i].qty===selectedroom.rooms_qty)
        {
          if( result[i].status===false){
            const key = { rateKey: selectedroom.booking_req_id, index: index }
            const newSelectedRoom = {
              room_rate_key: JSON.stringify(key),
              rooms_qty: selectedroom.rooms_qty,
              index: index,
            }
            result[i].status=true;
            setSelectedRooms([...selectedRooms, newSelectedRoom])
            setHotelSelectedRooms([...hotelSelectedRooms, key])
          }else{
            if (selectedRooms.some(room => room.index === index)) {
              const updatedSelectedRooms = selectedRooms.filter(  room => room.index !== index )
                    const newroom = hotelSelectedRooms.filter(item => item.index !== index);
                    setSelectedRooms(updatedSelectedRooms);
                    setHotelSelectedRooms(newroom);
                    result[i].status=false;
            }else{
              toast.error('Select the room according to the search criteria.', {
                        position: toast.POSITION.TOP_RIGHT
                    });
            }
          }
         
        }
          
      }

  };
  const BookRoom = async () => {
    
    if(hotelSelectedRooms.length !==0){ 
      debugger
      if(hotelSelectedRooms.length !== result.length)
      {
            toast.error('Please Select the room according to the search criteria.', {
              position: toast.POSITION.TOP_RIGHT
          });
        return;
      }

    const roomdata = {
      rooms_select_data: JSON.stringify(selectedRooms),
      hotel_index: index,
      hotelbeds_select_room: hotelSelectedRooms
    }
    const apitoken=Hotelapitoken();
    const finaldata = {
      token:apitoken,
      request_data: JSON.stringify(roomdata),
      selected_hotel: JSON.stringify(seleectedhotelfromlist),
      selected_hotel_details: JSON.stringify({
        check_in: ReduxSearchData.check_in,
        check_out: ReduxSearchData.check_out,
        hotel_address: hoteldetail.hotel_address,
        hotel_country: hoteldetail.hotel_country,
        hotel_city: hoteldetail.hotel_city,
        atitude: hoteldetail.latitude,
        longitude: hoteldetail.longitude
      }),
      req_index: index
    }
    setLoading(true)
    try {
      const response = await Axios.post(
        endpoint+'/api/hotels/checkavailability_Live',
        finaldata,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            // 'Access-Control-Allow-Headers':
            //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      // Handle the API response here
      if(response.data.status==='error'){
        setLoading(false);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
      });
      return;
      };
      if(response.data !==''){
        dispatch(fetchCheckoutDetail(response.data.hotels_data))
        navigate('/hotel_checkout')
      }
      setLoading(false)
    } catch (error) {
      // Handle errors here
      setLoading(false);
      console.error('Error:', error)
    }
  }else{
    toast.error('Please Select Room First.', {
      position: toast.POSITION.TOP_RIGHT
  });
  }
  };

  const renderPrice = (price) =>{
    const userData = localStorage.getItem('HotelCurrency');
    const Rates = JSON.parse(userData);
      if(userData !==null){
          if(CurrencyRates===undefined){
            const gbpprice = Rates.conversion_rates[baseCName]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice) * Number(price))
        
          }else{
            var select123 = CurrencyRates.selectedcurrency;
            const gbpprice = Rates.conversion_rates[baseCName];
            var baseprice1 = (Number(gbpprice) * Number(price));
            const gbpprice2 = GBPCurrencyRates.conversion_rates[select123]; // Use square brackets to access the property
            var baseprice = (Number(gbpprice2) * Number(baseprice1));
          }
          return baseprice.toFixed(0)
       }else{
        setShowPrice(false);
       }
  };
  const calculateMarkup = (price) => {
    if(Object.keys(hoteldetail).length !==0){
    let markupprice=0;
    let adminmarkupprice=0;
    let clientmarkupprice=0;
    let finalpricemarkup=Number(price);
    if(Number(hoteldetail.admin_markup) !== 0)
   {
    if(hoteldetail.admin_markup_type === "Percentage")
    {
      markupprice=( price * Number(hoteldetail.admin_markup))/100;
    }else{
      markupprice= Number(hoteldetail.admin_markup);
     }
      adminmarkupprice=markupprice;
      finalpricemarkup +=markupprice
      
   }
   if(Number(hoteldetail.customer_markup) !== 0)
   {
    if(hoteldetail.customer_markup_type=== "Percentage")
    {
      markupprice= (finalpricemarkup * Number(hoteldetail.customer_markup))/100;
    }else{
      markupprice= Number(hoteldetail.customer_markup);
     }
     clientmarkupprice=markupprice;
      finalpricemarkup +=markupprice;
   }
   return finalpricemarkup.toFixed(2);
  }
  };
 
  return (
    <>
   
      {loading ? (
        <Loader />
      ) : (
        <Layout>
        <div className='uitk-spacing uitk-spacing-margin-medium-inline-six'>
          <ToastContainer/>
          <div style={{marginTop:'1px', backgroundColor:'white'}}>
            <div class="uitk-spacing uitk-spacing-padding-block-two uitk-spacing-padding-inline-two">
              <div class="uitk-layout-flex uitk-layout-flex-align-items-center" style={{minHeight:'3rem'}}>
                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                  <button type="button" role="link" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                    <svg class="uitk-icon uitk-spacing uitk-spacing-margin-inlineend-one uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                    </svg>See all properties
                  </button>
                </div>
                <div data-stid="favorite-save-button">
                  <div>
                    <div data-stid="outlined-save-button">
                      <div class="">
                        <button type="button" aria-label="Save property to a trip" class="favorite-button favorite-button-button-with-label">
                          <svg class="uitk-icon favorite-button-fill favorite-button-fill-pill" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="m12 22-8.44-8.69a5.55 5.55 0 0 1 0-7.72C4.53 4.59 5.9 4 7.28 4c1.4 0 2.75.59 3.72 1.59l1 1.02 1-1.02c.97-1 2.32-1.59 3.72-1.59 1.4 0 2.75.59 3.72 1.59a5.55 5.55 0 0 1 0 7.72L12 22Z" clip-rule="evenodd"></path>
                          </svg>
                          <svg class="uitk-icon favorite-button-border favorite-button-border-pill favorite-button-border-color-highlighed" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="m12 22 8.44-8.69a5.55 5.55 0 0 0 0-7.72A5.24 5.24 0 0 0 16.72 4 5.22 5.22 0 0 0 13 5.59L12 6.6l-1-1.02a5.2 5.2 0 0 0-7.44 0 5.55 5.55 0 0 0 0 7.72L12 22Zm0-2.87-7-7.21a3.55 3.55 0 0 1 0-4.94C5.6 6.36 6.44 6 7.28 6c.84 0 1.69.36 2.3.98L12 9.48l2.43-2.5c.6-.62 1.45-.98 2.29-.98.84 0 1.68.36 2.28.98a3.55 3.55 0 0 1 0 4.94l-7 7.2Z" clip-rule="evenodd" opacity=".9"></path>
                          </svg>
                          <span class="favorite-button-text">Save</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Images data={hoteldetail.hotel_gallery} />
            <div class="uitk-card-content-section uitk-card-content-section-border-block-end uitk-card uitk-card-roundcorner-all custom-css-flat-border-top custom-css-flat-border-bottom uitk-card-has-primary-theme" data-stid="uitk-card-content-section-navbar" style={{position:'sticky'}}>
              <div class="uitk-tabs-container">
                <ul class="uitk-tabs uitk-tabs-natural background-primary uitk-tabs-jumplink uitk-tabs-with-border" role="list">
                  <li role="listitem" className={activeButton === 'section-1' ? 'current' : 'uitk-tab'}>
                    <a href='#section-1' onClick={() => handleButtonClick2('section-1')} class="uitk-tab-anchor"
                        aria-current="true" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Overview</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  <li role="listitem" className={activeButton === 'section-3' ? 'current' : 'uitk-tab'}>
                    <a href='#section-3' onClick={() => handleButtonClick('section-3')} className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Amenities</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  <li role="listitem" className={activeButton === 'section-2' ? 'current' : 'uitk-tab'}>
                    <a href='#section-2' onClick={() => handleButtonClick2('section-2')} 
                      className="uitk-tab-anchor" aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Rooms</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  {/* <li role="listitem" className={activeButton === 'section-4' ? 'current' : 'uitk-tab'}>
                    <a  href='#section-4' onClick={() => handleButtonClick('section-4')}  className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Near By Place</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li> */}
                  <li role="listitem" className={activeButton === 'section-5' ? 'current' : 'uitk-tab'}>
                    <a href='#section-5'  onClick={() => handleButtonClick('section-5')} className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Policies</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  <li role="listitem" className={activeButton === 'section-6' ? 'current' : 'uitk-tab'}>
                    <a href='#section-6'  onClick={() => handleButtonClick('section-6')} className="uitk-tab-anchor"
                        aria-current="false" role="link" data-toggle="tab" draggable="false">
                      <span class="uitk-tab-text">Review & Rating</span>
                    </a>
                    <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li>
                  </li>
                  {/* <li aria-hidden="true" class="uitk-tab-highlighter" style={{transform: 'translateX(0px) scaleY(1) scaleX(1)', width: '94.625px'}}></li> */}
                </ul>
              </div>
              <div class="uitk-layout-position uitk-layout-position-right-zero uitk-layout-position-top-zero uitk-layout-position-bottom-zero uitk-layout-position-absolute mt-1 reserve-room"> 
                <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-justify-content-flex-end uitk-spacing">
                  <div class="uitk-layout-flex uitk-layout-flex-align-content-center uitk-layout-flex-align-items-center uitk-layout-flex-block-size-full-size uitk-layout-flex-item uitk-layout-flex-item-flex-grow-0 uitk-spacing uitk-spacing-padding-inlineend-three">
                    <div data-stid="navbar-quick-reserve-button">
                      {/* <button data-stid="sticky-button" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary">Reserve a room</button> */}
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            {/* <nav className='navbar view-detail-navbar'>
              <div className='container'>
                <ul>
                  <li>
                    <a
                      href='#section-1'
                      onClick={() => handleButtonClick2('section-1')}
                      className={activeButton === 'section-1' ? 'current' : ''}
                    >
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-2'
                      onClick={() => handleButtonClick2('section-2')}
                      className={activeButton === 'section-2' ? 'current' : ''}
                    >
                      Rooms
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-3'
                      onClick={() => handleButtonClick('section-3')}
                      className={activeButton === 'section-3' ? 'current' : ''}
                    >
                      Facilities
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-4'
                      onClick={() => handleButtonClick('section-4')}
                      className={activeButton === 'section-4' ? 'current' : ''}
                    >
                      Near By Place
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-5'
                      onClick={() => handleButtonClick('section-5')}
                      className={activeButton === 'section-5' ? 'current' : ''}
                    >
                      Location
                    </a>
                  </li>
                  <li>
                    <a
                      href='#section-6'
                      onClick={() => handleButtonClick('section-6')}
                      className={activeButton === 'section-6' ? 'current' : ''}
                    >
                      Review & Rating
                    </a>
                  </li>
                </ul>
              </div>
            </nav> */}
          </div>
          <div className='container-fluid mt-2 rounded-3'>
            <section id='section-1' className='uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-spacing uitk-flat-border-top uitk-spacing-margin-blockend-three uitk-spacing-padding-large-inline-six uitk-card-has-primary-theme'>
              <h3>{hoteldetail.hotel_name}</h3>
              <div className='row'>
                <div className='col-md-8'>
                  <i class='awe-icon fa tc fa-check' aria-hidden='true'>
                    <FontAwesomeIcon icon={faLocation} />
                  </i>{' '}
                  {hoteldetail.hotel_address}
                  <div className='row mt-2 mb-2 p-2'>
                    <h6 className='mb-2'>Popular Amenities</h6>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            Hotel Type
                          </p>
                          <p
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          ></p>
                        </div>
                      </div>
                    </div>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            Minimum Stay
                          </p>
                          <p
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          >
                            {daysBetween} Night
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            Check In
                          </p>
                          <p
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          >
                            {moment(ReduxSearchData.check_in).format('DD-MM-YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            Check Out
                          </p>
                          <p
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          >
                            {moment(ReduxSearchData.check_out).format('DD-MM-YYYY')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            Country
                          </p>
                          <p
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          >
                            {hoteldetail.hotel_country}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            City
                          </p>
                          <p
                            className='mt-0'
                            style={{ fontSize: '12px' }}
                            class='title '
                          >
                            {hoteldetail.hotel_city}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            Boards
                          </p>
                          {hoteldetail.hotel_boards.map((item, index) => (
                            <span
                              key={index}
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {item.board_name}{' '}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className=' col-sm-3 col-6 col-md-6 col-lg-6 mt-1'>
                      <div class='single-tour-feature d-flex align-items-center mb-3'>
                        <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                          <i class='fas fa-check'>
                            <FontAwesomeIcon icon={faCheck} />
                          </i>
                        </div>
                        <div class='single-feature-titles'>
                          <p style={{ fontSize: '13px' }} class='title fw-bold'>
                            Segments
                          </p>
                          {hoteldetail.hotel_segments.map((item, index) => (
                            <span
                              key={index}
                              className='mt-0'
                              style={{ fontSize: '12px' }}
                              class='title '
                            >
                              {item}{' '}
                            </span>
                          ))}{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>  
                   <h5 className='mb-2'>Explore the area</h5>
                  <div style={{ width: '100%' }}>
                    <iframe
                      width='100%'
                      height='250'
                      frameborder='0'
                      scrolling='no'
                      marginheight='0'
                      marginwidth='0'
                      src={mapUrl}
                      className='rounded-4'
                    ></iframe>
                  </div>
                </div>
              </div>
            </section>
            <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 my-2">
              <div data-testid="one-key-banner-1" id="one-key-banner-1" className='px-0'>
                  <div>
                      <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0" data-stid="one-key-message-card">
                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                              <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                  <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                      <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                  </div>
                              </div>
                              <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                          <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                              <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">You'll always get our best prices when you're signed in!</h2>
                                          </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                          <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                            <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                            <a href="https://www.expedia.com/welcome-one-key?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UITertiaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-tertiary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-two uitk-button-tertiary-inverse">Create an account</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <section id='section-2' className='mt-3'>
              <h2 class="uitk-heading uitk-heading-4 mb-2" tabindex="-1">Choose your room</h2>
              <HotelSearch/>
              <div class="uitk-spacing uitk-spacing-margin-block-three">
                <div class="uitk-layout-grid uitk-layout-grid-align-items-center uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid room-tabs" >
                  <span class="is-visually-hidden">Available filters for rooms</span>
                  <div class="uitk-pill-container">
                    <div class="uitk-pill">
                      <input id="ALLROOMS" aria-checked="true" type="checkbox" class="uitk-pill-standard is-visually-hidden" value="true" checked="" />
                      <label class="uitk-pill-content" for="ALLROOMS" aria-label="All rooms">
                        <span class="uitk-pill-text">All rooms</span>
                      </label>
                    </div>
                    <div class="uitk-pill">
                      <input id="1BED" aria-checked="false" type="checkbox" class="uitk-pill-standard is-visually-hidden" value="false" />
                      <label class="uitk-pill-content" for="1BED" aria-label="1 bed">
                        <span class="uitk-pill-text">1 bed</span>
                      </label>
                    </div>
                    <div class="uitk-pill">
                      <input id="2BEDS" aria-checked="false" type="checkbox" class="uitk-pill-standard is-visually-hidden" value="false"/>
                      <label class="uitk-pill-content" for="2BEDS" aria-label="2 beds">
                        <span class="uitk-pill-text">2 beds</span>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-4 uitk-text uitk-type-300 uitk-text-white-space-nowrap uitk-text-default-theme uitk-layout-grid-item">Showing 2 of 2 rooms</div>
                </div>
              </div>
              <div className='row'>
                  {hoteldetail.rooms_options.map((item, index) => (
                    <div
                      key={index}
                      className='col-md-4 pt-2 pb-2'
                    >
                      <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-overflow uitk-card-has-primary-theme" data-stid="property-offer-1">
                        <div class="uitk-layout-flex-item">
                          <div>
                            <span>
                              <div class="uitk-gallery-carousel uitk-rounded-border-top">
                                <h3 class="is-visually-hidden">View all photos for Deluxe Room</h3>
                                <div class="uitk-gallery-carousel-items ratio-16-9">
                                  {/* <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                    <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                                      <div class="uitk-image-placeholder">
                                      </div>
                                    </figure>
                                  </div> */}
                                  <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                    <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-16-9 uitk-image-ratio">
                                      {item.rooms_images && item.rooms_images.length > 0 ? (
                                      <div class="uitk-image-placeholder">
                                        <img alt="Laptop workspace, iron/ironing board, free WiFi, bed sheets" class="uitk-image-media" src={item.rooms_images[0]} data-loaded="true"/>
                                      </div>
                                      ) : (
                                        <img alt="City view" class="uitk-image-media" src="https://images.trvl-media.com/lodging/92000000/91060000/91053400/91053391/eb43f08b.jpg?impolicy=fcrop&amp;w=1200&amp;h=800&amp;p=1&amp;q=medium" data-loaded="true"/>
                                      )}
                                    </figure>
                                  </div>
                                </div>
                                <div class="uitk-gallery-carousel-paging-controls">
                                  <button theme="solid-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging">
                                    <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous image" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <title id="prev-button-property-offers-media-carousel-1-title">Show previous image</title>
                                      <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                    </svg>
                                  </button>
                                  <button theme="solid-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging">
                                    <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next image" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <title id="next-button-property-offers-media-carousel-1-title">Show next image</title>
                                      <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                    </svg>
                                  </button>
                                </div>
                                {/* <div class="uitk-gallery-carousel-box">
                                  <div class="uitk-gallery-carousel-footer">
                                    <div class="uitk-action-buttons">
                                      <button type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-overlay uitk-gallery-button">
                                        <svg class="uitk-icon uitk-icon-leading" aria-label="Show all 16 images for Deluxe Room" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <title id="photo_library-property-offers-media-carousel-1-title">Show all 16 images for Deluxe Room</title>
                                          <path fill-rule="evenodd" d="M22 16V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2zm-11-4 2.03 2.71L16 11l4 5H8l3-4zm-9 8V6h2v14h14v2H4a2 2 0 0 1-2-2z" clip-rule="evenodd"></path>
                                        </svg>
                                        <span aria-hidden="true">16</span>
                                      </button>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </span>
                          </div>
                          <div class="uitk-spacing uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-two uitk-spacing-padding-inline-three">
                            <div class="uitk-spacing uitk-spacing-padding-small-blockend-half">
                              <h3 class="uitk-heading uitk-heading-6">{item.room_name}</h3>
                            </div>
                            <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-three" role="list">
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-bullet-icon-default-theme uitk-typelist-item-indent" role="listitem">
                                <div class="uitk-typelist-item-icon">
                                  <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6 18h2l-3 3-3-3h2V6c0-1.1.9-2 2-2h12V2l3 3-3 3V6H6v12zm14-8v2h-2v-2h2zm0 8a2 2 0 0 1-2 2v-2h2zm0-4v2h-2v-2h2zm-4 4v2h-2v-2h2zm-4 0v2h-2v-2h2z"></path>
                                  </svg>
                                </div>225 sq ft
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-bullet-icon-default-theme uitk-typelist-item-indent" role="listitem">
                                <div class="uitk-typelist-item-icon">
                                  <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="m1 9 2 2a12.73 12.73 0 0 1 18 0l2-2A15.57 15.57 0 0 0 1 9zm8 8 3 3 3-3a4.24 4.24 0 0 0-6 0zm-2-2-2-2a9.91 9.91 0 0 1 14 0l-2 2a7.07 7.07 0 0 0-10 0z" clip-rule="evenodd"></path>
                                  </svg>
                                </div>Free WiFi
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-bullet-icon-default-theme uitk-typelist-item-indent" role="listitem">
                                <div class="uitk-typelist-item-icon">
                                  <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M6 3h7a6 6 0 0 1 0 12h-3v6H6V3zm4 8h3.2a2 2 0 0 0 2-2 2 2 0 0 0-2-2H10v4z" clip-rule="evenodd"></path>
                                  </svg>
                                </div>Free self parking
                              </li>
                              <li class="uitk-typelist-item uitk-typelist-item-bullet-icon-standard uitk-typelist-item-bullet-icon uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-bullet-icon-default-theme uitk-typelist-item-indent" role="listitem">
                                <div class="uitk-typelist-item-icon">
                                  <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M10.99 8A3 3 0 1 1 5 8a3 3 0 0 1 6 0zm8 0A3 3 0 1 1 13 8a3 3 0 0 1 6 0zM8 13c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm7.03.05c.35-.03.68-.05.97-.05 2.33 0 7 1.17 7 3.5V19h-6v-2.5c0-1.48-.81-2.61-1.97-3.45z" clip-rule="evenodd"></path>
                                  </svg>
                                </div>Sleeps 2
                              </li>
                            </ul>
                            {/* <ul>
                              {item.rooms_facilities &&
                              item.rooms_facilities.length > 0 ? (
                                <RoomFacilities item={item}/>
                            
                              ) : (
                                <li>No facilities available</li>
                              )}
                            </ul> */}
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid uitk-spacing uitk-spacing-margin-block-two" data-stid="highlightedMessages-321661323">
                              <div class="uitk-layout-flex">
                                <div class="uitk-spacing uitk-spacing-padding-blockstart-half">
                                {item.cancliation_policy_arr &&
                                 item.cancliation_policy_arr.length > 0 ? (
                              new Date(
                                item.cancliation_policy_arr[0].from_date
                              ) > todayDate ? (
                                  <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">No cancellation fee before {moment(item.cancliation_policy_arr[0].from_date).format('DD-MM-YYYY')} (property local time)
                                      <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                  </button>
                              ) : (
                                  <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">Non-Refundable
                                      <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                  </button>
                                  )
                                ) : 
                                  <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme">Non-Refundable
                                      <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inline-two uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                  </button>
                                }
                                </div>
                              </div>
                            </div>
                            <div class="uitk-card-content-section uitk-spacing uitk-spacing-margin-blockstart-three" data-stid="section-roomtype">
                              <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">
                                <span aria-hidden="true">More details</span>
                                <span class="is-visually-hidden">More details for Deluxe Room</span>
                                <svg class="uitk-icon uitk-link-icon-medium uitk-icon-directional uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                </svg>
                              </button>
                            </div>
                          </div>
                          
                           {/* <div class="uitk-card-content-section uitk-card-content-section-border-block-start">
                          <div class="uitk-spacing uitk-spacing-margin-inline-three uitk-spacing-margin-blockstart-three">
                            <div class="uitk-card-content-section uitk-spacing uitk-spacing-padding-inline-half">
                              <div class="uitk-spacing">
                                <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-one">
                                  <h4 class="uitk-heading uitk-heading-7 uitk-layout-flex-item">Cancellation policy</h4>
                                </div>
                                <div class="uitk-spacing uitk-spacing-padding-blockstart-half uitk-spacing-padding-blockend-one">
                                  <button type="button" class="uitk-link uitk-spacing uitk-spacing-padding-block-two uitk-link-align-left uitk-link-layout-default uitk-link-medium" data-stid="property-offers-secondary-dialog-link">
                                    <div class="uitk-text uitk-type-200 uitk-text-default-theme" data-stid="property-offers-secondary-dialog-trigger">More details on all policy options
                                      <svg class="uitk-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                      </svg>
                                    </div>
                                  </button>
                                </div>
                              </div>
                              <fieldset class="uitk-radio-button-group" aria-labelledby="legend-w54k35">
                                <legend class="is-hidden" id="legend-w54k35">Cancellation policy</legend>
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                    <label class="uitk-radio-button-label uitk-radio-button-label-haslabelsuffix" for="LodgingCategorizedUnit-2-cancellationPolicy-0">
                                      <input type="radio" name="LodgingCategorizedUnit-2-cancellationPolicy-2" id="LodgingCategorizedUnit-2-cancellationPolicy-0" class="" aria-label="Non-Refundable, + $0." aria-describedby="radio-button-description-nn62s" value="LodgingCategorizedUnit-2-cancellationPolicy-0" checked="" />
                                      <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                      <div aria-hidden="false" class="uitk-radio-button-label-content">
                                        <span aria-hidden="true">Non-Refundable</span>
                                      </div>
                                      <span aria-hidden="true" class="uitk-radio-button-label-suffix">+ $0</span>
                                    </label>
                                  </div>
                                </div>
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                    <label class="uitk-radio-button-label uitk-radio-button-label-haslabelsuffix" for="LodgingCategorizedUnit-2-cancellationPolicy-1">
                                      <input type="radio" name="LodgingCategorizedUnit-2-cancellationPolicy-2" id="LodgingCategorizedUnit-2-cancellationPolicy-1" class="" aria-label="Partially refundable, + $4." aria-describedby="radio-button-description-qrma3d" value="LodgingCategorizedUnit-2-cancellationPolicy-1"/>
                                      <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                      <div aria-hidden="false" class="uitk-radio-button-label-content">
                                        <span aria-hidden="true">Partially refundable</span>
                                      </div>
                                      <span aria-hidden="true" class="uitk-radio-button-label-suffix">+ $4</span>
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                          </div>
                        </div>  */}
                        </div>
                        <div class="uitk-card-content-section uitk-layout-flex-item-align-self-stretch uitk-layout-flex-item">
                          <div class="uitk-spacing uitk-spacing-margin-three">
                            <div>
                              <div>
                                <span class="uitk-badge uitk-badge-large uitk-badge-deal-generic uitk-badge-has-text uitk-spacing uitk-spacing-margin-blockend-one">
                                  <span class="uitk-badge-text" aria-hidden="false">32% off</span>
                                </span>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-end uitk-layout-flex-justify-content-space-between">
                                <div class="uitk-layout-flex-item">
                                  <div>
                                      <div data-stid="price-summary">
                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                          <div>
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                              <div class="uitk-spacing uitk-spacing-padding-block-half">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">The price is 
                                                {showPrice ?(
                                                          <h6>{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {renderPrice(calculateMarkup(item.rooms_total_price))}</h6>
                                                          ):(
                                                            <h6>{hoteldetail.hotel_curreny} {calculateMarkup(item.rooms_total_price)}</h6>
                                                          )}
                                                </div>
                                                <span aria-hidden="true">
                                                  <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">
                                                      {showPrice ?(
                                                        <h6>{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {renderPrice(calculateMarkup(item.rooms_total_price))}</h6>
                                                        ):(
                                                          <h6>{hoteldetail.hotel_curreny} {calculateMarkup(item.rooms_total_price)}</h6>
                                                        )}
                                                  </div>
                                                  <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">
                                                  <h6 >Room Capacity</h6>
                                                  <h6>
                                                    {' '}
                                                    {item.adults} Adults , {item.childs} children
                                                  </h6>
                                                  <h6> {item.rooms_qty} Rooms</h6>
                                                  </div>
                                                </span>
                                              </div>
                                              {/* <button type="button" class="neutral-color-link neutral-color-link-align-left neutral-color-link-layout-inline neutral-color-link-medium" data-stid="disclaimer-dialog-link">
                                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                  <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                    <del>
                                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">The price was $50</div>
                                                      <span aria-hidden="true">
                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme">
                                                        {showPrice ?(
                                                          <h6>{CurrencyRates===undefined ? (baseCName):(CurrencyRates.selectedcurrency)} {renderPrice(calculateMarkup(item.rooms_total_price))}</h6>
                                                          ):(
                                                            <h6>{hoteldetail.hotel_curreny} {calculateMarkup(item.rooms_total_price)}</h6>
                                                          )}
                                                        </div>
                                                      </span>
                                                    </del>
                                                  </div>
                                                </div>
                                              </button> */}
                                            </div>
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                              <div class="uitk-text uitk-type-start uitk-type-200 uitk-text-default-theme">Price For {daysBetween} Nights</div>
                                            </div>
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                              <div class="uitk-text uitk-type-start uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class='form-group' data-toggle='buttons'>
                                        <label
                                          onClick={event =>
                                            selectedRoomData(index, event)
                                          }
                                          class='btn btn-default mt-2 select-room--checkbox primary'
                                        >
                                          <i class='fa fa-fw'></i>
                                          <input
                                            id={index}
                                            autoComplete='off'
                                            className='room-check'
                                            type='checkbox'
                                            checked={selectedRooms.some(
                                              room => room.index === index
                                            )}
                                            onChange={() => {}} // Add an empty onChange handler to prevent warnings
                                          />
                                          Select Room
                                        </label>
                                      </div>
                                  </div>
                                </div>
                                {/* <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-end uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-two uitk-layout-flex-item-align-self-flex-end uitk-layout-flex-item">
                                  <div class="uitk-layout-flex uitk-layout-flex-justify-content-flex-end">
                                    <button type="submit" data-stid="submit-hotel-reserve" role="link" class="uitk-button uitk-button-medium uitk-button-primary" onClick={BookRoom}>
                                      <span aria-hidden="true">Reserve</span>
                                      <span class="is-visually-hidden">Reserve Deluxe Room</span>
                                    </button>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                          {/* <div className='col-md-4 room-detail-center item-from'>
                            <h6 className='tc'>Meal Type</h6>
                            <h6>{item.board_id}</h6>
                          </div> */}
                          {/* <div className='col-md-4 text-center item-from'>
                            <h6 className='tc'>Room Capacity</h6>
                            <h6>
                              {' '}
                              {item.adults} Adults , {item.childs} children
                            </h6>
                            <h6> {item.rooms_qty} Rooms</h6>
                          </div> */}
                    </div>
                  ))}
              </div>
              <div>
                <div class="lazyload-wrapper uitk-spacing uitk-spacing-margin-block-six">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                    <section>
                      <div class="uitk-card-content-section">
                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three about-uit">
                          <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large sub-about1">
                            <div class="uitk-layout-flex">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                <h2 class="uitk-heading uitk-heading-4" tabindex="-1">About this property</h2>
                              </div>
                            </div>
                          </div>
                          <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-about">
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three sub-about-desc">
                              <div data-stid="content-item" itemprop="description" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                <div class="uitk-layout-flex">
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                    <h3 class="uitk-heading uitk-heading-5" tabindex="-1">{hoteldetail.hotel_name}</h3>
                                  </div>
                                </div>
                                <div class="uitk-expando-peek">
                                  <div class="uitk-expando-peek-main uitk-expando-peek-main-active uitk-expando-peek-main-whitespace">
                                    <div class="uitk-expando-peek-inner display-lines">
                                      <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">{hoteldetail.hotel_address}</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">{hoteldetail.description} </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">You'll also enjoy perks such as:</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two" role="list">
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Free self parking and valet parking</span>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Luggage storage, 1 meeting room, and a 24-hour front desk</span>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Free newspapers, tour/ticket assistance, and smoke-free premises</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">Room features</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup"> 
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">All guestrooms at {hoteldetail.hotel_name}- ALL Suite Hotel offer comforts such as 24-hour room service and premium bedding, as well as perks like air conditioning and bathrobes. </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">Other amenities include: </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                            <div data-stid="content-markup">
                                              <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two" role="list">
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Heating and ceiling fans</span>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Showers, hair dryers, and shampoo</span>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Smart TVs with digital channels</span>
                                                </li>
                                                <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                  <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                  <span class="uitk-typelist-item-child">Wardrobes/closets, kitchenettes, and mini fridges</span>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-expando-peek-control uitk-expando-peek-control-active">
                                    <button type="button" aria-label="See less" analytics="[object Object]" class="uitk-link uitk-expando-peek-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" aria-expanded="true">See less</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-about">
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three">
                              <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                    <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Languages</h3>
                                  </div>
                                </div>
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                  <div class="uitk-layout-grid-item">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                      <div class="uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-layout-flex-item">English, Hindi, Urdu</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
            {/* <div class="uitk-spacing uitk-spacing-margin-block-six">
              <div class="lazyload-wrapper ">
                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-card-padded uitk-card-has-primary-theme">
                  <section id='section-3'>
                    <div class='property-highlights'>
                      <h3>Property highlights</h3>
                      <div class='property-highlights__content'>
                        <div class='row'>
                          {hoteldetail.hotel_facilities===null || hoteldetail.hotel_facilities==='' || hoteldetail.hotel_facilities.length===0 ? (
                            <h5> No Facilities Available</h5>
                          ):(
                            <>
                            {hoteldetail.hotel_facilities.map((item, index) => (
                              <div key={index} class='col-md-3 col-6'>
                                <div class='item'>
                                  <i
                                    style={{ color: '#d39d00' }}
                                    class='awe-icon fa fa-check'
                                    aria-hidden='true'
                                  >
                                    <FontAwesomeIcon icon={faCheck} />
                                  </i>{' '}
                                  <span>{item}</span>
                                </div>
                              </div>
                            ))}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div> */}
            <div>
              <div id="Amenities" class="uitk-spacing uitk-spacing-margin-block-six">
                <div class="lazyload-wrapper "></div>
              </div>
              <div id="Accessibility" class="uitk-spacing">
                <div class="lazyload-wrapper ">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing uitk-card-roundcorner-all uitk-spacing-padding-block-six uitk-spacing-padding-small-inline-three uitk-spacing-padding-large-inline-six uitk-card-has-primary-theme">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-access">
                      <div class="uitk-layout-columns uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                        <h2 class="uitk-heading uitk-heading-4 uitk-spacing uitk-spacing-padding-inlineend-two">Accessibility</h2>
                        <p class="uitk-paragraph uitk-paragraph-2 uitk-spacing uitk-spacing-padding-blockstart-one uitk-spacing-padding-blockend-three">If you have requests for specific accessibility needs, please contact the property using the information on the reservation confirmation received after booking.</p>
                      </div>
                      <div class="uitk-layout-columns uitk-layout-columns-gap-twelve uitk-layout-columns-minwidth-half_width uitk-layout-grid-item">
                        <div class="uitk-layout-flex uitk-layout-flex-gap-two">
                          <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlineend-one uitk-layout-flex-item" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m21.61 21.41-.47-1.1a.49.49 0 0 0-.16-.2.4.4 0 0 0-.23-.06h-.84V7.4a.4.4 0 0 0-.12-.28.38.38 0 0 0-.29-.13h-3.75V2.41a.4.4 0 0 0-.12-.28.38.38 0 0 0-.29-.13H4.5a.38.38 0 0 0-.3.13.4.4 0 0 0-.11.28v17.64h-.84a.4.4 0 0 0-.23.07.49.49 0 0 0-.16.18l-.47 1.11a.44.44 0 0 0-.03.2c0 .08.03.14.07.2a.38.38 0 0 0 .33.2h18.48a.38.38 0 0 0 .33-.2.36.36 0 0 0 .07-.2c0-.06 0-.13-.03-.2zM9.09 17h-2.5v-2.5h2.5V17zm0-5h-2.5V9.5h2.5V12zm0-5h-2.5V4.5h2.5V7zm4.16 12.77h-2.5V14.5h2.5v5.27zm0-7.77h-2.5V9.5h2.5V12zm0-5h-2.5V4.5h2.5V7zm4.16 10h-2.5v-2.5h2.5V17zm0-5h-2.5V9.5h2.5V12z"></path>
                          </svg>
                          <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-padding-inlineend-two" tabindex="-1">Common areas</h3>
                        </div>
                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-padding-inlinestart-six uitk-spacing-padding-blockend-four uitk-spacing-padding-blockstart-two" role="list">
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                              <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair accessible (may have limitations)</div>
                              <meta itemprop="value" />
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Accessible parking space</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Elevator</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Steps to reach entrance</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair-accessible business center</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair-accessible path to elevator</div>
                            <meta itemprop="value"/>
                          </li>
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Wheelchair-accessible restaurant</div>
                            <meta itemprop="value"/>
                          </li>
                        </ul>
                      </div>
                      <div class="uitk-layout-columns uitk-layout-columns-gap-twelve uitk-layout-columns-minwidth-half_width uitk-layout-grid-item">
                        <div class="uitk-layout-flex uitk-layout-flex-gap-two">
                          <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlineend-one uitk-layout-flex-item" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 19V4h-4V3H5v16H3v2h12V6h2v15h4v-2h-2zm-6 0H7V5h6v14zm-3-8h2v2h-2v-2z"></path>
                          </svg>
                          <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-padding-inlineend-two" tabindex="-1">Rooms</h3>
                        </div>
                        <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-padding-inlinestart-six uitk-spacing-padding-blockend-four uitk-spacing-padding-blockstart-two" role="list">
                          <li class="uitk-spacing uitk-spacing-margin-inlinestart-four" role="listitem">
                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Area rug in room</div>
                            <meta itemprop="value"/>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div id="section-5" class="uitk-spacing uitk-spacing-margin-block-six">
                <div class="lazyload-wrapper ">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                    <section>
                      <div class="uitk-card-content-section">
                        <div id="Policies" tabindex="-1">
                          <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three policy-uitk">
                            <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large">
                              <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                  <h2 class="uitk-heading uitk-heading-4" tabindex="-1">Policies</h2>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-expando-peek uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-policy">
                              <div class="uitk-expando-peek-main">
                                <div class="uitk-expando-peek-inner" data-items-to-display="0">
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk1">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Check-in</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Check-in start time: 2 PM; Check-in end time: 6 PM</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Late check-in subject to availability</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Minimum check-in age: 18 </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Check-out</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                             <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Check-out before noon</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Special check-in instructions</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">This property offers transfers from the airport; guests must contact the property with arrival details before travel, using the contact information on the booking confirmation</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Front desk staff will greet guests on arrival</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">At check-in, guests must provide a record of full COVID-19 vaccination</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">COVID-19 vaccination record requirement applies to all guests aged 12 and up; guests must have received complete COVID-19 vaccination at least 14 days prior to check-in</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Access methods</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Staffed front desk</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" itemprop="petsAllowed" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Pets</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">No pets or service animals allowed</div>
                                            <meta itemprop="petsAllowed" content="No pets or service animals allowed" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                          <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Children and extra beds</h3>
                                        </div>
                                      </div>
                                      <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                        <div class="uitk-layout-grid-item">
                                          <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Children are welcome</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Rollaway/extra beds are available for PKR 3000.0 per day</div>
                                            <div class="uitk-text uitk-text-spacing-two uitk-type-300 uitk-text-default-theme">Cribs (infant beds) are not available</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" itemprop="paymentAccepted" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Property payment types</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div data-stid="content-image" class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                              <div class="uitk-layout-flex uitk-layout-flex-gap-half uitk-layout-flex-flex-wrap-wrap">
                                                <div class="LazyLoad is-visible uitk-layout-flex-item" style={{height: '64px'}}>
                                                  <img src="https://a.travel-assets.com/dms-svg/payments/cards-cc_master_card.svg" alt="Mastercard" height="100%"/>
                                                  <meta itemprop="paymentAccepted" content="Mastercard"/>
                                                </div>
                                                <div class="LazyLoad is-visible uitk-layout-flex-item" style={{height: '64px'}}>
                                                  <img src="https://a.travel-assets.com/dms-svg/payments/cards-cc_visa.svg" alt="Visa" height="100%"/>
                                                  <meta itemprop="paymentAccepted" content="Visa"/>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div id="Policies" class="uitk-spacing uitk-spacing-margin-block-six">
                  <div class="lazyload-wrapper ">
                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                      <section>
                        <div class="uitk-card-content-section">
                          <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-small-inline-unset uitk-spacing-padding-medium-inline-unset uitk-spacing-padding-large-inline-three uitk-spacing-padding-extra_large-inline-three uitk-spacing-padding-blockend-three uitk-spacing-padding-blockstart-three policy-uitk">
                            <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large">
                              <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                    <h2 class="uitk-heading uitk-heading-4" tabindex="-1">Important information</h2>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-expando-peek uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large sub-policy">
                              <div class="uitk-expando-peek-main">
                                <div class="uitk-expando-peek-inner" data-items-to-display="0">
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" itemprop="currenciesAccepted" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">Optional extras</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing uitk-spacing uitk-spacing-margin-blockstart-two" role="list">
                                                  <li class="uitk-typelist-item uitk-typelist-item-bullet-unordered uitk-typelist-item-orientation-stacked uitk-typelist-item-size-2 uitk-typelist-item-default-theme uitk-typelist-item-indent" role="listitem">
                                                      <span aria-hidden="true" class="uitk-typelist-item-bulletchar uitk-typelist-item-bulletchar-bullet-unordered uitk-typelist-item-bulletchar-size-2"></span>
                                                      <span class="uitk-typelist-item-child">Rollaway bed fee: PKR 3000.0 per day</span>
                                                    </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <p class="uitk-paragraph uitk-paragraph-2">The above list may not be comprehensive. Fees and deposits may not include tax and are subject to change. </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">You need to know</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Extra-person charges may apply and vary depending on property policy</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Government-issued photo identification and a credit card, debit card, or cash deposit may be required at check-in for incidental charges</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Special requests are subject to availability upon check-in and may incur additional charges; special requests cannot be guaranteed</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">This property accepts credit cards and cash</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Cashless transactions are available</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Long-term renters welcome</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium uitk-layout-grid-item-has-column-start-by-large">
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three policy-uitk2">
                                      <div data-stid="content-item" class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                        <div class="uitk-layout-flex uitk-layout-flex-gap-six">
                                          <div class="uitk-layout-flex-item uitk-layout-flex-item-max-width-full_width uitk-layout-flex-item-flex-grow-1">
                                            <h3 class="uitk-heading uitk-heading-5" tabindex="-1">We should mention</h3>
                                          </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">Only registered guests are allowed in the guestrooms</div>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="uitk-layout-grid-item">
                                            <div class="uitk-text uitk-text-spacing-two overflow-wrap uitk-type-300 uitk-text-default-theme">
                                              <div data-stid="content-markup">
                                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">No pets and no service animals are allowed at this property</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="lazyload-wrapper">
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-padding-block-six uitk-spacing-padding-large-inline-six uitk-spacing-padding-extra_large-inline-six uitk-card-has-primary-theme">
                    <div class="uitk-layout-grid uitk-layout-grid-has-areas uitk-layout-grid-has-areas-by-medium uitk-layout-grid-has-areas-by-large uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns uitk-layout-grid-has-rows-by-large uitk-layout-grid-has-rows-by-medium uitk-layout-grid-has-rows uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-faq">
                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-end uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end uitk-sub-faq1">
                        <h2 class="uitk-heading uitk-heading-4 uitk-spacing uitk-spacing-margin-blockend-four">Frequently asked questions</h2>
                      </div>
                      <div class="uitk-expando-peek uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-end uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end uitk-sub-faq">
                        <div class="uitk-expando-peek-main">
                          <div class="uitk-expando-peek-inner" data-items-to-display="0">
                            <section class="uitk-expando-list">
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">Is Heritage Luxury Suites- ALL Suite Hotel pet-friendly? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>No, pets are not allowed at this property. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">How much is parking at Heritage Luxury Suites- ALL Suite Hotel? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Self parking and valet parking are free at this property. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">What time is check-in at Heritage Luxury Suites- ALL Suite Hotel? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Check-in start time: 2 PM; Check-in end time: 6 PM. Late check-in is available during limited hours. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">What time is check-out at Heritage Luxury Suites- ALL Suite Hotel? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Check-out is at noon. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">Does Heritage Luxury Suites- ALL Suite Hotel provide a shuttle to the airport? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Yes, there's a free airport shuttle that runs on request. </div>
                                  </div>
                                </div>
                              </details>
                              <details class="uitk-expando uitk-expando-list-item">
                                <summary class="uitk-expando-trigger-content">
                                  <svg class="uitk-icon uitk-expando-trigger-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                                  </svg>
                                  <span class="uitk-expando-title">
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme">Where is Heritage Luxury Suites- ALL Suite Hotel located? </div>
                                  </span>
                                </summary>
                                <div class="uitk-expando-body is-indented-leading" data-testid="uitk-expando-list-body-collapsed">
                                  <div class="uitk-expando-description">
                                    <div>Located in Gulberg, this hotel is within a 10-minute walk of M.M. Allam Road and Liberty Market. Gaddafi Stadium and Fortress Stadium are also within 6 mi (10 km). </div>
                                  </div>
                                </div>
                              </details>
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div id='section-6' class="uitk-spacing uitk-spacing-margin-block-six">
                <div class="lazyload-wrapper ">
                  <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-overflow uitk-card-padded uitk-card-has-primary-theme">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-rating">
                      <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                        <div class="uitk-spacing uitk-spacing-margin-block- uitk-spacing-padding-block-">
                          <div class="uitk-text uitk-type-900 uitk-type-regular uitk-text-positive-theme">9.2/10</div>
                          <div class="uitk-spacing uitk-spacing-margin-block-one uitk-spacing-padding-block-">
                            <div class="uitk-spacing uitk-spacing-margin-blockend-two">
                              <h1 class="uitk-heading uitk-heading-4" aria-hidden="true">Wonderful</h1>
                            </div>
                          </div>
                          <div class="uitk-spacing uitk-spacing-margin-blockstart-one uitk-menu uitk-menu-mounted" id="">
                            <button type="button" aria-label="14 verified reviews" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading uitk-menu-trigger" aria-expanded="false">
                              <span>14 verified reviews</span>
                              <div class="uitk-more-info-trigger-icon-container uitk-more-info-trigger-size-medium-icon-container">
                                <svg class="uitk-icon uitk-more-info-trigger-icon uitk-more-info-trigger-icon-theme-default uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                </svg>
                              </div>
                            </button>
                            <div class="uitk-menu-container animation-disabled uitk-menu-pos-left uitk-menu-container-autoposition" aria-hidden="true" style={{width: '327px'}}>
                              <div class="uitk-card uitk-card-roundcorner-all uitk-spacing uitk-spacing-padding-inline-three uitk-card-has-primary-theme">
                                <div class="uitk-text uitk-type-300 uitk-text-default-theme">All reviews shown are from real guest experiences. Only travelers who have booked a stay with us can submit a review. We verify reviews according to our guidelines and publish all reviews, positive or negative.</div>
                                <a href="https://www.expedia.com/lp/b/content-guidelines" target="_blank" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">More information</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-rating-sub">
                        <div class="uitk-spacing uitk-spacing-margin-block- uitk-spacing-padding-block-">
                          <div class="uitk-spacing uitk-spacing-margin-blockend-three">
                            <h1 class="uitk-heading uitk-heading-6" aria-hidden="true">Recent reviews</h1>
                          </div>
                          <div class="uitk-carousel no-touch items-max-height md" data-stid="carousel-wrapper">
                            <h3 class="is-visually-hidden">ExperimentalCarousel</h3>
                            <div class="uitk-carousel-container use-container-sizing" aria-label="ExperimentalCarousel" data-stid="carousel-container" tabindex="0">
                              <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="1 out of 7">
                                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                  <div class="uitk-spacing uitk-spacing-margin-blockend-two">
                                    <h1 class="uitk-heading uitk-heading-7" aria-hidden="true">10/10 Excellent</h1>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                    <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">Read more</button>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-padding-block-">
                                    <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-default-theme">ZAHID</div>
                                    <div class="uitk-text uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">May 2, 2023</div>
                                  </div>
                                </div>
                              </div>
                              <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="2 out of 7">
                                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                  <div class="uitk-spacing uitk-spacing-margin-blockend-two">
                                    <h1 class="uitk-heading uitk-heading-7" aria-hidden="true">10/10 Excellent</h1>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                    <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium">Read more</button>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-padding-block-">
                                    <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-default-theme">Aftab</div>
                                    <div class="uitk-text uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">Aug 6, 2022</div>
                                  </div>
                                </div>
                              </div>
                              <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="3 out of 7">
                                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                  <div class="uitk-spacing uitk-spacing-margin-blockend-two">
                                    <h1 class="uitk-heading uitk-heading-7" aria-hidden="true">10/10 Excellent</h1>
                                  </div>
                                  <div class="uitk-text uitk-type-300 uitk-text-default-theme">Great location. Very comfortable suites with many amenities made it feel like the comfort of home. Great service and foo...</div>
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                    <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" tabindex="-1">Read more</button>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-padding-block-">
                                    <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-default-theme">Naveed</div>
                                    <div class="uitk-text uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">Jul 19, 2022</div>
                                  </div>
                                </div>
                              </div>
                              <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="4 out of 7">
                                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                  <div class="uitk-spacing uitk-spacing-margin-blockend-two">
                                    <h1 class="uitk-heading uitk-heading-7" aria-hidden="true">10/10 Excellent</h1>
                                  </div>
                                  <div class="uitk-text uitk-type-300 uitk-text-default-theme">The building is beautiful, both inside and outside.  Inspired by Moroccan architecture, the details are gorgeous and a s...</div>
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                    <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" tabindex="-1">Read more</button>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-padding-block-">
                                    <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-default-theme">Zulfiqar</div>
                                    <div class="uitk-text uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">Jun 28, 2022</div>
                                  </div>
                                </div>
                              </div>
                              <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="5 out of 7">
                                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                  <div class="uitk-spacing uitk-spacing-margin-blockend-two">
                                    <h1 class="uitk-heading uitk-heading-7" aria-hidden="true">10/10 Excellent</h1>
                                  </div>
                                  <div class="uitk-text uitk-type-300 uitk-text-default-theme">Amazing place</div>
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                    <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" tabindex="-1">Read more</button>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-padding-block-">
                                    <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-default-theme">Abdellah</div>
                                    <div class="uitk-text uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">Mar 1, 2022</div>
                                  </div>
                                </div>
                              </div>
                              <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="6 out of 7">
                                <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                  <div class="uitk-spacing uitk-spacing-margin-blockend-two">
                                    <h1 class="uitk-heading uitk-heading-7" aria-hidden="true">10/10 Excellent</h1>
                                  </div>
                                  <div class="uitk-text uitk-type-300 uitk-text-default-theme">The theme Of the hotel is very nixe and its in the center of the city. The location is really good</div>
                                  <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                    <button type="button" class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" tabindex="-1">Read more</button>
                                  </div>
                                  <div class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-padding-block-">
                                    <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-default-theme">Sandi</div>
                                    <div class="uitk-text uitk-text-spacing-half uitk-type-200 uitk-type-regular uitk-text-default-theme">Dec 14, 2021</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uitk-spacing uitk-spacing-margin-block-three uitk-spacing-padding-block-">
                            <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                              <a type="button" class="uitk-button uitk-button-medium uitk-button-secondary">See all 14 reviews
                                <svg class="uitk-icon uitk-icon-directional" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style={{marginTop:'5px', marginLeft:'3px'}}>
                                  <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"></path>
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="uitk-spacing SimpleContainer">
                <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                        <figure class="uitk-image uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-image-ratio-3-2 uitk-image-ratio figure-tree">
                            <div class="uitk-image-placeholder">
                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/footer/bnaBanners/BEX_Footer.jpg"/>
                            </div>
                        </figure>
                        <div class="uitk-card-content-section uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-tree">
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-six">
                                <div role="region" aria-labelledby="marquee-title" class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-column-start width-q">
                                    <h2 class="uitk-heading uitk-heading-3" id="marquee-title">Go further with the Expedia app</h2>
                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-type-paragraph-300 uitk-spacing-padding-blockstart-two uitk-spacing-padding-inlineend-four">Save on select hotels and earn OneKeyCash on bookings in the app. Our app deals help you to save on trips so you can travel more and manage it all on the go.</div>
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-twelve uitk-spacing-padding-inlineend-four uitk-spacing-padding-blockend-two">Scan the QR code with your device camera and download our app</div>
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid switch">
                                        <div class="uitk-spacing uitk-spacing-padding-blockstart-six uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                            <a id="download-btn" name="download-btn" href="https://expe.app.link/?~customer_ad_set_name=EXPEDIA-US&amp;~customer_ad_name=BRAND.EXPEDIA.OWNED.BRANCH.DEFAULT.MAD&amp;~customer_campaign=BRAND&amp;~customer_placement=BEX-HP-MARQUEEDESKTOP&amp;~placement_id=Homepage&amp;%24canonical_url=https%3A%2F%2Fwww.expedia.com%2F&amp;%24desktop_url=https%3A%2F%2Fwww.expedia.com%2Fapp&amp;custom_web_attribute=add49b7a-17c6-412c-957e-78218a70f45a" class="uitk-button uitk-button-large uitk-button-fullWidth uitk-button-has-text uitk-button-as-link uitk-button-primary">Switch to the app</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start QR-code">
                                    <div class="uitk-spacing uitk-spacing-padding-inlinestart-four">
                                        <figure class="uitk-image uitk-image-ratio-1-1 uitk-image-ratio">
                                            <div class="uitk-image-placeholder">
                                                <img alt="QR code" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/qr-code/footer_qr_hp/1_QR_SHEET_CONTROL_FOOTER_HP.png"/>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        </Layout>
      )}
      <div id="mybutton">
      <button  onClick={BookRoom} class="btn feedback  select-styling search-btn1 mt-2 detail-view-btn">Book Now</button>
      </div>
    </>
  )
}

export default HotelDetail
