import React,{useState,useEffect} from "react";
import  Axios from "axios";
import { Hotelapitoken,ApiEndPoint,CustomerDomainName } from '../GlobalData/GlobalData';
import Loading from "../Loading/Loader";
import {Modal, ModalBody } from 'reactstrap'
import { fetchHotels,fetchHotelsSearh, OneWayFlightIndexSearchData,OneWayFlightSearchData } from '../../Redux/Actions/actions';
import { useNavigate,NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer,toast } from 'react-toastify';
import { airportcode } from '../Data/AirportCodes';
import moment from 'moment';
function Footer(){
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation,setUserLocation]=useState([]);
  const [arivalAirpotrs,setArivalAirpotrs]=useState([]);
  const [isOpen,setIsOpen]=useState(false);
  var token = Hotelapitoken();
  var apiendpoint=ApiEndPoint();
  const navigation=useNavigate();
  const Dispatch=useDispatch();
  const Airportcode=airportcode.items;
  useEffect(()=>{
    GetFlighMarkup();
    getUserLocation();
  },[]);
  function getUserLocation() {
    // Fetch user's location data
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=0708571ae58c4688824a7d01ca397041")
      .then(response => response.json())
      .then(data => {
        setUserLocation(data);
        // Handle location data as needed
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }

  const Searchhotels = async (name) => {
    setIsLoading(true);
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const threeDaysLater = new Date(currentDate);
    threeDaysLater.setDate(currentDate.getDate() + 3);
    var destination_name='';
     var country='';
     var lat='';
     var lon='';
     var pin='';
    if(name==='New York'){
     destination_name="New York";
     country="United States";
     lat=40.7127753;
     lon=-74.0059728;
     pin="US";
    }else if(name==='Los Angeles'){
     destination_name="Los Angeles";
     country="United States";
     lat=34.0549076;
     lon=-118.242643;
     pin="US";
    }else if(name==='Orlando'){
     destination_name="Orlando";
     country="United States";
     lat=36.1489309;
     lon=-97.378098;
     pin="US";
    }else if(name==='Atlanta'){
      destination_name="Atlanta";
      country="United States";
      lat=33.748752;
      lon=-84.38768449999999;
      pin="US";
     }else if(name==='Dallas'){
      destination_name="Dallas";
      country="United States";
      lat=32.7766642;
      lon=-96.79698789999999;
      pin="US";
     }else if(name==='San Francisco'){
      destination_name="SF";
      country="United States";
      lat=37.7749295;
      lon=-122.4194155;
      pin="US";
     }else if(name==='Miami'){
      destination_name="Miami";
      country="United States";
      lat=25.7616798;
      lon=-80.1917902;
      pin="US";
     }else if(name==='Denver'){
      destination_name="Denver City";
      country="United States";
      lat=32.9645475;
      lon=-102.8290963;
      pin="US";
     }else if(name==='Seattle'){
      destination_name="Seattle";
      country="United States";
      lat=47.6061389;
      lon=-122.3328481;
      pin="US";
     }else if(name==='San Diego'){
      destination_name="San Diego";
      country="United States";
      lat=32.715738;
      lon=-117.1610838;
      pin="US";
     }
     
    // Define your API URL, authToken, and dataToSend as shown in the previous example
    const FormData = {
       "token":token,
      "currency_slc": 'AFN',
      "currency_slc_iso": 'AF',
      "destination_name":destination_name,
      "country":country,
      "lat":  lat,
      "long": lon,
      "pin": pin,
      "cityd":destination_name,
      "country_code": pin,
      "check_in":moment(twoDaysLater).format('YYYY-MM-DD'),
      "check_out":moment(threeDaysLater).format('YYYY-MM-DD'),
      "slc_nationality": country,
      "adult": 2,
      "child": 0,
      "room": 1,
      "Adults": [2],
      "children": [0],
      "child_ages1": [],
      "rooms_counter": [1],
      "child_ages2": [],
     
    };
    try {
    
     Dispatch(fetchHotelsSearh(FormData));
      const response = await Axios.post(apiendpoint+'/api/search/hotels/new',FormData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        
        } ,
        
      });
      window.scrollTo(0, 0);
      sessionStorage.removeItem('FlightCheckOut');
      navigation('/hotels');
      Dispatch(fetchHotels(response.data));
      // Handle the API response here
      setIsLoading(false);
    } catch (error) {
      // Handle errors here
      setIsLoading(false);
      console.error('Error:', error);
    }
  };
  // **********Flight Section*************

  const GetFlighMarkup=async()=>{
    var data={
      'token':token,
    };
  try{
    const response=await Axios.post(apiendpoint+'/api/get_markup_flight_new',data,{
      headers:{
        "Access-Control-Allow-Origin": "*",

      }
    });
    localStorage.setItem('FlightMarkup',JSON.stringify(response.data));
  } catch (error){
      console.error('Error',error);
  }

};

const OneWayapicall = async (arrival) => {
  var Location=userLocation.city.name;
  const UserAirport = Airportcode.filter(option =>
    option.cityName.toLowerCase().includes(Location.toLowerCase())
  );
  if(UserAirport.length===0){
    return;
  }
  const random15DigitNumber = generateRandomNumber();
  const currentDate = new Date();
   const twoDaysLater = new Date(currentDate);
   twoDaysLater.setDate(currentDate.getDate() + 2);
  const data = {
    token_authorization: token,
    case: 'search_flights',
    MaxStopsQuantity:'All',
    DepartureDate: moment(twoDaysLater).format('YYYY-MM-DD'),
    DepartureCode: UserAirport[0].airportCode,
    ArrivalCode: arrival,
    AirTripType:'OneWay',
    AirlinesCode: 'EK',
    adult: 1,
    child: 0,
    infant: 0,
    ConversationId: random15DigitNumber,
    CabinType: 'no'
  }
  Dispatch(OneWayFlightIndexSearchData(data))
  setIsLoading(true)
  try {
    const response = await Axios.post(
      apiendpoint + '/api/flight_search',
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
    setIsLoading(false)
    if (response.data.Success === false) {
      toast.warning(response.data.Message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;

    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      Dispatch(OneWayFlightSearchData(response.data.Data))
      sessionStorage.setItem('15digitnumber', random15DigitNumber.toString())
      navigation('/Flight_search')
    }
  } catch (error) {
    
    setIsLoading(false)
    console.error('Error:', error)
  }
};

function generateRandomNumber () {
  const min = Math.pow(10, 14) 
  const max = Math.pow(10, 15) - 1 
  return Math.floor(Math.random() * (max - min + 1) + min)
};
const OpenModal=(data)=>{
  if(data !==''){
    const UserAirport = Airportcode.filter(option =>
      option.cityName.toLowerCase().includes(data.toLowerCase())
    );
    setArivalAirpotrs(UserAirport);
  }
 
  setIsOpen(!isOpen);
};
    return(
    <>
    {isLoading && ( <Loading/>)}
    <Modal isOpen={isOpen} className='Airport-Modal' toggle={()=>OpenModal('')}>
      <ModalBody>
        <h4 className='p-3'>Where you want to go?</h4>
          <div className='row'>
            {arivalAirpotrs.map((item,index)=>(
              <div key={index}  data-testid="box-2" class="sc-aXZVg p-2 col-md-4 col-lg-4 sc-JrDLc  csbhqS">
              <div onClick={()=>OneWayapicall(item.airportCode)} class="sc-aXZVg sc-jEACwC sc-cMa-dbN kqMasH jjRHoq Pgbli sc-c30427c5-0 gBuBCo" color="background.lightest" data-testid="action-card" role="link" tabindex="0">
                <div class="sc-aXZVg ldwMXy">
                  <div color="primary.light" height="48" width="48" class="sc-aXZVg sc-eqUAAy TZuCs cgaCzu">
                  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" focusable="false" aria-hidden="true" role="img">
                                                  <g fill="none" fill-rule="evenodd">
                                                    <path d="M22.15.5c.31.004.617.07.902.197a.5.5 0 01.254.254c.126.285.193.591.198.881l-.012.418c-.077 1.384-.803 2.627-2.142 4.081l-.321.341-.68.69-2.084 
                                                      2.041.428 1.41c.45-.157.968-.103 1.412.186l.127.092 1.397 1.397a1.5 1.5 0 01.386 1.123c-.027.36-.184.696-.411.925l-.102.092-1.269 1.268 1.335 
                                                      4.404c.124.415.037.861-.23 1.2l-.096.108-.543.543a1.12 1.12 0 01-1.734-.146l-.065-.112-4.641-8.528-.625.625-1.263 1.249a20.596 20.596 0 01-2.67 
                                                      2.359l.348 4.502a.836.836 0 01-.162.517l-.075.09-.54.54a.87.87 0 01-1.327-.115l-.063-.103-1.734-3.45a1.66 1.66 0 01-1.125-.208l-.142-.095-.084-.078a1.666 
                                                      1.666 0 01-.374-1.344L.967 16.117a.87.87 0 01-.29-1.298l.078-.088.555-.554a.835.835 0 01.499-.218l.12-.003 4.478.355a20.52 20.52 0 011.94-2.245l.426-.413 
                                                      1.887-1.898L2.123 5.11a1.12 1.12 0 01-.574-.7l-.028-.133a1.12 1.12 0 01.24-.874l.091-.1.547-.546a1.32 1.32 0 011.165-.355l.14.034 4.402 1.333L9.34 2.54a1.5 1.5 0 
                                                      012.068-.251l.124.103 1.422 1.426a1.57 1.57 0 01.238 1.494l1.419.43 2.157-2.214.599-.59.45-.423C19.273 1.19 20.38.574 21.726.511L22.15.5z" fill="#0068ef">
                                                    </path>
                                                    <path d="M8.75 18.262l.293 3.8-.352.352-1.6-3.184c.096-.045.193-.092.29-.142.311-.173.618-.352.92-.54l.449-.286zm8.709-8.07L20.61 20.59l.013.067a.32.32 0 
                                                      01-.048.194l-.043.054-.547.547-.035.027-.037.014-.039.001-.036-.012-.03-.022-.013-.014-4.798-8.816 1.42-1.413 1.042-1.024zM1.941 
                                                      14.958l3.798.304c-.198.299-.388.603-.57.912l-.268.468-.13.267-3.182-1.599.352-.352zM3.347 3.38l.068.013 10.411 3.154-1.236 1.27-1.195 
                                                      1.2-8.807-4.792-.04-.027a.115.115 0 01-.038-.07.12.12 0 01.013-.073l.022-.03.557-.557.053-.041a.32.32 0 01.192-.047z" fill="#e8f2ff">
                                                    </path>
                                                    <path d="M22.141 1.501l.136.01.128.021.051.014.027.111.014.103.006.105-.01.316c-.066 1.051-.618 2.053-1.664 3.236l-.216.239-.41.433-.558.562-4.126 4.046-3.257 
                                                      3.246-.603.594-.386.394a19.638 19.638 0 01-4.366 3.275l-.225.111-.234.104-.356.14-.113.027a.67.67 0 01-.318-.02l-.096-.04-.039-.025-.028-.038a.66.66 0 
                                                      01-.04-.602c.102-.274.208-.517.327-.753a19.59 19.59 0 013.292-4.359l.395-.383 4.005-4.026.509-.518 3.371-3.471.236-.237.227-.226.436-.417.244-.225c1.203-1.09 
                                                      2.117-1.621 3.122-1.725l.202-.015.317-.007zm-2.66 10.28l.091.058 1.333 1.339.042.056a.511.511 0 01-.054.595l-.059.057-.93.926-.921-3.04a.57.57 0 01.498.01zm-8.704-8.717l.065.05 
                                                      1.363 1.364.042.07c.051.108.06.245.022.376l-.036.096-3.042-.922.891-.89.074-.078a.504.504 0 01.312-.145.5.5 0 01.31.08zm10.862-.737a1.186 1.186 0 00-1.679 0 .275.275 0 00-.001.388.27.27 
                                                      0 00.384.001.645.645 0 01.745-.12l.088.052.077.065.033.044a.644.644 0 010 .914.275.275 0 00-.002.387.27.27 0 00.384.003 1.194 1.194 0 00.168-1.484l-.078-.11-.088-.098-.031-.042z" fill="#fff">
                                                    </path>
                                                  </g>
                  </svg>
                  </div>
                </div>
                <div width="100%" class="sc-aXZVg sc-eqUAAy feckNt dFCDBu">
                  <div class="sc-aXZVg sc-eqUAAy gCbQdB heAqDO">
                    <div font-weight="700" font-size="14px" class="sc-fqkvVR sc-fUBkdm dxpPZm btsJdq">{item.airportCode}-{item.airportName}, {item.cityName}</div>
                  </div>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20" title="ArrowRight" aria-hidden="true" fill="currentcolor" color="primary" tabindex="-1" focusable="false" role="img" class="Svg-sc-12lgb6u-0 iJgYGn ArrowRight__SvgArrowRight-sc-i9is6p-0 sc-hBtRBD cpzuBV kOTrwD">
                  <title>ArrowRight</title>
                  <path d="M12 4l-1.4 1.4 5.6 5.6H4v2h12.2l-5.6 5.6L12 20l8-8-8-8z"></path>
                </svg>
              </div>
              </div>
            ))}
         
            <button className='btn btn-primary' onClick={()=>OpenModal('')}>Close</button>
          </div>
      </ModalBody>
    </Modal>
        <div class="uitk-spacing uitk-spacing-padding-block-three uitk-spacing-padding-inlinestart-two uitk-spacing-padding-inlineend-two">
          <h2 class="uitk-heading uitk-heading-4 is-visually-hidden">Additional information about Mufti Travel</h2>
          <footer class="global-navigation-footer">
            <div class="global-navigation-footer-container">
              <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-unset uitk-spacing-padding-inlinestart-six uitk-spacing-padding-blockstart-six uitk-spacing-padding-blockend-twelve uitk-spacing-padding-small-inlineend-three uitk-spacing-padding-medium-inlineend-three uitk-spacing-padding-large-inlineend-two uitk-spacing-padding-extra_large-inlineend-two">
                <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-spacing uitk-spacing-padding-blockend-two uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="list">
                  {/* <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-large uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-row-start-by-large" 
                      role="listitem">
                    <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-flex-start uitk-spacing no-bullet uitk-spacing-padding-blockstart-two">
                      <a  >
                        <img aria-label="opens in a new window" src="https://a.travel-assets.com/globalcontrols-service/content/f285fb631b0a976202ef57611c7050e9ef5ca51a/images/EG_Wordmark_blue_RGB.svg" alt="Expedia Group Logo" width="100%" loading="lazy"/>
                      </a>
                    </div>
                  </li> */}
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Company</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="About" to="/terms_and_conditions" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Terms & Conditions</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Jobs" to='/complaint_policy' target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Complaints Policy</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Privacy" to="/privacy_policy" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Privacy Policy</NavLink>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <NavLink aria-label="Terms of use" to="/faqs"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">FAQs</NavLink>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Hotels</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Los Angeles')} aria-label="United States of America travel guide"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Los Angeles</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('New York')} aria-label="Hotels in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in New York</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Dallas')} aria-label="Vacation rentals in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Dallas</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('Atlanta')} aria-label="Vacation packages in United States of America"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in Atlanta</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>Searchhotels('San Diego')} aria-label="Domestic flights"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Hotels in San Diego</a>
                        </div>
                      </li>
                     
                    </ul>
                  </li>
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Flights</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>OpenModal('New York')} aria-label="Cookies"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">New York</a>
                        </div>
                      </li>
                     
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>OpenModal('London')} aria-label="Vrbo terms and conditions"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">London</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>OpenModal('Seattle')} aria-label="Get started with One Key™" target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Seattle</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>OpenModal('Miami')} aria-label="Accessibility"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Miami</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a onClick={()=>OpenModal('Dubai')} aria-label="Your Privacy Choices"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Dubai</a>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-row-start-by-medium uitk-layout-grid-item-has-row-start-by-large wdf" role="listitem">
                    <h3 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-padding-blockstart-two">Help</h3>
                    <ul class="uitk-typelist uitk-typelist-orientation-stacked uitk-typelist-size-2 uitk-typelist-spacing" role="list">
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a aria-label="Support"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Support</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a aria-label="Cancel your hotel or vacation rental booking"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Cancel your hotel or vacation rental booking</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a aria-label="Cancel your flight"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Cancel your flight</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a aria-label="Refund timelines, policies &amp; processes"  target="_self" class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Refund timelines, policies &amp; processes</a>
                        </div>
                      </li>
                      <li class="uitk-spacing uitk-spacing-padding-blockstart-two" role="listitem">
                        <div class="uitk-layout-flex">
                          <a aria-label="Use an Expedia coupon"  class="uitk-link phone-only-agent  uitk-link-align-left uitk-link-layout-default uitk-link-small" role="link">Use an Mufti Travel coupon</a>
                        </div>
                      </li>
                      
                    </ul>
                  </li>
                </ul>
                <div>
                  <hr class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr" />
                </div>
                <ul class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-column uitk-layout-flex-flex-wrap-wrap no-bullet">
                  <li class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="listitem">
                    <div class="uitk-text uitk-type-start overflow-wrap uitk-type-200 uitk-type-regular uitk-text-default-theme">
                      <span>Mufti Travel, Inc. is not responsible for content on external Web sites. </span>
                    </div>
                  </li>
                  <li class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item uitk-layout-flex-item-flex-basis-full_width" role="listitem">
                    <div class="uitk-text uitk-type-start overflow-wrap uitk-type-200 uitk-type-regular uitk-text-default-theme">
                      <span>© 2024 Mufti Travel, Inc. All rights reserved. Mufti Travel and the Airplane Logo are trademarks or registered trademarks of Mufti Travel, Inc.</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
        </div>
        {/* <footer className="footer-section mt-5">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-6">
                  <div className="widget text-justify company-intro-widget">
                    <a href="index.html" className="site-logo"><img src={logo} alt="logo"/></a>
                    <p> <span className="web-color">Al-Hijaz</span> is the pioneer tour operation in United Kingdom to offer Hajj and Umrah services from United Kingdom. Make use of our fantastic hajj and umrah packages which are tailored to suit the needs of pilgrims visiting Saudi Arabia for their all important sacred journey.</p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="widget course-links-widget">
                    <h5 className="widget-title">Helpfull Links</h5>
                    <ul className="courses-link-list">
                      <li><NavLink className='nav-link' to='/terms_and_conditions'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Terms & conditions</NavLink></li>
                      <li><NavLink className='nav-link' to='/complaint_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Complaints Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/privacy_policy'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Privacy Policy</NavLink></li>
                      <li><NavLink className='nav-link' to='/faqs'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>FAQs</NavLink></li>
                      <li><NavLink className='nav-link' to='/about-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>About Us</NavLink></li>
                      <li><NavLink className='nav-link' to='/contact-us'><i className="fas fa-long-arrow-alt-right"><FontAwesomeIcon icon={faArrowRight}/></i>Contact Us</NavLink></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget newsletter-widget">
                    <h5 className="widget-title">For Special booking request, please call</h5>
                    <div className="footer-newsletter">
                      <h3>0121 777 2522</h3>
                      <p><FontAwesomeIcon icon={faLocationArrow}/>  1a Nansen Road Sparkhill Birmingham B11 4DR UK</p>
                      <div className="social-icons mt-4">
                        <a className="logo_hover" href="https://www.facebook.com/alhijaztoursbirmingham" target="_blank"> <img src={fb} alt="Facebook Logo" width="47px"/></a>
                        <a className="logo_hover ms-4" href="#" target="_blank"> <img src={insta} alt="Insta Logo" height="55px" width="55px"/></a>
                        <a className="logo_hover ms-4" href="https://www.linkedin.com/company/alhijaztours/" target="_blank"> <img src={linkedin} alt="LinkedIn Logo" height="50px" width="50px"/></a>
                        <a className="logo_hover ms-4" href="https://twitter.com/Alhijaztours2" target="_blank"> <img src={twitter} alt="Facebook Logo" width="54px"/></a></div>
                        <p className="mt-2">Al Hijaz tours ltd is a acting agent on behalf of Ruhani Travels atol number 11540</p>

                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                  <div className="col-md-12 col-sm-12 text-sm-left text-center">
                    <span className="copy-right-text">© {moment().format('YYYY')}<span className="web-color"> AlHijaz Tours</span> All Rights Reserved.</span>
                  </div>
              </div>
            </div>
          </div>
        </footer> */}
    </>        
    );
}

export default Footer;