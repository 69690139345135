import React, { useState ,useEffect,useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faSearch,
  faArrowRight,
  faAngleDown,
  faBowlFood,
  faFilter,
  faDollar
} from '@fortawesome/free-solid-svg-icons'
import bgimage from '../../Images/Hotels/bg.jpg'
import noUiSlider from 'nouislider'
import { RangeSlider } from 'rsuite';
import HotelCard from '../../Components/HotelCard/Hotelcard'
import { useSelector } from 'react-redux';
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import HotelSearch from '../../Components/SearchBar/HotelSearch';
import Layout from '../../Components/Layout/Layout'
import moment from 'moment';
function Hotels () {
  const hotels = useSelector((state) => state.hotels.hotels);
  const hotelsSearchindex = useSelector((state) => state.hotels.hsearch);
  const minamounts = hotels.hotels_list.map(hotel => Number(hotel.min_price));
    const minValue2 = Math.min(...minamounts);
    const maxValue2 = Math.max(...minamounts);
  const [minValue, setMinValue] = useState(minValue2);
  const [maxValue, setMaxValue] = useState(maxValue2);
  const [hotelsSearchData, sethotelsSearchData] = useState(hotelsSearchindex);
  const [hotelNameFilter, setHotelNameFilter] = useState(null);
  const [baseCName, setBaseCName] = useState('GBP')
  const [rangeValue, setRangeValue] = useState([minValue2, maxValue2]);
  const [ShowPriceFilter, setShowPriceFilter] = useState(false);
    const [ShowMealTYpeFilter, setShowMealTYpeFilter] = useState(false);
    const [ShowRatingFilter, setShowRatingFilter] = useState(false);
    const [ShowFacilityFilter, setShowFacilityFilter] = useState(false);
  const [showModifySearch, setShowModifySearch] = useState(false);
  const [baseCurrency, setBaseCurrency] = useState([])
  const [hotelid, setHotelid] = useState("");
  const [showfilter, setShowfilter] = useState({rating:false,meal:false,facility:false});
  const [starRating, setStarRating] = useState({rating5:'0',rating4:'0',rating3:'0',rating2:'0',rating1:'0',type:'Stars'});
  const [mealRating, setMealRating] = useState({meal1:'',meal2:'',meal3:'',meal4:'',type:'meal'});
  const [selectedFacilities, setSelectedFacilities] = useState({ type: 'facility' }); // Initialize an empty array for selected items
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    setStarRating((prevDetails) => {
      const updatedStarRating = { ...prevDetails };
      if (updatedStarRating[name] !=="0") {
        updatedStarRating[name]="0";
      } else {
        updatedStarRating[name] =value;
      }
  
      return updatedStarRating;
    });
  };
  const handleMealTypeChange = (event) => {
    const { name, value } = event.target;

    setMealRating((prevDetails) => {
      const updatedStarRating = { ...prevDetails };
      if (updatedStarRating[name] !=="") {
        updatedStarRating[name]="";
      } else {
        updatedStarRating[name] =value;
      }
  
      return updatedStarRating;
    });
  };

  const handleFacilityChange = (event) => {
    const { name, value } = event.target;
    const isSelected = selectedFacilities[name] === value;
    
    if (isSelected) {
      // If selected, remove it from the object
      const updatedFacilities = { ...selectedFacilities };
      delete updatedFacilities[name];
      setSelectedFacilities(updatedFacilities);
    } else {
      // If not selected, add it to the object
      setSelectedFacilities({ ...selectedFacilities, [name]: value });
    }

  };
  useEffect(() => {
    // Your code to be executed every time hotelsSearchData changes
    // For example:

    // Add your logic here based on the updated hotelsSearchData
  }, [hotelsSearchData,hotels])
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    // Check if the slider element exists
    if (slider) {
      noUiSlider.create(slider, {
        start: [minValue, maxValue],
        connect: true,
        range: {
          min: minValue2,
          max: maxValue2
        }
      });

      slider.noUiSlider.on('update', (values, handle) => {
        if (handle === 0) {
          setMinValue(parseInt(values[handle]));
        } else {
          setMaxValue(parseInt(values[handle]));
        }
      });

      return () => slider.noUiSlider.destroy();
    }

    // If the slider element doesn't exist, do nothing
  }, [minValue, maxValue, minValue2, maxValue2]);
  const handleHotelFilter = inputValue => {
    setHotelid(inputValue.value);
    setHotelNameFilter(inputValue);
  };
  const StarFilter=()=>{
    setShowRatingFilter(false);
    setHotelid(starRating);
  };
const PriceFilter=()=>{
  setShowPriceFilter(false);
  var data={type:'price',min:minValue,max:maxValue};
  setHotelid(data);
}
  const MealFilter=()=>{
    setShowMealTYpeFilter(false);
    setHotelid(mealRating);
  };

  const FacilityFilter=()=>{
    setShowFacilityFilter(false);
    setHotelid(selectedFacilities);
  };
  const DisplayModifySearch=()=>{
    setShowModifySearch(!showModifySearch);
  };
  const Showfilter=(num)=>{
    if(num===1){
      setShowfilter(prevData => ({ ...prevData, rating: !prevData.rating }));
    }
    if(num===2){
      setShowfilter(prevData => ({ ...prevData, meal: !prevData.meal }));
    }
    if(num===3){
      setShowfilter(prevData => ({ ...prevData, facility: !prevData.facility }));

    }
  };

  const TogglePriceFilter=()=>{
    setShowPriceFilter(!ShowPriceFilter);
  };
  const ToggleMealTypeFilter=()=>{
    setShowMealTYpeFilter(!ShowMealTYpeFilter);
  };
  const ToggleRatingFilter=()=>{
    setShowRatingFilter(!ShowRatingFilter);
  };
  const ToggleFacilityFilter=()=>{
    setShowFacilityFilter(!ShowFacilityFilter);
  };
  const handleChange = (newRangeValue) => {
    setRangeValue(newRangeValue);
    setMinValue(newRangeValue[0]);
    setMaxValue(newRangeValue[1]);
  };
  return (
    <>
       <Modal isOpen={ShowPriceFilter} toggle={TogglePriceFilter}>
          <ModalHeader toggle={TogglePriceFilter}>Price Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_price_filter'>
                <div className='mb-0'>
                  {/* <h3 className='form-label'>Price Level</h3> */}
                  <RangeSlider
                      value={rangeValue}
                      onChange={handleChange}
                      min={minValue2}
                      tooltip={false}
                      max={maxValue2}
                      step={1}
                    />                  <div className='pt-2'>
                    <div className='fw-bold mb-2'>
                      Min: <span id='kt_slider_basic_min'>{minValue.toFixed(0)} </span>
                    </div>
                    <div className='fw-bold mb-2'>
                      Max: <span id='kt_slider_basic_max'>{maxValue.toFixed(0)}</span>
                    </div>
                  </div>
                  <button onClick={PriceFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowMealTYpeFilter} toggle={ToggleMealTypeFilter}>
          <ModalHeader toggle={ToggleMealTypeFilter}>Meal Type Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' >                
                {/* <h3>Meal Type</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal1' value='ROOM ONLY' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>  Room Only</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal2' value='BED AND BREAKFAST' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> BED AND BREAKFAST </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal3' value='HALF BOARD' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> HALF BOARD</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal4' value='Full BOARD' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Full BOARD</span>
                    </label>
                  </li>
                </ul>
                <button onClick={MealFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
               
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowRatingFilter} toggle={ToggleRatingFilter}>
          <ModalHeader toggle={ToggleRatingFilter}>Star Rating Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' onClick={()=>Showfilter(1)}>                
                  {/* <h3>Filter by Rating</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul>
                  <li>
                    <label>
                      <input type='checkbox' 
                      className='custom-textbox' 
                      onChange={handleCheckboxChange} name='rating5' value='5' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'>
                        <i className='fa fa-star'>
                          {' '}
                          <FontAwesomeIcon icon={faStar} />
                        </i>
                      </span>
                    </label>
                  </li>
                </ul>
                <button onClick={StarFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
                 
              </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={ShowFacilityFilter} toggle={ToggleFacilityFilter}>
          <ModalHeader toggle={ToggleFacilityFilter}>Facility Filter</ModalHeader>
          <ModalBody>
          <div className='widget widget_has_radio_checkbox'>
                <div className='filter-show-hide' onClick={()=>Showfilter(3)}>                
                {/* <h3>Facilities</h3> */}
                {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                </div>
                  <div>
                <ul> 
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility1' value='Wi-fi' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wi-fi</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility2' value='Internet access' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Internet access </span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility3' value='TV' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> TV</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Wake-up service' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wake-up service</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Smoking rooms' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Smoking rooms</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility6' value='Wheelchair-accessible' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Wheelchair-accessible</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox'  className='custom-textbox' onChange={handleFacilityChange} name='facility7' value='Laundry service' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Laundry service</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility8' value='Banquet hall' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Banquet hall</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility9' value='Non-smoking establishment' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Non-smoking establishment</span>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility10' value='Safe' />
                      <i className='awe-icon awe-icon-check'></i>
                      <span className='rating'> Safe</span>
                    </label>
                  </li>
                </ul>
                <div>
                <button onClick={FacilityFilter} className='btn btn-warning m-2'>Apply</button>
                </div>
                </div>
              
              </div>
          </ModalBody>
        </Modal>
    <Layout>
    <div className='container-fluid'>
      <div className='mt-3 px-3'><HotelSearch/></div>
    </div>
      <div className='m-4'>
        <div className='row'>
          <div className='col-md-3 col-md-pull-9'>
            <div className='theiaStickySidebar'>
              <div class="mobile-Filter-info mb-3">
                <ul>
                    <li><p onClick={TogglePriceFilter}><FontAwesomeIcon icon={faDollar}/> Price</p></li>
                    <li><p onClick={ToggleMealTypeFilter}><FontAwesomeIcon icon={faBowlFood}/><span > Meal Type</span></p>
                    </li>
                    <li><p onClick={ToggleRatingFilter}><FontAwesomeIcon icon={faStar}/><span > Rating</span></p>
                    </li>
                    <li><p onClick={ToggleFacilityFilter}><FontAwesomeIcon icon={faFilter}/><span > Facilities</span></p>
                    </li>
                </ul>
              </div>
              <div className='page-sidebar'>
                <div
                  id='map-container-google-2'
                  className='z-depth-1-half map-container'
                  style={{ height: '500' }}
                >
                  <iframe
                    src='https://www.google.com/maps/embed/v1/place?key=AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY
                  &q=Mecca+Saudi+Arabia'
                    frameborder='0'
                    allowfullscreen
                    className='rounded-4'
                  ></iframe>
                </div>
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div class="uitk-layout-flex uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-primary-theme">
                    <div class="uitk-switch uitk-layout-flex-item uitk-switch-theme-standard uitk-switch-position-trailing">
                      <input type="checkbox" name="compare-toggle-filter" id="compare-toggle-filter" class="uitk-switch-input" aria-label="Compare properties" />
                      <div aria-hidden="true" class="uitk-switch-control">
                        <div class="uitk-switch-control-handle">
                          <svg class="uitk-icon" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                          </svg>
                        </div>
                      </div>
                      <div class="uitk-switch-content mr-3">
                        <label class="uitk-switch-label" for="compare-toggle-filter">
                          <span class="uitk-switch-label-content">Compare properties</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{opacity:'1'}} />
                <div className='widget widget_price_filter'>
                  <h3 className='form-label'>Search by property name</h3>
                  <Select
                      value={hotelNameFilter}
                      onChange={handleHotelFilter}
                      options={hotels.hotels_list.map(option => ({
                        value: option.hotel_id,
                        label:option.hotel_name
                      }))}
                    />
                </div>
                <hr style={{opacity:'1'}} />
              </div>
              <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                <h3 class="uitk-heading uitk-heading-5">Filter by</h3>
                
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-testid="price">
                      <legend class="uitk-spacing uitk-spacing-margin-blockend-two">
                        <h4 class="uitk-heading uitk-heading-7">Price per night</h4>
                      </legend>
                      <div class="slider-text-input--container">
                        <div class="slider-text-input--input_container">
                          <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-content-space-between">
                            <div class="uitk-field">
                              <label for="price-min" class="uitk-field-label is-visually-hidden">Min</label>
                              <input type="text" value={minValue.toFixed(0)} class="uitk-field-input uitk-layout-grid-item remove-spinner" id='kt_slider_basic_min' step="1" style={{sliderPrimary:'0%'}}/>
                              <div class="uitk-field-label" aria-hidden="true">Min</div>
                            </div>
                            <div class="uitk-field">
                              <label for="price-max" class="uitk-field-label is-visually-hidden">Max</label>
                              <input type="text" value={maxValue.toFixed(0)} class="uitk-field-input uitk-layout-grid-item remove-spinner" id='kt_slider_basic_max' step="1" style={{sliderPrimary:'0%'}}/>
                              <div class="uitk-field-label" aria-hidden="true">Max</div>
                            </div>
                          </div>
                        </div>
                        <div class="slider-text-input--slider my-4">
                          <div data-testid="price-slider-container" class="price-slider-container">
                            <RangeSlider
                              value={rangeValue}
                              onChange={handleChange}
                              min={minValue2}
                              tooltip={false}
                              max={maxValue2}
                              step={1}
                            /> 
                          </div>
                        </div>
                        <div>
                          <a href='#' onClick={PriceFilter} className='pb-2' >Apply</a>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset>
                      <legend class="uitk-spacing uitk-spacing-margin-blockend-two">
                        <h4 class="uitk-heading uitk-heading-7">Guest rating</h4>
                      </legend>
                      <fieldset class="uitk-radio-button-group">
                        <legend class="is-visually-hidden">guestRating</legend>
                        <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                          <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                            <label class="uitk-radio-button-label" for="guestRating--mrr">
                              <input type="radio" name="guestRating" id="guestRating--mrr" class="" value="" aria-label="Any" aria-describedby="" checked=""/>
                              <span aria-hidden="true" class="uitk-radio-button-control"></span>
                              <span aria-hidden="false" class="uitk-radio-button-label-content">Any</span>
                            </label>
                          </div>
                        </div>
                        <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                          <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                            <label class="uitk-radio-button-label" for="guestRating-45-4p8">
                              <input type="radio" name="guestRating" id="guestRating-45-4p8" class="" value="45" aria-label="Wonderful 9+" aria-describedby=""/>
                              <span aria-hidden="true" class="uitk-radio-button-control"></span>
                              <span aria-hidden="false" class="uitk-radio-button-label-content">Wonderful 9+</span>
                            </label>
                          </div>
                        </div>
                        <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                          <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                            <label class="uitk-radio-button-label" for="guestRating-40-05k">
                              <input type="radio" name="guestRating" id="guestRating-40-05k" class="" value="40" aria-label="Very good 8+" aria-describedby=""/>
                              <span aria-hidden="true" class="uitk-radio-button-control"></span>
                              <span aria-hidden="false" class="uitk-radio-button-label-content">Very good 8+</span>
                            </label>
                          </div>
                        </div>
                        <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                          <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                            <label class="uitk-radio-button-label" for="guestRating-35-n0w">
                              <input type="radio" name="guestRating" id="guestRating-35-n0w" class="" value="35" aria-label="Good 7+" aria-describedby=""/>
                              <span aria-hidden="true" class="uitk-radio-button-control"></span>
                              <span aria-hidden="false" class="uitk-radio-button-label-content">Good 7+</span>
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </fieldset>
                  </div>
                </div> */}
                {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset>
                      <legend>
                        <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockend-two">Star rating</h4>
                      </legend>
                      <ul class="uitk-button-toggle-group uitk-spacing uitk-spacing-padding-blockstart-one uitk-button-toggle-group-wrap">
                        <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                            <label class="uitk-button-toggle-content" for='star1'>
                              <input type='checkbox' className='uitk-button-toggle-input is-visually-hidden' onChange={handleCheckboxChange} name='rating1' id='star1' value='1' />
                              <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                              </svg>
                              <span class="uitk-button-toggle-label-text" aria-hidden="true">1</span>
                            </label>
                        </div>
                        <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                          <label class="uitk-button-toggle-content" for="star2">
                            <input type='checkbox' className='uitk-button-toggle-input is-visually-hidden' onChange={handleCheckboxChange} name='rating2' id='star2' value='2' />
                            <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                            </svg>
                            <span class="uitk-button-toggle-label-text" aria-hidden="true">2</span>
                          </label>
                        </div>
                        <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                          <label class="uitk-button-toggle-content" for="star3">
                            <input type='checkbox' className='uitk-button-toggle-input is-visually-hidden' onChange={handleCheckboxChange} name='rating3' id='star3' value='3' />
                            <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                            </svg>
                            <span class="uitk-button-toggle-label-text" aria-hidden="true">3</span>
                          </label>
                        </div>
                        <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                          <label class="uitk-button-toggle-content" for="start4">
                            <input type='checkbox' className='uitk-button-toggle-input is-visually-hidden' onChange={handleCheckboxChange} name='rating4' id='star4' value='4' />
                            <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                            </svg>
                            <span class="uitk-button-toggle-label-text" aria-hidden="true">4</span>
                          </label>
                        </div>
                        <div class="uitk-button-toggle uitk-button-toggle-is-inline">
                          <label class="uitk-button-toggle-content" for="star5">
                            <input type='checkbox' className='uitk-button-toggle-input is-visually-hidden' onChange={handleCheckboxChange} name='rating5' id='star5' value='5' />
                            <svg class="uitk-icon uitk-button-toggle-icon uitk-icon-xsmall" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 17.27 18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"></path>
                            </svg>
                            <span class="uitk-button-toggle-label-text" aria-hidden="true">5</span>
                          </label>
                        </div>
                      </ul>
                      <a href='#' onClick={StarFilter}> Apply</a>
                    </fieldset>
                  </div>
                </div> */}
                {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-stid="paymentType">
                      <legend>
                        <h4 class="uitk-heading uitk-heading-7">Payment type</h4>
                      </legend>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="paymentType" id="paymentType-PAY_LATER-rmv" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="PAY_LATER" aria-label="Reserve now, pay later"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="paymentType-PAY_LATER-rmv">
                                <p class="uitk-checkbox-label-content">Reserve now, pay later</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div> */}
                {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-stid="propertyCancellation">
                      <legend>
                        <h4 class="uitk-heading uitk-heading-7">Property cancellation options</h4>
                      </legend>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="paymentType" id="paymentType-FREE_CANCELLATION-zou" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="FREE_CANCELLATION" aria-label="Fully refundable property"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="paymentType-FREE_CANCELLATION-zou">
                                <p class="uitk-checkbox-label-content">Fully refundable property</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-stid="lodging">
                      <legend>
                        <h4 class="uitk-heading uitk-heading-7">Property type</h4>
                      </legend>
                      <div class="uitk-expando-peek">
                        <div class="uitk-expando-peek-main uitk-expando-peek-main-inactive hide-scrim">
                          <div class="uitk-expando-peek-inner display-items" data-items-to-display="3">
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-VACATION_RENTALS-90l" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="VACATION_RENTALS" aria-label="Vacation rentals"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-VACATION_RENTALS-90l">
                                      <p class="uitk-checkbox-label-content">Vacation rentals</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-HOTEL-tnr" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="HOTEL" aria-label="Hotel"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-HOTEL-tnr">
                                      <p class="uitk-checkbox-label-content">Hotel</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-MOTEL-zm6" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Motel" value="MOTEL"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-MOTEL-zm6"><p class="uitk-checkbox-label-content"> Motel</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-HOTEL_RESORT-arx" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Hotel resort" value="HOTEL_RESORT"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-HOTEL_RESORT-arx"><p class="uitk-checkbox-label-content"> Hotel resort</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-HOSTAL-khl" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Hostal" value="HOSTAL"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-HOSTAL-khl"><p class="uitk-checkbox-label-content"> Hostal</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-APART_HOTEL-15u" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Aparthotel" value="APART_HOTEL"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-APART_HOTEL-15u">
                                      <p class="uitk-checkbox-label-content"> Aparthotel</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-APARTMENT-xw3" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Apartment" value="APARTMENT"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-APARTMENT-xw3"><p class="uitk-checkbox-label-content"> Apartment</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="lodging" id="lodging-CONDO_RESORT-cex" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Condominium resort" value="CONDO_RESORT"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="lodging-CONDO_RESORT-cex"><p class="uitk-checkbox-label-content"> Condominium resort</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uitk-expando-peek-control">
                          <button type="button" aria-label="See more property types" class="uitk-link uitk-expando-peek-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" aria-expanded="false">See more</button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-stid="bed_type_group">
                      <legend><h4 class="uitk-heading uitk-heading-7">Bed Preference</h4></legend>
                      <div class="uitk-expando-peek">
                        <div class="uitk-expando-peek-main uitk-expando-peek-main-inactive hide-scrim">
                          <div class="uitk-expando-peek-inner display-items" data-items-to-display="3">
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="bed_type_group" id="bed_type_group-king_bed-ops" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="king_bed" aria-label="King"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="bed_type_group-king_bed-ops">
                                      <p class="uitk-checkbox-label-content">King</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="bed_type_group" id="bed_type_group-queen_bed-x69" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="queen_bed" aria-label="Queen"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="bed_type_group-queen_bed-x69">
                                      <p class="uitk-checkbox-label-content"> Queen</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="bed_type_group" id="bed_type_group-double_bed-zdh" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="double_bed" aria-label="Double"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="bed_type_group-double_bed-zdh">
                                      <p class="uitk-checkbox-label-content"> Double</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="bed_type_group" id="bed_type_group-twin_bed-ldw" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="twin_bed" aria-label="Twin"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="bed_type_group-twin_bed-ldw">
                                      <p class="uitk-checkbox-label-content"> Twin</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uitk-expando-peek-control">
                          <button type="button" aria-label="See more options" class="uitk-link uitk-expando-peek-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" aria-expanded="false">See more</button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset>
                      <legend class="uitk-spacing uitk-spacing-margin-blockend-two">
                        <h4 class="uitk-heading uitk-heading-7">Neighborhood</h4>
                      </legend>
                      <fieldset class="uitk-radio-button-group">
                        <legend class="is-visually-hidden">neighborhood</legend>
                        <div class="uitk-expando-peek">
                          <div class="uitk-expando-peek-main uitk-expando-peek-main-inactive hide-scrim">
                            <div class="uitk-expando-peek-inner display-items" data-items-to-display="3">
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-2068-nij">
                                    <input type="radio" name="neighborhood" id="neighborhood-2068-nij" class="" value="2068" aria-label="Lahore" aria-describedby=""/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Lahore</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248633981712959-jzd">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248633981712959-jzd" class="" aria-label="Mall Road" aria-describedby="" value="553248633981712959"/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Mall Road</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248633981713732-gh9">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248633981713732-gh9" class="" aria-label="Gulberg" aria-describedby="" value="553248633981713732"/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Gulberg</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248633981712965-zph">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248633981712965-zph" class="" value="553248633981712965" aria-label="Model Town" aria-describedby=""/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Model Town</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248635976008302-qlm">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248635976008302-qlm" class="" aria-label="Green Town" aria-describedby="" value="553248635976008302"/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Green Town</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248633981713738-mtp">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248633981713738-mtp" class="" aria-label="Johar Town" aria-describedby="" value="553248633981713738"/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Johar Town</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248633981711475-kqc">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248633981711475-kqc" class="" aria-label="Township" aria-describedby="" value="553248633981711475"/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Township</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248635977031379-vc5">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248635977031379-vc5" class="" aria-label="Garden Town" aria-describedby="" value="553248635977031379"/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Garden Town</span>
                                  </label>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-radio-button">
                                <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-radio-button-content">
                                  <label class="uitk-radio-button-label" for="neighborhood-553248635976008128-v17">
                                    <input type="radio" name="neighborhood" id="neighborhood-553248635976008128-v17" class="" aria-label="Bahria Town" aria-describedby="" value="553248635976008128"/>
                                    <span aria-hidden="true" class="uitk-radio-button-control"></span>
                                    <span aria-hidden="false" class="uitk-radio-button-label-content">Bahria Town</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uitk-expando-peek-control">
                            <button type="button" aria-label="See more neighborhoods" class="uitk-link uitk-expando-peek-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" aria-expanded="false">See more</button>
                          </div>
                        </div>
                      </fieldset>
                    </fieldset>
                  </div>
                </div>
                <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-stid="mealPlan">
                      <legend>
                        <h4 class="uitk-heading uitk-heading-7">Meal plans available</h4>
                      </legend>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="mealPlan" id="mealPlan-FREE_BREAKFAST-xfg" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="FREE_BREAKFAST" aria-label="Breakfast included"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="mealPlan-FREE_BREAKFAST-xfg">
                                <p class="uitk-checkbox-label-content"> Breakfast included</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="mealPlan" id="mealPlan-HALF_BOARD-nzk" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Lunch included" value="HALF_BOARD"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="mealPlan-HALF_BOARD-nzk">
                                <p class="uitk-checkbox-label-content"> Lunch included</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="mealPlan" id="mealPlan-FULL_BOARD-isy" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Dinner included" value="FULL_BOARD"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="mealPlan-FULL_BOARD-isy">
                                <p class="uitk-checkbox-label-content"> Dinner included</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="mealPlan" id="mealPlan-ALL_INCLUSIVE-1p4" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="ALL_INCLUSIVE" aria-label="All inclusive"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="mealPlan-ALL_INCLUSIVE-1p4">
                                <p class="uitk-checkbox-label-content"> All inclusive</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div> */}
                {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-stid="amenities">
                      <legend>
                        <h4 class="uitk-heading uitk-heading-7">Amenities</h4>
                      </legend>
                      <div class="uitk-expando-peek">
                        <div class="uitk-expando-peek-main uitk-expando-peek-main-inactive hide-scrim">
                          <div class="uitk-expando-peek-inner display-items" data-items-to-display="3">
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-POOL-rxf" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="POOL" aria-label="Pool"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-POOL-rxf">
                                      <p class="uitk-checkbox-label-content"> Pool</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-FREE_PARKING-pxp" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Parking" value="FREE_PARKING"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-FREE_PARKING-pxp">
                                      <p class="uitk-checkbox-label-content"> Parking</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-HOT_TUB-x12" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Hot tub" value="HOT_TUB"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-HOT_TUB-x12">
                                      <p class="uitk-checkbox-label-content"> Hot tub</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-PETS-8co" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="PETS" aria-label="Pet friendly"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-PETS-8co">
                                      <p class="uitk-checkbox-label-content">Pet friendly</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-beach_front-g33" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Beachfront" value="beach_front"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-beach_front-g33">
                                      <p class="uitk-checkbox-label-content"> Beachfront</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-golf_course-azl" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Golf course" value="golf_course"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-golf_course-azl">
                                      <p class="uitk-checkbox-label-content"> Golf course</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-OCEAN_VIEW-p99" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Ocean view" value="OCEAN_VIEW"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-OCEAN_VIEW-p99"><p class="uitk-checkbox-label-content"> Ocean view</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-AIR_CONDITIONING-f0n" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Air conditioned" value="AIR_CONDITIONING"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-AIR_CONDITIONING-f0n"><p class="uitk-checkbox-label-content"> Air conditioned</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-SPA_ON_SITE-a6m" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Spa" value="SPA_ON_SITE"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-SPA_ON_SITE-a6m"><p class="uitk-checkbox-label-content"> Spa</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-KITCHEN_KITCHENETTE-nvy" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Kitchen" value="KITCHEN_KITCHENETTE"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-KITCHEN_KITCHENETTE-nvy"><p class="uitk-checkbox-label-content"> Kitchen</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-WIFI-iw3" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Wifi Included" value="WIFI"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-WIFI-iw3">
                                      <p class="uitk-checkbox-label-content"> Wifi Included</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-WATER_PARK-320" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Water park" value="WATER_PARK"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-WATER_PARK-320">
                                      <p class="uitk-checkbox-label-content"> Water park</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-WASHER_DRYER-3i2" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Washer and dryer" value="WASHER_DRYER"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-WASHER_DRYER-3i2"><p class="uitk-checkbox-label-content"> Washer and dryer</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-ELECTRIC_CAR-gee" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Electric car charging station" value="ELECTRIC_CAR"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-ELECTRIC_CAR-gee"><p class="uitk-checkbox-label-content"> Electric car charging station</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-CASINO-mhy" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Casino" value="CASINO"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-CASINO-mhy">
                                      <p class="uitk-checkbox-label-content"> Casino</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-GYM-fmy" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Gym" value="GYM"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-GYM-fmy"><p class="uitk-checkbox-label-content"> Gym</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-FREE_AIRPORT_TRANSPORTATION-7re" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Airport shuttle included" value="FREE_AIRPORT_TRANSPORTATION"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-FREE_AIRPORT_TRANSPORTATION-7re">
                                      <p class="uitk-checkbox-label-content"> Airport shuttle included</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-BALCONY_OR_TERRACE-w5d" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Outdoor space" value="BALCONY_OR_TERRACE"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-BALCONY_OR_TERRACE-w5d">
                                      <p class="uitk-checkbox-label-content"> Outdoor space</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-RESTAURANT_IN_HOTEL-bx5" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Restaurant" value="RESTAURANT_IN_HOTEL"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-RESTAURANT_IN_HOTEL-bx5">
                                      <p class="uitk-checkbox-label-content"> Restaurant</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                                  <input type="checkbox" name="amenities" id="amenities-bar-b37" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="bar" aria-label="Bar"/>
                                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                                    <label class="uitk-checkbox-label" for="amenities-bar-b37"><p class="uitk-checkbox-label-content">Bar</p></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uitk-expando-peek-control">
                          <button type="button" aria-label="See more amenities" class="uitk-link uitk-expando-peek-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" aria-expanded="false">See more</button>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div> */}
                {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div>
                    <fieldset data-stid="rewards">
                      <legend><h4 class="uitk-heading uitk-heading-7">One Key benefits</h4></legend>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="rewards" id="rewards-VIP-zsy" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="VIP" aria-label="VIP Access properties"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="rewards-VIP-zsy">
                                <p class="uitk-checkbox-label-content"> VIP Access properties</p>
                                <p class="uitk-checkbox-description uitk-type-200">Top-rated stays with member perks</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                          <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                            <input type="checkbox" name="rewards" id="rewards-MEMBER_ONLY-032" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="MEMBER_ONLY" aria-label="Member Prices"/>
                            <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                            <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                              <label class="uitk-checkbox-label" for="rewards-MEMBER_ONLY-032">
                                <p class="uitk-checkbox-label-content"> Member Prices</p><p class="uitk-checkbox-description uitk-type-200">Instant savings of 10% or more on select stays</p>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div> */}
                {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                  <div><fieldset data-stid="accessibility"><legend><h4 class="uitk-heading uitk-heading-7">Accessibility</h4></legend><div class="uitk-expando-peek"><div class="uitk-expando-peek-main uitk-expando-peek-main-inactive hide-scrim"><div class="uitk-expando-peek-inner display-items" data-items-to-display="3"><div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                    <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox"><input type="checkbox" name="accessibility" id="accessibility-SIGN_LANGUAGE_INTERPRETER-ry3" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Sign language-capable staff" value="SIGN_LANGUAGE_INTERPRETER"/>
                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="accessibility-SIGN_LANGUAGE_INTERPRETER-ry3"><p class="uitk-checkbox-label-content"> Sign language-capable staff</p></label></div></div></div>
                  </div><div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                  <input type="checkbox" name="accessibility" id="accessibility-ELEVATOR-sp6" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="ELEVATOR" aria-label="Elevator"/><span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                  <label class="uitk-checkbox-label" for="accessibility-ELEVATOR-sp6"><p class="uitk-checkbox-label-content"> Elevator</p></label></div></div></div></div><div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                  <input type="checkbox" name="accessibility" id="accessibility-STAIR_FREE_PATH-69k" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="STAIR_FREE_PATH" aria-label="Stair-free path to entrance"/><span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                  <label class="uitk-checkbox-label" for="accessibility-STAIR_FREE_PATH-69k"><p class="uitk-checkbox-label-content">Stair-free path to entrance</p></label></div></div></div></div><div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                  <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox"><input type="checkbox" name="accessibility" id="accessibility-SERVICE_ANIMAL-o6t" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Service animals allowed" value="SERVICE_ANIMAL"/><span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="accessibility-SERVICE_ANIMAL-o6t"><p class="uitk-checkbox-label-content"> Service animals allowed</p></label></div></div></div></div><div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox"><input type="checkbox" name="accessibility" id="accessibility-IN_ROOM_ACCESSIBLE-wib" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="IN_ROOM_ACCESSIBLE" aria-label="In-room accessibility"/>
                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="accessibility-IN_ROOM_ACCESSIBLE-wib"><p class="uitk-checkbox-label-content">In-room accessibility</p></label></div></div></div></div>
                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox"><input type="checkbox" name="accessibility" id="accessibility-ACCESSIBLE_PARKING-npz" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Wheelchair accessible parking" value="ACCESSIBLE_PARKING"/>
                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="accessibility-ACCESSIBLE_PARKING-npz"><p class="uitk-checkbox-label-content"> Wheelchair accessible parking</p></label></div></div></div></div><div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox"><input type="checkbox" name="accessibility" id="accessibility-ROLL_IN_SHOWER-ln1" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="ROLL_IN_SHOWER" aria-label="Roll-in shower"/>
                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="accessibility-ROLL_IN_SHOWER-ln1"><p class="uitk-checkbox-label-content"> Roll-in shower</p></label></div></div></div></div>
                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox"><input type="checkbox" name="accessibility" id="accessibility-ACCESSIBLE_BATHROOM-622" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" aria-label="Accessible bathroom" value="ACCESSIBLE_BATHROOM"/>
                  <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="accessibility-ACCESSIBLE_BATHROOM-622"><p class="uitk-checkbox-label-content"> Accessible bathroom</p></label></div></div></div></div></div></div><div class="uitk-expando-peek-control">
                  <button type="button" aria-label="See more accessibility options" class="uitk-link uitk-expando-peek-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" aria-expanded="false">See more</button>
                  </div>
                  </div>
                  </fieldset>
                  </div>
                  </div> */}
                  {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                    <div>
                    <fieldset data-stid="travelerType"><legend><h4 class="uitk-heading uitk-heading-7">Traveler experience</h4></legend>
                  <div class="uitk-expando-peek">
                 <div class="uitk-expando-peek-main uitk-expando-peek-main-inactive hide-scrim"><div class="uitk-expando-peek-inner display-items" data-items-to-display="4"><div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                  <input type="checkbox" name="travelerType" id="travelerType-ECO_CERTIFIED-d2q" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="ECO_CERTIFIED" aria-label="Eco-certified"/><span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                  <label class="uitk-checkbox-label" for="travelerType-ECO_CERTIFIED-d2q"><p class="uitk-checkbox-label-content">Eco-certified</p></label></div></div></div></div>
                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one"><div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                  <input type="checkbox" name="travelerType" id="travelerType-LGBT-o0t" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="LGBT" aria-label="LGBTQ welcoming"/><span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="travelerType-LGBT-o0t">
                  <p class="uitk-checkbox-label-content"> LGBTQ welcoming</p><p class="uitk-checkbox-description uitk-type-200">See properties that pledge to make all guests feel safe, welcome, and respected.</p></label></div></div></div></div>
                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two"><div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                  <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox"><input type="checkbox" name="travelerType" id="travelerType-BUSINESS-s1j" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="BUSINESS" aria-label="Business friendly"/><span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"></span>
                  <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content"><label class="uitk-checkbox-label" for="travelerType-BUSINESS-s1j"><p class="uitk-checkbox-label-content"> Business friendly</p><p class="uitk-checkbox-description uitk-type-200">See properties with amenities to help you work comfortably, like WiFi and breakfast.</p></label></div></div></div></div>
                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-two">
                   <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-spacing uitk-spacing-padding-inlinestart-one">
                      <div class="uitk-layout-flex uitk-layout-flex-flex-wrap-nowrap uitk-checkbox">
                        <input type="checkbox" name="travelerType" id="travelerType-FAMILY-85w" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" value="FAMILY" aria-label="Family friendly"/>
                        <span aria-hidden="true" class="uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0 uitk-checkbox-control"> </span>
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-checkbox-content">
                          <label class="uitk-checkbox-label" for="travelerType-FAMILY-85w">
                            <p class="uitk-checkbox-label-content"> Family friendly</p>
                            <p class="uitk-checkbox-description uitk-type-200">See properties that include family essentials like in-room conveniences and activities for the kids.</p>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </fieldset>
                  </div>
                  </div> */}
                  </div>
              <div className='page-sidebar hide-page_filter '>
                {/* <div className='widget widget_has_radio_checkbox'>
                  <div className='mb-2'>
                  <ul>
                    <li>
                      <label>
                        <input type='checkbox' 
                        className='custom-textbox' 
                        onChange={handleCheckboxChange} name='rating5' value='5' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating4' value='4' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox' className='custom-textbox' onChange={handleCheckboxChange} name='rating3' value='3' />
                        <i className='awe-icon awe-icon-check'></i>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox'className='custom-textbox' onChange={handleCheckboxChange} name='rating2' value='2' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                    <li>
                      <label>
                        <input type='checkbox'className='custom-textbox'  onChange={handleCheckboxChange} name='rating1' value='1' />
                        <i className='awe-icon awe-icon-check'></i>
                        <span className='rating'>
                          <i className='fa fa-star'>
                            {' '}
                            <FontAwesomeIcon icon={faStar} />
                          </i>
                        </span>
                      </label>
                    </li>
                  </ul>
                  </div>
                </div> */}
                <div className='widget widget_has_radio_checkbox' style={{borderBottom:'0'}}>
                  <div className='filter-show-hide'>                
                  <h3>Meal Type</h3>
                  {/* <FontAwesomeIcon icon={faAngleDown}/> */}
                  </div>
                    <div className='mb-2'>
                        <ul>
                          <li>
                            <label>
                              <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal1' value='ROOM ONLY' />
                              <i className='awe-icon awe-icon-check'></i>
                              <span className='rating'>  Room Only</span>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal2' value='BED AND BREAKFAST' />
                              <i className='awe-icon awe-icon-check'></i>
                              <span className='rating'> BED AND BREAKFAST </span>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal3' value='HALF BOARD' />
                              <i className='awe-icon awe-icon-check'></i>
                              <span className='rating'> HALF BOARD</span>
                            </label>
                          </li>
                          <li>
                            <label>
                              <input type='checkbox' className='custom-textbox' onChange={handleMealTypeChange} name='meal4' value='Full BOARD' />
                              <i className='awe-icon awe-icon-check'></i>
                              <span className='rating'> Full BOARD</span>
                            </label>
                          </li>
                        </ul>
                        <a href='#' onClick={MealFilter}>Apply</a>
                        {/* <button style={{width:'96%'}} onClick={MealFilter} className='btn btn-warning m-2'>Apply</button> */}
                    </div>
                </div>
                <div className='widget widget_has_radio_checkbox' style={{borderBottom:'0'}}>
                  {/* <div className='filter-show-hide' onClick={()=>Showfilter(3)}>                
                  <FontAwesomeIcon icon={faAngleDown}/>
                  </div> */}
                  <div className='my-3'>
                    <h3>Facilities</h3>
                    <ul>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility1' value='Wi-fi' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Wi-fi</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility2' value='Internet access' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Internet access </span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility3' value='TV' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> TV</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Wake-up service' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Wake-up service</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility4' value='Smoking rooms' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Smoking rooms</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility6' value='Wheelchair-accessible' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Wheelchair-accessible</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox'  className='custom-textbox' onChange={handleFacilityChange} name='facility7' value='Laundry service' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Laundry service</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility8' value='Banquet hall' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Banquet hall</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility9' value='Non-smoking establishment' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Non-smoking establishment</span>
                        </label>
                      </li>
                      <li>
                        <label>
                          <input type='checkbox' className='custom-textbox' onChange={handleFacilityChange} name='facility10' value='Safe' />
                          <i className='awe-icon awe-icon-check'></i>
                          <span className='rating'> Safe</span>
                        </label>
                      </li>
                    </ul>
                    <a href='#' onClick={FacilityFilter}>Apply</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-9 col-md-push-3 mt-3'>
            {/* <div  className="d-flex align-items-center w-100 justify-content-between">
              <div className=' ms-2'>
                <h5 className='title font-size-24 tc' id='tours_result'>
                  {hotelsSearchData.destination_name} - {hotelsSearchData.country}
                </h5>
                <h6 className='title font-size-24 tc' id='tours_result'>
                {hotelsSearchData.adult !== 0 && (
                  <>
                    {hotelsSearchData.adult} adult {' '}
                  </>
                )}
                {hotelsSearchData.child !== 0 && (
                  <>
                    - {hotelsSearchData.child} child
                  </>
                )}
                
                </h6>
              </div>
                <h6 className='title font-size-24 tc' id='tours_result'>
                      {moment(hotelsSearchData.check_in).format('MMM Do ')}{' '}
                      <FontAwesomeIcon icon={faArrowRight} />{' '}
                      {moment(hotelsSearchData.check_out).format('MMM Do ')}      
                    </h6>
            </div> */}
            <section data-stid="results-header" class="uitk-spacing uitk-spacing-margin-blockstart-one px-3">
              <header style={{boxShadow:'none !important'}}>
                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid tour-main">
                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-5">
                    <div data-stid="results-header-messages">
                      <a href="https://www.expedia.com/lp/b/sort-order-info" target="_blank" rel="noopener" analytics="[object Object]" class="uitk-more-info-trigger uitk-more-info-trigger-size-small uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading">
                        <div class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme"> <h6 className='title font-size-24 tc' id='tours_result' style={{fontWeight:'bold'}}>{hotels.hotel_beds_counts} Hotels Available </h6><b style={{fontWeight:'bold'}}>How our sort order works</b>
                          <svg class="uitk-icon uitk-spacing uitk-spacing-padding-inlinestart-one uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start tour-sort">
                    <div class="">
                      <form class="uitk-form">
                        <div>
                          <div class="uitk-field uitk-field-select-field has-floatedLabel-label">
                            <label for="sort-filter-dropdown-sort" class="uitk-field-label is-visually-hidden">Sort by</label>
                            <select id="sort-filter-dropdown-sort" name="sort" class="uitk-field-select">
                              <option selected="" value="RECOMMENDED" aria-selected="true" data-opt-id="RECOMMENDED">Recommended</option>
                              <option value="PRICE_LOW_TO_HIGH" aria-selected="false" data-opt-id="PRICE_LOW_TO_HIGH">Price: low to high</option>
                              <option value="PRICE_HIGH_TO_LOW" aria-selected="false" data-opt-id="PRICE_HIGH_TO_LOW">Price: high to low</option>
                              <option value="DISTANCE" aria-selected="false" data-opt-id="DISTANCE">Distance from downtown</option>
                              <option value="REVIEW_RELEVANT" aria-selected="false" data-opt-id="REVIEW_RELEVANT">Guest rating + our picks</option>
                              <option value="PROPERTY_CLASS" aria-selected="false" data-opt-id="PROPERTY_CLASS">Star rating</option>
                              <option value="PACKAGE_SAVINGS" aria-selected="false" data-opt-id="PACKAGE_SAVINGS">Package discount</option>
                            </select>
                            <svg class="uitk-icon uitk-field-select-icon uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path>
                            </svg>
                            <div class="uitk-field-label" aria-hidden="true">Sort by</div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </header>
            </section>
            <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 my-2">
              <div data-testid="one-key-banner-1 px-0" id="one-key-banner-1">
                  <div>
                      <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme px-0" data-stid="one-key-message-card">
                          <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                              <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                  <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                      <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                  </div>
                              </div>
                              <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                          <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                              <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">You'll always get our best prices when you're signed in!</h2>
                                          </div>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                          <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                            <a href="https://www.expedia.com/login?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                            <a href="https://www.expedia.com/welcome-one-key?&amp;uurl=e3id%3Dredr%26rurl%3D%2F" target="_self" data-stid="one-key-message-card-UITertiaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-tertiary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-two uitk-button-tertiary-inverse">Create an account</a>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <HotelCard hotelid={hotelid}/>
          </div>
        </div>
      </div>
      </Layout>
    </>
  )
}

export default Hotels
