
import React,{useState,useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobe,
  faInfoCircle,
  faPerson,
  faCar,
  faSuitcase,
  faClock
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../Loading/Loader";
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Axios from "axios";
import { NewTransferSearchToken,ApiEndPoint } from "../GlobalData/GlobalData";
function TransferCard(props){
  const [isLoading, setIsLoading] = useState(false);
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const token=NewTransferSearchToken();
  const endpoint=ApiEndPoint();
  const navigate=useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const TransferPerPage =10; // Number of hotels to display per page
  const indexOfLastTransfer = currentPage * TransferPerPage;
  const indexOfFirstTransfer = indexOfLastTransfer - TransferPerPage;
  const currentransfers =props.TransferData.slice(indexOfFirstTransfer, indexOfLastTransfer);
  const pagesCount = Math.ceil(props.TransferData.length / TransferPerPage);
  const validCurrentPage = currentPage < 1 ? 1 : currentPage;
  const maxPagesToShow = 5;

  const startHotelIndex = indexOfFirstTransfer + 1;
  const endHotelIndex = Math.min(indexOfLastTransfer, props.TransferData.length);
  const paginate = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    // Adjust the current page if it exceeds the total number of pages
    if (currentPage < 1) {
      setCurrentPage(1);
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  }, [ currentPage, pagesCount]); 

  useEffect(() => {
    setCurrentPage(1);
  }, [ props.TransferData]); 

  const renderPaginationItems = () => {
    const items = [];
    let startPage = currentPage - Math.floor(maxPagesToShow / 2);
    startPage = Math.max(startPage, 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, pagesCount);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => paginate(i)} href="#">
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };
  const BookTransfer=async(id)=>{
    var transfer=props.TransferData.filter((item)=>item.destination_id===id);
    sessionStorage.setItem('TransferID', id);
    if(transfer[0].booking_From !=="3rd Party API"){
      navigate('/transfer-checkout');
    }else{
      setIsLoading(true);
      var extras_avline=[];
      if(transfer[0]?.extras_Avline){
        extras_avline=JSON.parse(transfer[0]?.extras_Avline);
       }
      var data={
       'token': token,
       'extras_Avline':extras_avline.length ===0 ? JSON.stringify([]):JSON.stringify(extras_avline),
       'sessionID':props.sessionID,
	      'bookingid': transfer[0].destination_id
      }

      try {
         
        const response = await Axios.post(endpoint+'/api/book_Transfer_Api',data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          } ,
          
        });
        setIsLoading(false);
        if(response.data.message==="Success"){
          sessionStorage.setItem('TransferTransectionNumber', response.data.transactionNumber);
          sessionStorage.setItem('Extradata', JSON.stringify(response.data.extras_Data));
          navigate('/transfer-checkout');
        }
      
      } catch (error) {
        setIsLoading(false);
        console.error('Error:', error);
      }


    }
  };
  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      var baseprice =price;
    } else {
      const conversionrate = GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency];
      var newprice = (Number(conversionrate) * Number(price)).toFixed(0);
      var baseprice =newprice;
     
    }
    return baseprice
  };


    return(
        <>
        {isLoading && ( <Loading/>)}
        <div>
          <div className='filter-page__content'>
            <div className='fw-bold  m-2'>Showing {startHotelIndex} to {endHotelIndex} of {props.TransferData.length} Transfers</div>                   
            <div className='filter-item-wrapper' id='tours_filter'>
              {currentransfers.map((item, index) => (
                  <div key={index} className='mb-4'>
                    <div className='row parent_row py-0 mx-2' >
                      <div className='col-md-3 item-from px-0'>
                          <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-left-zero uitk-layout-position-relative">
                            <figure class="uitk-image uitk-layout-position uitk-layout-position-zindex-layer2 uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                              <span>
                                <span>
                                  <div class="uitk-gallery-carousel">
                                    <div class="uitk-gallery-carousel-items rounded-start-4" style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                                      <div class="uitk-gallery-carousel-item-current p-4" aria-hidden="false">
                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                            <div class="uitk-image-placeholder">
                                                <img alt="Room" class="uitk-image-media" src={item.vehicle_image} />
                                            </div>
                                        </figure>
                                      </div>
                                    </div>
                                  </div>
                                </span>
                              </span>
                            </figure>
                            <div class="uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-right-zero uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                              <div data-stid="outlined-save-button">
                                <div class="favorite-button-wrapper">
                                  <button type="button" class="favorite-button favorite-button-button-no-label">
                                    <svg class="uitk-icon favorite-button-fill favorite-button-fill-default" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" d="m12 22-8.44-8.69a5.55 5.55 0 0 1 0-7.72C4.53 4.59 5.9 4 7.28 4c1.4 0 2.75.59 3.72 1.59l1 1.02 1-1.02c.97-1 2.32-1.59 3.72-1.59 1.4 0 2.75.59 3.72 1.59a5.55 5.55 0 0 1 0 7.72L12 22Z" clip-rule="evenodd"></path>
                                    </svg>
                                    <svg class="uitk-icon favorite-button-border favorite-button-border-default" style={{fill:'black'}} aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" d="m12 22 8.44-8.69a5.55 5.55 0 0 0 0-7.72A5.24 5.24 0 0 0 16.72 4 5.22 5.22 0 0 0 13 5.59L12 6.6l-1-1.02a5.2 5.2 0 0 0-7.44 0 5.55 5.55 0 0 0 0 7.72L12 22Zm0-2.87-7-7.21a3.55 3.55 0 0 1 0-4.94C5.6 6.36 6.44 6 7.28 6c.84 0 1.69.36 2.3.98L12 9.48l2.43-2.5c.6-.62 1.45-.98 2.29-.98.84 0 1.68.36 2.28.98a3.55 3.55 0 0 1 0 4.94l-7 7.2Z" clip-rule="evenodd" opacity=".9"></path>
                                    </svg>
                                  </button>
                                </div>
                              </div> 
                            </div>
                          </div>
                      </div>
                      <div className='col-md-9'>
                        <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 p-2">
                          <div class="uitk-layout-flex">
                            <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1 uitk-layout-flex-item-flex-shrink-1">
                              <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid flex-container">
                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-layout-grid-item" aria-hidden="false"> {item.vehicle_Name} ({item.transfer_type}) </h3>
                              </div>
                              <div class="uitk-layout-flex uitk-spacing flex-container uitk-spacing-margin-medium-inlineend-twelve uitk-spacing-margin-large-inlineend-unset uitk-spacing-padding-inlinestart-three uitk-spacing-padding-medium-inlineend-twelve uitk-spacing-padding-large-inlineend-unset mt-2">
                                <section class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-half_width uitk-layout-flex-item-flex-grow-1 uitk-layout-flex-item-flex-shrink-1 uitk-spacing icon-s uitk-spacing-padding-inlineend-three">
                                  <div role="list">
                                    <div role="listitem">
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-margin-blockend-one"> Type : {item?.TransferType ?item?.TransferType:item.transfer_type }r</div>
                                    </div>
                                    <div role="listitem">
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-one">
                                        {item.booking_From === '' ? null: (
                                          <div class='row mt-1'>
                                            <div className='col-6 mt-1'>
                                              <div class='single-tour-feature d-flex align-items-center mb-3'>
                                                <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                                  <i class='fas fa-check'>
                                                    <FontAwesomeIcon icon={faPerson} />
                                                  </i>
                                                </div>
                                                <div class='single-feature-titles'>
                                                  <p class='title fw-bold'>Capacity</p>
                                                  <p class='title fw-bold mt-0'>{item.OccupancyFrom} to {item.OccupancyTo}</p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-6 mt-1'>
                                              <div class='single-tour-feature d-flex align-items-center mb-3'>
                                                <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                                  <i class='fas fa-check'>
                                                    <FontAwesomeIcon icon={faSuitcase} />
                                                  </i>
                                                </div>
                                                <div class='single-feature-titles'>
                                                  <p class='title fw-bold'>Baggages</p>
                                                  <p class='title fw-bold mt-0'>{item.SmallBagAllowance} to {item.BigBagAllowance}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div role="listitem">
                                      <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-one">
                                        {item.booking_From === '' ? null: (
                                          <div class='row mt-1'>
                                            <div className='col-6'>
                                              <div class='single-tour-feature d-flex align-items-center mb-3'>
                                                <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                                  <i class='fas fa-check'>
                                                    <FontAwesomeIcon icon={faClock} />
                                                  </i>
                                                </div>
                                                <div class='single-feature-titles'>
                                                  <p class='title fw-bold'>Duration</p>
                                                  <p class='title fw-bold mt-0'>{item.duration} </p>
                                                </div>
                                              </div>
                                            </div>
                                            <div className='col-6'>
                                              <div class='single-tour-feature d-flex align-items-center mb-3'>
                                                <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                                  <i class='fas fa-check'>
                                                    <FontAwesomeIcon icon={faCar} />
                                                  </i>
                                                </div>
                                                <div class='single-feature-titles'>
                                                  <p class='title fw-bold'>Class</p>
                                                  <p class='title fw-bold mt-0'>{Object.keys( item.VehicleClass).length === 0 ? item.VehicleMake: item.VehicleClass }</p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </section>
                                <section class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-half_width uitk-layout-flex-item-flex-grow-1 uitk-layout-flex-item-flex-shrink-1">
                                  <div class="uitk-text uitk-type-300 uitk-text-default-theme">
                                    <ul class="confidence-messages">
                                      <li>
                                        <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-positive-theme">Free cancellation</div>
                                      </li>
                                      <li>
                                        <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-positive-theme">Online check-in</div>
                                      </li>
                                      <li>
                                        <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-positive-theme">Pay at pick-up</div>
                                      </li>
                                      <li>
                                        <div class="uitk-text uitk-type-start uitk-type-300 uitk-text-positive-theme">Reserve without a credit card</div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="uitk-spacing vendor-logo-container uitk-spacing-padding-blockstart-two">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                        {/* <div class="uitk-image-placeholder">
                                          <img alt="Alamo Rent A Car" class="uitk-image-media uitk-spacing vendor-logo uitk-layout-flex-item" src="https://mediaim.expedia.com/cars/logos/AL.png"/>
                                        </div> */}
                                        <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-spacing uitk-spacing-margin-inlinestart-one uitk-spacing-margin-inlineend-one uitk-layout-flex-item">
                                          <span class="uitk-badge-base-text" aria-hidden="false">86%</span>
                                        </span>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                        <div class="uitk-text uitk-type-300 uitk-type-medium uitk-text-emphasis-theme uitk-layout-flex-item">Excellent</div>
                                        <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-emphasis-theme uitk-layout-flex-item">799 ratings</div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="uitk-text uitk-type-300 uitk-text-default-theme"></div>
                                </section>
                              </div>
                            </div>
                            <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-end uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0" style={{flexBasis: '117px', textAlign:'end'}}>
                              <div class="uitk-layout-flex-item">
                                <div>
                                  <section class="cars-offer-price right-align">
                                    <div class="price-qualifier-container" aria-hidden="true">
                                      <span class="per-day-price">{CurrencyRates===undefined ? item.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(item.total_fare_markup))}</span>
                                      <div class="per-day-price-qualifier"></div>
                                    </div>
                                    <div class="total-price-container">
                                      <span class="total-price-container" aria-hidden="true">
                                        <span class="total-price">{CurrencyRates===undefined ? item.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(item.total_fare_markup))}</span>
                                        <span class="uitk-spacing total-price-qualifier uitk-spacing-padding-inlinestart-one">total</span>
                                      </span>
                                    </div>
                                  </section>
                                </div>
                              </div>
                              <a data-stid="default-link" data-testid="default-link" class="uitk-spacing offer-reserve-button uitk-spacing-margin-block-three">
                                <button tabindex="-1" name="textonly" type="button" class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-primary" onClick={()=>BookTransfer(item.destination_id)}> Reserve </button>
                              </a>
                              <div class="uitk-layout-flex-item"> <div> </div> </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='col-md-3'>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium">
                          <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between">
                            <div class="uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item">
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x">
                                <div class="uitk-text uitk-type-200 uitk-text-default-theme">
                                  <p className="fw-bold">Pick-Up</p>
                                  <div class='item-address'>
                                    <i class='awe-icon awe-icon-marker-2'>
                                      <FontAwesomeIcon icon={faGlobe} />
                                    </i>{' '}
                                    {item.pickup_City}{' '}
                                  </div>
                                  <p className="fw-bold">Drop-Of</p>
                                  <div class='item-address'>
                                    <i class='awe-icon awe-icon-marker-2'>
                                      <FontAwesomeIcon icon={faGlobe} />
                                    </i>{' '}
                                    {item.dropof_City}{' '}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex">
                              <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-">
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                      <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard">
                                        <span class="uitk-badge-base-text" aria-hidden="true">
                                          Date : {item.pickup_date}{' '}
                                        </span>
                                      </span>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-300 uitk-type-medium uitk-text-emphasis-theme" aria-hidden="true">Excellent</div>
                                          <span class="is-visually-hidden">  
                                            <p className='card-star'>
                                            {item.starts_rating === 'No_Rating' ? (
                                                <span className='fw-bold'>No Rating</span>
                                              ) : (
                                                <>
                                                  {item.starts_rating}.0
                                                  {Array.from({
                                                    length: parseInt(item.starts_rating, 10)
                                                  }).map((_, index) => (
                                                    <i key={index} className='fa fa-star'>
                                                      {' '}
                                                      <FontAwesomeIcon icon={faStar} />
                                                    </i>
                                                  ))}
                                                </>
                                              )}
                                            </p>
                                          </span>
                                        </span>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-200 uitk-type-regular uitk-text-default-theme" aria-hidden="true">555 reviews</div>
                                          <span class="is-visually-hidden">(555 reviews)</span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
              ))}
            </div>
            <div className='row'>
              <div className='col-md-6 col-sm-12  col-12'>
                <div className='fw-bold  m-2'>Showing {startHotelIndex} to {endHotelIndex} of {props.TransferData.length} Transfers</div>                   
              </div>
              <div className='col-md-6 col-sm-12 col-12'>
                  <Pagination aria-label="Page navigation example ">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink previous onClick={() => paginate(validCurrentPage  - 1)} />
                    </PaginationItem>
                    {renderPaginationItems()}
                    <PaginationItem disabled={currentPage === pagesCount}>
                      <PaginationLink next onClick={() => paginate(validCurrentPage  + 1)} />
                    </PaginationItem>
                  </Pagination>            
              </div>
            </div> 
          </div>
        </div>
        </>
    );
}

export default TransferCard;