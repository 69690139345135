import React,{useState,useEffect} from "react";
import { useSelector } from "react-redux";
import WithoutLayout from "../../Components/Layout/WithoutLayout";
import calender from '../../Images/calendar_shield.svg';
import Select from 'react-select';
import Axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarDays,
  faCheck,
  faHeadset,
  faAngleDown,
  faAngleRight,
  faWindowRestore,
  faCancel,
  faClock,
  faDiagramProject,
  faSnowflake,
  faStar,
  faUser,
  faCar,
  faLock,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { Stripe } from 'stripe';
import Loading from "../../Components/Loading/Loader";
import { ApiEndPoint,TransferCheckoutToken,CustomerDomainName } from "../../Components/GlobalData/GlobalData";
import { ToastContainer, toast } from 'react-toastify';
import payment from '../../Images/Packages/Logo/payment-img.png';
import { useNavigate } from "react-router-dom";
import { StripeClientSecret } from "../../Components/GlobalData/GlobalData";
import TransferNew from "../../Components/SearchBar/TransferNew";
import { useStripe, useElements,CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
var StripePayment=0;
var StripeCurrency='';
var exchangeRate=0;
function TransferCheckout(){
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const Picurl=CustomerDomainName();
  const navigate=useNavigate();
  const endpoint=ApiEndPoint();
  const token=TransferCheckoutToken();
    const Transferid = sessionStorage.getItem('TransferID');
    const TransferDetail=useSelector((state) => state.hotels.TrSeResponse);
    const NewSelectedTransfer= TransferDetail?.transfers_list.filter((item)=>Number(item.destination_id)===Number(Transferid));
    var Secretkey=StripeClientSecret();
    const stripe = new Stripe(Secretkey);
    const elements = useElements();
    const stripeInstance = useStripe();
    const [error, setError] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isBooking, setIsBooking] = useState(false);
    const [cardInfo, setCardInfo] = useState({
      name: '',
    });
    const [selectedTransfer, setSelectedTransfer] = useState(NewSelectedTransfer[0]);
    const [selectedNationality, setSelectedNationality] = useState('');
    const [gestinfo, setGestinfo] = useState({
        title:'',
        firstname: '',
        lastname: '',
        dateofbirth: '',
        numbercode:'',
        email: '',
        phonenumber: '',
        pno:'',
        country:'',
        nationality:'',
      });
        useEffect(()=>{
            fetchData();
        },[]);

      async function fetchData () {
        Axios.get('https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd')
              .then(response => {
                const countryOptions = response?.data.map((country) => ({
                    value:country.name.common,
                    label: country.name.common,
                    flag: country.flags.png,
                    phoneCode: country.idd.root+country.idd.suffixes[0],
                  }));
                setCountryList(countryOptions)
              })
              .catch(error => {
                console.error('Error:', error)
              })
      };
      const handlegestchange=(e)=>{
        const {value,name}=e.target;
        setGestinfo(prevgestinfo=>({...prevgestinfo,
          [name]:value,
        }));
      };
      const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
         setSelectedNationality(selectedOption.value);
        setGestinfo(prevdata=>({...prevdata, phonenumber:selectedOption.phoneCode, numbercode:selectedOption.phoneCode,nationality:selectedOption.value }));
      };

      const handlecarddetail=(e)=>{
        const {value,name}=e.target;
        setCardInfo(prevcardinfo=>({...prevcardinfo,
        [name]:value,
        }));
      };

      const handlePayment = async () => {
        if(cardInfo.name===""){
          setError("Enter Card Holder Name.");
          return { success: false};
        };
        const response = await stripe.paymentIntents.create({
          amount: Number(StripePayment)*100, // Amount in cents
          currency: StripeCurrency,
          description: 'Example payment',
          payment_method_types: ['card'],
        });
        var Client_Secret= response.client_secret;
        
        try {
          // Confirm the payment intent with the payment method
          const { paymentIntent, error } = await stripeInstance.confirmCardPayment(Client_Secret, {
            payment_method: {
              card: elements.getElement(CardNumberElement),
              billing_details: {
                name:cardInfo.name,
              },
            },
          });
    
          if (error) {
             setError(error.message);
             
            console.error(error);
            setIsBooking(false);
            return { success: false};
          } else {
            return { success: true,data:paymentIntent};
          }
        } catch (error) {
          console.error('Error:', error);
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT
        });
        setIsBooking(false);
    
          return { success: false};
        }
      };

      const BookTransfer=async(newdata)=>{
                var status=await handlePayment();
                  if(status.success===false){
                    setIsBooking(false);
                    return;
                  };
                  const LeadPassengerData= {
                    "lead_passenger_details": {
                      "lead_title": gestinfo.title,
                      "lead_first_name": gestinfo.firstname,
                      "lead_last_name": gestinfo.lastname,
                      "lead_email": gestinfo.email,
                      "lead_date_of_birth": gestinfo.dateofbirth,
                      "lead_country": gestinfo.numbercode,
                      "lead_phone": gestinfo.phonenumber,
                      "passport_img": ""
                    },
                    "other_passenger_details": [],
                    "transfer_price_details": {
                      "original_price_transfer": selectedTransfer?.total_fare_markup,
                      "original_price_total_transfer": (selectedTransfer?.search_passenger*selectedTransfer?.total_fare_markup),
                      "original_curreny_transfer":  selectedTransfer?.sale_currency,
                      "exchange_price_transfer": StripePayment,
                      "exchange_price_total_transfer":StripePayment,
                      "exchange_curreny_transfer":StripeCurrency,
                      "destination_avail_id": selectedTransfer?.destination_id,
                      "no_of_paxs_transfer": selectedTransfer?.search_passenger
                    }
                  };
                  debugger
                  const extraData = sessionStorage.getItem('Extradata');
                  var bookingdata={
                    'token': token,
                    'extras_Data':extraData,
                    'slc_pyment_method':JSON.stringify(status.data),
                    'transfer_data': JSON.stringify(LeadPassengerData),
                    'transfer_destination_data':JSON.stringify(selectedTransfer),
                    'booking_From':selectedTransfer.booking_From,
                    'response_confirm_booking':JSON.stringify(newdata)
                  };

                  try {
                    const response = await Axios.post(endpoint+'/api/transfer_checkout_submit_react',bookingdata , {
                      headers: {
                      "Access-Control-Allow-Origin": "*",
                      } ,
                      
                    });
                    setIsBooking(false);
                    if(response.data.status==='success' ){
                         navigate(`/transfer_invoice/${response.data.Invoice_no}`);
                    }else{
                      toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                      })
                      return;
                    }
                  } catch (error) {
                    setIsBooking(false);
                    console.error('Error:', error);
                  }
          

      };

        const ThrirdPartyTransfer=async()=>{
      if(isChecked){
          if(gestinfo.title==='')
          {
            toast.error('Please Select Lead Guest Title.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
          }else if(gestinfo.firstname===''){
            toast.error('Please Enter Lead Guest First Name.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
          }else if(gestinfo.lastname===''){
            toast.error('Please Enter Lead Guest Last Name.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
          }else if(gestinfo.email===''){
            toast.error('Please Enter Lead Guest Email.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
          }else if(selectedNationality===''){
            toast.error('Please Select Lead Guest Nationality.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
          }else if(gestinfo.phonenumber.length <= 5){
            toast.error('Please Enter Phone Number.', {
              position: toast.POSITION.TOP_RIGHT
          });
          return;
          };
          setIsBooking(true);
          if(selectedTransfer.booking_From !== '3rd Party API'){
            BookTransfer();
          };
          const  TransectionNumber= sessionStorage.getItem('TransferTransectionNumber');
          var bookingData= {
            "country": selectedTransfer.country,
            "propertyname": gestinfo.firstname,
            "j1propertyname":gestinfo.lastname,
            "accomodationaddress":'H#10 St#9 etc',
            "deppoint": selectedTransfer.country,
            "depinfo": selectedTransfer.pickup_City,
            "transactionnumber": TransectionNumber,
            "title": gestinfo.title,
            "firstname": gestinfo.firstname,
            "lastname": gestinfo.lastname,
            "email":gestinfo.email,
            "phone": gestinfo.phonenumber,
            "mobile": gestinfo.phonenumber,
        };

          var data={
            'token':token,
            'confirm_Booking_Arr':JSON.stringify(bookingData),
          };

          try {
            const response = await Axios.post(endpoint+'/api/confbook_Transfer_Api',data , {
              headers: {
              "Access-Control-Allow-Origin": "*",
              } ,
              
            });
            
            if(!response.data.error){
              BookTransfer(response.data);
            }else{
              setIsBooking(false);
              toast.error(response.data.error, {
                position: toast.POSITION.TOP_RIGHT
              })
              return;
            }
          } catch (error) {
            setIsBooking(false);
            console.error('Error:', error);
            toast.error(error.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        }else{
                toast.error('Please Agree with Terms and Conditions.', {
                  position: toast.POSITION.TOP_RIGHT
              });
            }

        }

      const handleCheckboxContinue=()=>{
        setIsChecked(!isChecked);
      };

      const renderPrice = price => {
        if (CurrencyRates === undefined) {
          var baseprice =price;
        } else {
          const conversionrate = GBPCurrencyRates.conversion_rates[CurrencyRates.selectedcurrency];
          exchangeRate=conversionrate;
          var newprice = (Number(conversionrate) * Number(price)).toFixed(0);
          var baseprice =newprice;
         
        }
        return baseprice
      };
    return(
        <>
        {isBooking && (
          <Loading/>        
          )}
        <ToastContainer/>
          <WithoutLayout>
            <div className="container mt-2">
              <h1 class="section-header-main" style={{fontWeight:'30px'}}>Secure booking - only takes 2 minutes!</h1>
              <div id="free-cancellation-alignment" class="uitk-grid flex-layout-custom flex-listing flex-theme-light checkout-hotel-detail">
                <div id="free-cancellation-message" class="uitk-col ">
                  <div class="flex-card d-flex">
                    <div class="flex-figure">
                      <img class="image background aspect-ratio1-1" src={calender} />
                    </div>
                    <div class="flex-content"><div class="flex-area-primary"><p class="confidence-text-header">Free cancellation if plans change</p><p class="confidence-text-body">Cancel free of charge anytime. Reserve now and pay at pick up.</p></div></div>
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-lg-8 margin-checkout'>
                  <div class="uitk-spacing bex-homepage-module SimpleContainer px-0 mb-3 rounded-0">
                    <div data-testid="one-key-banner-1" id="one-key-banner-1" className='px-0 rounded-0'>
                        <div className='rounded-0'>
                            <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme rounded-0 chech-theme" data-stid="one-key-message-card">
                                <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three rounded-0">
                                    <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                        <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                            <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                        </div>
                                    </div>
                                    <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                                <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                                    {/* <p class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme"> */}
                                                      <span class="one-key-loyalty-text" data-user-type="guest">Sign in or create an account to earn <strong><span data-price-update="oneKeyLoyaltyEarnModulePoints">$15.53</span></strong> in OneKeyCash™ after this trip.</span>
                                                    {/* </p> */}
                                                </div>
                                            </div>
                                            <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                                <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                                  <span class="universal-login-forward-icon" data-user-type="guest">
                                                      <span class="icon icon-toggle90" aria-hidden="true" style={{color:'white', fontSize:'20px'}}><FontAwesomeIcon icon={faAngleRight} /></span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='hotel-checkout-shadow p-3'>
                      <div className="row">
                        <div> 
                          <h4 style={{color:'black'}}>Who's driving?</h4>
                          <div class="traveler-info-header module">
                            <h3 class="module-subtitle"><span class="car-class">{selectedTransfer.vehicle_Name}</span></h3>
                            <p class="travel-date">{selectedTransfer.pickup_date}</p>
                          </div>
                          <div id="confidence-items" class="confidence-items">
                            <ul class="confidence-items">
                              <li class="confidence-item unlimited-mileage">
                                <span class="icon icon-success" aria-hidden="true" style={{paddingRight:'10px'}}><FontAwesomeIcon icon={faCheck} /></span>  
                                <span class="confidence-text">Unlimited mileage</span>
                              </li>
                              <li class="confidence-item">
                                <span class="icon icon-success" aria-hidden="true" style={{paddingRight:'10px'}}><FontAwesomeIcon icon={faCheck} /></span> 
                                <span class="confidence-text">Online check-in</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label className='fw-bold'>Title</label>
                          <select id='inputState' name='title' value={gestinfo.title} onChange={handlegestchange} class='form-control mt-2 form-select select-styling'>
                            <option selected>Select Title</option>
                            <option value='MR'>Mr</option>
                            <option value='MRS'>Mrs</option>
                          </select>
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                        <label className='fw-bold'>First Name</label>
                          <input
                            type='text'
                            class='form-control mt-2'
                            value={gestinfo.firstname}
                            name='firstname'
                            onChange={handlegestchange}
                            placeholder='First Name'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                        <label className='fw-bold'>Last Name</label>
                          <input
                            type='text'
                            class='form-control mt-2'
                            value={gestinfo.lastname}
                            name='lastname'
                            onChange={handlegestchange}
                            placeholder='Last Name'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                        <label className='fw-bold'>Email</label>
                          <input
                            type='email'
                            class='form-control mt-2'
                            value={gestinfo.email}
                            name='email'
                            onChange={handlegestchange}
                            placeholder='Email'
                          />
                        </div>
                        <div class='form-group field-icon-wrap mt-2 col-md-6'>
                        <label className='fw-bold'>Nationality</label>
                        <Select
                                              options={countryList}
                                              isSearchable={true}
                                              className="mt-2"
                                              onChange={handleCountryChange}
                                              value={selectedCountry}
                                              getOptionLabel={(option) => (
                                                  <div>
                                                  <img
                                                      src={option.flag}
                                                      alt={option.label}
                                                      style={{ width: '20px', marginRight: '8px' }}
                                                  />
                                                  {option.label} ({option.phoneCode})
                                                  </div>
                                              )}
                                              getOptionValue={(option) => option.value}
                                              />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                        <label className='fw-bold'>Phone Number</label>
                          <input
                            type='text'
                            class='form-control mt-2'
                            value={gestinfo.phonenumber}
                            name='phonenumber'
                            onChange={handlegestchange}
                            placeholder='Phone Number'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class='row mt-4'>
                    <div className='hotel-checkout-shadow p-3'>
                      <div className='row'>
                        <div><h4 style={{color:'black'}}>Payment Method</h4> </div>
                        <div class='section-tab check-mark-tab text-center mt-3 pb-4'>
                          <ul class='nav' id='myTab' role='tablist'>
                            <li class='nav-item'>
                                <i class='la la-check icon-element'></i>
                                <img
                                  src={payment}
                                  alt=''
                                />
                            </li>
                          </ul>
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor="cardNumber" className="form-label">Card Holder Name</label>
                          <input
                            type='text'
                            class='form-control'
                            name='name'
                          value={cardInfo.name}
                          onChange={handlecarddetail}
                            placeholder='Name'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                         <label htmlFor="cardNumber" className="form-label">Card number</label>
                          <CardNumberElement  className="form-control h-50" id="cardNumber" />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor="expiryDate" className="form-label">Expiration date</label>
                          <CardExpiryElement className="form-control h-100" id="expiryDate" />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                        <label htmlFor="cvc" className="form-label">CVC</label>
                          <CardCvcElement className="form-control h-100" id="cvc" />
                        </div>
                        {error && (
                          <div style={{color:"red"}}  className="error mt-2" role="alert">
                            {error}
                          </div>
                        )}
                      </div>
                      <div class='form-check mt-5'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          checked={isChecked}
                          onChange={handleCheckboxContinue}
                          id='invalidCheck'
                          required
                        />
                        <label class='form-check-label' for='invalidCheck'>
                          By continuing, you agree to the{' '}
                          <span style={{ color: 'red' }}> Terms and conditions</span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                  <article id="insurance" class="segment no-target insurance-module checkout-hotel-detail mt-3">
                    <div class="global-insurance-container-v2 ins-grid-c1-15" id="hotelInsuranceContainer">
                        <div class="insurance-header remove-margin">
                            <div class="align-urgency cf">
                                <div class="shield-header-with-recommend-text">
                                    <h2 class="benefits-heading">        
                                      <span class="insurance-header-title-padding">Drive with rental car protection</span>
                                      <span class="badge badge-success badge-notification  insurance-badge insurance-badge-side-title bold-700 insurance-badge-line-height"><span class="insurtech-badge-text">Recommended</span></span>
                                    </h2>
                                </div>
                            </div>
                        </div>      
                        <div id="US_HOTEL_GENERALI" data-ftl-template="US_HOTEL_GENERALI_RETRO" class="insurance-template insurance-offering ins-grid-c1-15" data-lob="hotel">
                            <div class="ins-wrapper remove-margin">
                              <div class="global-hotel template-body ins-grid-c1-15">
                                <form id="insurance-form" data-lob="hotel">
                                    <fieldset class="fieldset-margin insurance-fields ins-grid-c1-15">
                                      <div class="wa-residency-message type-200" id="WAResidencyMessage-Car" data-cko-click="changeInsuranceUsResidency" data-territory="TX" data-productinternalname="Zero-deductible Car Insurance (International)" data-product="car" data-template="US_CAR">
                                          <b>Note:</b> If you are a resident of <b>Washington</b> state, please <a href="#">click here</a> to view products that you are eligible to purchase.
                                      </div>
                                      <div class="insurance-selection-container retro">
                                          <span class="label required insReqMessage p-t-0" aria-hidden="true">Select Yes or NO to continue booking</span>
                                          <div class="one-plan-yes-highlight remove-yes-collapse">
                                              <div class="one-plan-yes-label">
                                                  <div class="one-plan-CTA">
                                                      <label class="check insurance-product margin-adjustment" for="yes_insurance" data-cko-click="addGlobalInsuranceV2" data-piid="30010" data-insurancetypeid="30010" data-label="Yes, I want to add protection to my stay.">
                                                        <input type="radio" id="yes_insurance" name="insurance_piid" value="Generali US Hotel International" class="  cko-field-validate loggedForcedChoice" aria-label="Yes, I want to add protection to my stay." 
                                                            data-custom-rules="insuranceForcedChoiceValidate" data-product-type="hotel" />
                                                        <span class="inline-label">
                                                          <span class="yes-text yes-cta-text-header" aria-hidden="true"><b>Yes</b>, I need rental car protection</span>
                                                        </span>
                                                      </label>
                                                  </div>
                                                  <div id="one-plan-price" class="insurance-price" onclick="Checkout.GlobalInsuranceV2.Metrics.viewGlobalCalendarToolTipV2(Checkout.Model.productType)">
                                                      <div class="price type-600" aria-hidden="true"> $11.00
                                                          <span>
                                                            <a href="#" data-control="tooltip" role="button" data-content="<p>Price is calculated by calendar day. Each calendar day counts as a separate day of coverage. For example, a car picked up at 9am on Monday and returned at 8am on Tuesday is 2 calendar days and counts as 2 days of coverage.</p>" data-width="300" data-arrow="true" data-fade="out" data-tooltip-id="calendar-icon" data-manual="none" data-show-tooltip="true">/calendar day</a>
                                                          </span>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div id="ins-yes-feedback-msg" class="multi-yes-feedback-msg-container hidden ins-yes-message">
                                                  <span class="multi-no-feedback-img-container"><img src="https://images.trvl-media.com/media/content/expus/images/insurance/assets/icons/insurance_shield_tick.svg"/></span>
                                                  <span class="multi-yes-feedback-msg">  You've added rental car protection to your trip.</span>
                                              </div>
                                              <div class="insurance-description">
                                                <div class="ins-col-car">
                                                    <ul class="checkMarkItem ">
                                                      <li class="li benefit-list-item">                        
                                                        <span class="icon icon-success check-bullet" style={{paddingRight:'20px'}} aria-hidden="true"><FontAwesomeIcon icon={faCheck} /></span>
                                                        <span class="descriptionLine">Covers your rental car from certain <b>theft</b>, <b>vandalism</b>, and <b>collision damage</b></span>
                                                      </li>
                                                      <li class="li benefit-list-item">                        
                                                        <span class="icon icon-success check-bullet" style={{paddingRight:'20px'}} aria-hidden="true"><FontAwesomeIcon icon={faCheck} /></span>
                                                        <span class="descriptionLine"><b>$0 deductible</b>/excess</span>
                                                      </li>
                                                      <li class="li benefit-list-item">                        
                                                         <span class="icon icon-success check-bullet" style={{paddingRight:'20px'}} aria-hidden="true"><FontAwesomeIcon icon={faCheck} /></span>
                                                        <span class="descriptionLine">Up to <b>$35,000</b> in <b>primary</b> coverage</span>
                                                      </li>
                                                      <li class="li benefit-list-item">                       
                                                        <span class="icon icon-success check-bullet" style={{paddingRight:'20px'}} aria-hidden="true"><FontAwesomeIcon icon={faCheck} /></span>
                                                        <span class="descriptionLine"><b>24/7</b> emergency <b>assistance</b></span>
                                                      </li>
                                                      <li class="li benefit-list-item">                       
                                                        <span class="icon icon-success check-bullet" style={{paddingRight:'20px'}} aria-hidden="true"><FontAwesomeIcon icon={faCheck} /></span>
                                                        <span class="descriptionLine"><b>100%</b> refund of paid plan cost if you <b>cancel insurance</b> before car pick-up time</span>
                                                      </li>
                                                    </ul>
                                                    <div class="one-plan-terms-link" data-product-type="car" onclick="Checkout.GlobalInsuranceV2.Metrics.viewGlobalInsuranceTermsV2(Checkout.Model.productType)">
                                                        <a href="https://webservices.travelguard.com/Product/FileRetrieval.aspx?CountryCode=US&amp;StateCode=NW&amp;ProductCode=009173&amp;PlanCode=P1&amp;FileType=PROD_PLAN_GM" target="_blank">View insurance details and disclosures 
                                                          <span class="icon icon-popup" aria-hidden="true"></span>
                                                          <span class="alt">(Opens in a new Window)</span>
                                                        </a>
                                                    </div>
                                                </div>
                                              </div>
                                          </div>
                                          <div class="insurance-offering decline-offering remove-no-collapse">
                                              <div class="no-highlight-slim">
                                                <label class="check decline" for="no_insurance" data-cko-click="removeGlobalInsuranceV2" data-piid="no_insurance">
                                                  <input type="radio" id="no_insurance" name="insurance_piid" value="none" class="  gb-whitelist loggedForcedChoice" data-custom-rules="insuranceForcedChoiceValidate" data-product-type="hotel" data-ins-product-internal-name="Generali US Hotel International" data-product="hotel"/>
                                                  <span class="inline-label"><span class="no-text" aria-hidden="true">No, I am willing to take the risk.</span></span>
                                                </label>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="uitk-grid customer-testimonial-container remove-margin">
                                        <div class="uitk-col type-200">
                                            <span class="showTestimonialWithQuotes-openingQuote">“</span>
                                            I was surprised how quickly the claim was resolved and with minimal paperwork. And no deductible!!!
                                            <span class="showTestimonialWithQuotes-closingQuote">”</span>
                                            <div class="cust-testimonial-footer">-Ms. K</div>
                                        </div>
                                      </div>
                                    </fieldset>
                                </form>
                               </div>
                            </div>
                        </div>
                    </div>
                  </article>
                  <article id="complete" class="segment no-target review-and-book checkout-hotel-detail">
                    <div id="fxmp" class="hidden">
                        <p class="fx-header">
                            <strong>The following currencies are available for your booking:</strong>
                        </p>
                        <fieldset class="fx-fields">
                            <label class="check">
                                <input type="radio" class="fx-poscurrency gb-whitelist" name="fx_selected" value="false" data-cko-change="trackFX"/>
                                <span class="inline-label fx-label">
                                  Pay in <span class="fx-code fx-poscurrency-code"></span> - <span class="fx-value fx-poscurrency-value"></span>            
                                </span>
                            </label>
                            <label class="check">
                                <input type="radio" class="fx-cccurrency gb-whitelist" name="fx_selected" value="true" checked="checked" data-cko-change="trackFX" />
                                <span class="inline-label fx-label">Pay in <span class="fx-code fx-cccurrency-code"></span> - <span class="fx-value fx-cccurrency-value"></span>            </span>
                            </label>
                        </fieldset>
                    </div>
                    <div id="important-information" class="important-information-rr-cancellation scroll-wrapper">
                        <h2 class="checkout-title-visual-alignment">Important Car information </h2>
                        <div class="rules-and-restrictions-payment car">
                            <div class="title-sub default secondary">
                              <ul class="rr-bullets rules-list">
                                <li>The following rules and restrictions are provided by the car rental company.</li>
                                <li>The driver must present a valid <a href="https://www.expedia.com/Checkout/RulesAndRestrictions?tripid=e037c5b4-0473-506c-a1e0-0ad8e0bd742d#DRIVERS_LICENSE" data-cko-click="openWindow" target="_blank">driver's license <span class="icon icon-popup" aria-hidden="true"></span> <span class="alt">(Opens a new window) </span></a> and credit card in their name upon pick-up. The credit card is required as a deposit when renting any vehicle. The deposit amount is held by the car rental company. Please ensure sufficient funds are available on the card.</li>
                                <li>International rentals may have different driver license requirements. An international driving license is required if the drivers' license is non-roman alphabet.</li>
                                <li><a href="https://www.expedia.com/Checkout/RulesAndRestrictions?tripid=e037c5b4-0473-506c-a1e0-0ad8e0bd742d#AGE" data-cko-click="openWindow" target="_blank">Additional charges or restrictions<span class="icon icon-popup" aria-hidden="true"></span> <span class="alt">(Opens a new window) </span></a> may apply for drivers under 25 or over 70.</li>
                                <li>Charges for <a href="https://www.expedia.com/Checkout/RulesAndRestrictions?tripid=e037c5b4-0473-506c-a1e0-0ad8e0bd742d#RETURN_REFUELING" data-cko-click="openWindow" target="_blank">refueling<span class="icon icon-popup" aria-hidden="true"></span> <span class="alt">(Opens a new window) </span></a>, additional drivers, etc. are not included in the total price.</li>
                                <li><a href="https://www.expedia.com/Checkout/RulesAndRestrictions?tripid=e037c5b4-0473-506c-a1e0-0ad8e0bd742d#SPECIAL_EQUIPMENT" data-cko-click="openWindow" target="_blank">Special equipment<span class="icon icon-popup" aria-hidden="true"></span> <span class="alt">(Opens a new window) </span></a>, such as child seats and GPS, can be purchased upon pick-up (if available).</li>
                                <li>Geographical restrictions may apply, even for rental contracts that feature unlimited mileage. Some car rental companies do not allow you to take their vehicles across certain domestic or international borders, or may apply an additional charge to do so.</li>
                                <li>Your rental may have <a href="https://www.expedia.com/Checkout/RulesAndRestrictions?tripid=e037c5b4-0473-506c-a1e0-0ad8e0bd742d#INSURANCE" data-cko-click="openWindow" target="_blank">mandatory, local insurance requirements<span class="icon icon-popup" aria-hidden="true"></span> <span class="alt">(Opens a new window) </span></a> that result in additional charges at the time of rental.</li>
                                <li>The total price includes all mandatory taxes and fees.</li>
                                <li>Rental fees are due at pick-up.</li>
                                <li>Unless and otherwise specified, rates are quoted in US dollars.</li>
                                <li>View the rental car company’s <a href="https://privacy.ehi.com/en-us/home/privacy-policy.html" data-cko-click="openWindow" target="_blank">privacy policy<span class="icon icon-popup" aria-hidden="true"></span> <span class="alt">(Opens a new window) </span></a>.</li>
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div id="booking-summary">
                        <hr class="booking-summary-separator" aria-hidden="true"/>
                    </div>
                    <div id="rules-and-restrictions" class="legal-consent page-2  consent-margin">
                        <div class="implicit-consent">
                          By clicking on the button below, I acknowledge that I have reviewed the 
                          <a href="/privacy?rfrr=HOT.CKO.Privacy" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.PRIVACY">Privacy Statement 
                          <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and  
                          <a href="https://travel.state.gov/content/passports/en/alertswarnings.html" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOT.CKO.TravelAdvice"> Government Travel Advice 
                          <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and have reviewed and accept the 
                          <a href="/Checkout/V1/HotelRulesAndRestrictions?tripid=d3ea7a5a-949a-5162-b4f0-022b5c7fe0a2&amp;rfrr=HOT.CKO.Rules&amp;np=1" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.RULES">Rules &amp; Restrictions 
                          <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a> and <a href="/terms?rfrr=HOT.CKO.Terms" target="_blank" data-cko-click="openWindow" data-cko-dctk-pageid="HOTEL.CKO.TERMS">Terms of Use 
                          <span class="icon icon-popup" aria-hidden="true"><FontAwesomeIcon icon={faWindowRestore} /></span><span class="alt">Opens in a new window.</span></a>.        
                        </div>
                    </div>
                    <div class='row mt-4'>
                    <div className=' p-3'>
                      <div className='row'>
                        <div><h4 style={{color:'black'}}>Payment Method</h4> </div>
                        <div class='section-tab check-mark-tab text-center mt-3 pb-4'>
                          <ul class='nav' id='myTab' role='tablist'>
                            <li class='nav-item'>
                                <i class='la la-check icon-element'></i>
                                <img
                                  src={payment}
                                  alt=''
                                />
                            </li>
                          </ul>
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor="cardNumber" className="form-label">Card Holder Name</label>
                          <input
                            type='text'
                            class='form-control'
                            name='name'
                          value={cardInfo.name}
                          onChange={handlecarddetail}
                            placeholder='Name'
                          />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                         <label htmlFor="cardNumber" className="form-label">Card number</label>
                          <CardNumberElement  className="form-control h-50" id="cardNumber" />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                          <label htmlFor="expiryDate" className="form-label">Expiration date</label>
                          <CardExpiryElement className="form-control h-100" id="expiryDate" />
                        </div>
                        <div class='form-group mt-2 col-md-6'>
                        <label htmlFor="cvc" className="form-label">CVC</label>
                          <CardCvcElement className="form-control h-100" id="cvc" />
                        </div>
                        {error && (
                          <div style={{color:"red"}}  className="error mt-2" role="alert">
                            {error}
                          </div>
                        )}
                      </div>
                      <div class='form-check mt-5'>
                        <input
                          class='form-check-input'
                          type='checkbox'
                          checked={isChecked}
                          onChange={handleCheckboxContinue}
                          id='invalidCheck'
                          required
                        />
                        <label class='form-check-label' for='invalidCheck'>
                          By continuing, you agree to the{' '}
                          <span style={{ color: 'red' }}> Terms and conditions</span>
                        </label>
                      </div>
                    </div>
                  </div>
                    <button type="button" onClick={ThrirdPartyTransfer} class="btn-primary btn-action complete-booking-cta booking-button">
                      <span class="btn-label">Reserve Now</span>
                    </button>
                    <div class="security-message" aria-hidden="true">
                      <span class="icon icon-lock" aria-hidden="true"><FontAwesomeIcon icon={faLock} /></span>       
                      <span class="text1"> We use secure transmission and encrypted storage to protect your personal information.</span>
                      <span class="page-2 visa-mandate-message text1">
                        Payments are processed in the U.S. except where the travel provider (hotel / airline etc) processes your payment outside the U.S., in which case your card issuer may charge a foreign transaction fee.        
                      </span>
                    </div>
                  </article>
                </div>
                <div className='col-lg-4 margin-checkout'>
                  <section id="trip-summary" class="trip-summary cf">
                    <div class="product-summary" data-cko-click="toggleProductDetails" data-cko-space-click="toggleProductDetails">
                      <span class="icon icon-carsalt product-section-icon" aria-hidden="true">
                        <FontAwesomeIcon icon={faCar} />
                      </span>
                      <div class="product-content">
                        <a role="button" href="#product-card-0" style={{fontSize:'20px'}} class="toggle-trigger product-content-title">
                           {selectedTransfer.VehicleMake} {' '} ({selectedTransfer.vehicle_Name})
                        </a>
                        <div class="summary">
                          {/* <div class="location-info">Pickup Location: {selectedTransfer.pickup_City}. Dropof Location: {selectedTransfer.dropof_City} </div> */}
                          <div class="date-info">{selectedTransfer.pickup_date}</div>
                          <div class="product-description">    
                            <span class="product-feature">
                              <span class="icon icon-traveler" aria-hidden="true"><FontAwesomeIcon icon={faUser} /></span>
                              <span class="alt">Passengers</span>  {selectedTransfer.search_passenger}
                            </span>
                            <span class="product-feature px-2" style={{}}>
                              <span class="icon icon-snowflake" aria-hidden="true"><FontAwesomeIcon icon={faSnowflake} /></span>
                              {' '}  <span class="alt">A/C</span>A/C
                            </span>
                            <span class="product-feature px-2">
                              <span class="icon icon-snowflake" aria-hidden="true"><FontAwesomeIcon icon={faDiagramProject} /></span>
                               {' '} <span class="alt" id="Transmission type">Transmission type</span>
                              Automatic
                            </span>
                            <span class="product-feature">
                              <span class="icon icon-snowflake" aria-hidden="true"><FontAwesomeIcon icon={faCar} /></span>
                               {' '} <span class="alt" id="Transmission type">Vehicle</span>
                               {selectedTransfer.no_of_vehicles} 
                            </span>
                          </div>
                          <br />
                          <div class="product-feature" style={{display:'flex', justifyContent:'space-between'}}>
                            <p class="icon icon-snowflake" aria-hidden="true">Transfer Type:</p>
                              <span class="alt" id="Transmission type">Vehicle</span>
                            <h6 style={{textAlign:'right', display:'inline-flex'}}>{selectedTransfer.transfer_type}</h6>
                          </div>
                          <div class="product-feature" style={{display:'flex', justifyContent:'space-between'}}>
                            <p class="icon icon-snowflake" aria-hidden="true">Duration:</p>
                              <span class="alt" id="Transmission type">Vehicle</span>
                            <h6 style={{textAlign:'right', display:'inline-flex'}}> {selectedTransfer.duration}</h6>
                          </div>
                          <div class="product-feature" style={{display:'flex', justifyContent:'space-between'}}>
                            <p class="icon icon-snowflake" aria-hidden="true">Pickup Location:</p>
                            <p style={{textAlign:'right', display:'inline-flex'}}>{selectedTransfer.pickup_City}</p>
                          </div>
                          <div class="product-feature" style={{display:'flex', justifyContent:'space-between'}}>
                            <p class="icon icon-snowflake" aria-hidden="true">Dropof Location:</p>
                            <p style={{textAlign:'right', display:'inline-flex'}}>{selectedTransfer.dropof_City}</p>
                          </div>
                        </div>
                        {/* <div id="product-card-0" class="details toggle-pane " role="region">
                            <div class="toggle-inner">
                                <div class="vendor-details">Europcar</div>
                                <div class="fuel-info-details">
                                    <div class="fuel_info_title">Fuel info</div>
                                    <div class="fuel_info_msg">Full to Full</div>
                                </div>
                                <div class="pick-up-details">
                                    <div class="pick-up-title">Pick-up</div>
                                    <div class="pick-up-date-time">Fri, Mar 29 10:30am</div>
                                    <div class="pick-up-location">London, United Kingdom  (LHR Airport)
                                        <span class="in-terminal">
                                          <a href="#tooltip1" class="nested-trigger" data-control="tooltip" role="button" data-width="300" data-arrow="true" data-fade="out" data-tooltip-id="myTooltipID" data-manual="none" data-show-tooltip="true" data-tooltip-classes="example" data-track="CAR.CKO.SHUTTLE.TOOLTIP">Counter in terminal, shuttle to car</a>
                                          <div id="tooltip1" class="hidden"><p>Rental car counter in the terminal, free shuttle to the car located in the airport</p></div>
                                        </span>
                                    </div>
                                </div>
                                <div class="drop-off-details">
                                    <div class="drop-off-title">Drop-off</div>
                                    <div class="drop-off-date-time">Sat, Mar 30 10:30am</div>
                                    <div class="drop-off-location">Same as pick-up</div>
                                </div>
                            </div>
                        </div> */}
                      </div>
                    </div>
                    <div class="multi-item-price-breakdown-summary">
                      <div class="price-breakdown">
                        <div class="total-breakdown-summary ">
                          <div id="no_surprises" class="nosurprises">
                              <div class="nosurprises-wrapper">
                                  <div>
                                      <span class="nosurprises-header">No surprises!</span>
                                      <span class="noSurprises-message">Here’s a breakdown of your price:</span>
                                  </div>
                              </div>
                          </div>
                          <dl class="cf zero-due  due-today-zero">
                              <dt class="due_today">Due today</dt>
                              <dd data-price-update="zeroDueToday">$0.00</dd>
                          </dl>
                          <div class="fee-details due-at-car-rental car-rental-counter-border with-total-price-breakdown">
                              <a role="button" href="#car-fee-summary" aria-controls="car-fee-summary" data-control="toggle" data-transition="fade" data-toggle-text="Due at car rental counter<span class=&quot;icon icon-expand&quot; aria-hidden=&quot;true&quot;></span>" class="toggle-trigger " data-isdisplay-icon="" aria-expanded="false" data-expand-text="Due at car rental counter<span class=&quot;icon icon-expand&quot; aria-hidden=&quot;true&quot;></span>" data-track="CAR.CKO.PaymentDueToday.COLLAPSE">
                                Due at car rental counter
                              </a>
                              <span class="subtotal-due-amount oms-price" data-price-update="dueAtPickupPrice">
                                {CurrencyRates===undefined ? selectedTransfer.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(selectedTransfer.total_fare_markup))}
                              </span>
                              <div id="car-fee-summary" class="toggle-pane " role="region">
                                  <div class="toggle-inner">
                                      <dl class="cf">
                                          <dt class="description">Car rental</dt>
                                          <dd class="amount" data-price-update="carTaxOrFeeItem0"> {CurrencyRates===undefined ? selectedTransfer.sale_currency:CurrencyRates.selectedcurrency} {renderPrice(Number(selectedTransfer.total_fare_markup))}</dd>
                                          <dt class="description">Rft - registration fee/ road tax</dt>
                                          <dd class="amount" data-price-update="carTaxOrFeeItem1">$0</dd>
                                          <dt class="description">Tax - other tax</dt>
                                          <dd class="amount" data-price-update="carTaxOrFeeItem2">$0</dd>
                                      </dl>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="total-summary">
                          <div class="total-due-today">
                            <span class="total-desc">Total</span>
                            <div id="hasNoPointsAndDueAtPickUpPrice" class="prod-total oms-price" data-price-update="finalTripTotal">  {StripeCurrency=(CurrencyRates===undefined ? selectedTransfer.sale_currency:CurrencyRates.selectedcurrency)} {StripePayment=renderPrice(Number(selectedTransfer.total_fare_markup))}</div>
                          </div>
                        </div>
                        <div class="currency-details">
                          <div class="currency-disclaimer">
                            Rates are quoted in <strong>US dollars</strong>. 
                            You'll pay <span data-price-update="totalPriceInPOSuNoSymbol">  {StripeCurrency=(CurrencyRates===undefined ? selectedTransfer.sale_currency:CurrencyRates.selectedcurrency)} {StripePayment=renderPrice(Number(selectedTransfer.total_fare_markup))}</span> at the car rental counter.            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="coalition-rewards-section"> </div>
                  </section>
                  <div className='checkout-hotel-detail p-3 tour_booking_amount_area'>
                    <div className='Hotel-img'>
                      <div class='card-img mt-2 pb-2'>
                        <a href='hotel-single.html' class='d-block'>
                          <img
                              className='tour-img'
                              src={selectedTransfer.vehicle_image }
                              alt=''
                            />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </WithoutLayout>
        </>
    )
}

export default TransferCheckout;