import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import HotelDetail from '../../Pages/Hotels/HotelDetail'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStar,
  faGlobe,
  faCheck,
  faArrowRight,
  faInfo,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import Axios from 'axios'
import moment from 'moment'
import iLoading from '../../Images/Loading/Loader.gif'
import Loading from '../Loading/Loader'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken,
  CurrencyConverter
} from '../GlobalData/GlobalData'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { fetchHotelDetail } from '../../Redux/Actions/actions'
function HotelCard ({ hotelid }) {
  const dispatch = useDispatch()
  var endpoint = ApiEndPoint()
  var apitoken = Hotelapitoken()
  const [searchData, setSearchData] = useState({
    checkin: '',
    checkout: '',
    adult: 0,
    child: 0
  })
  var filteredHotels = []
  const navigate = useNavigate();
  const hotels = useSelector(state => state.hotels.hotels);
  const ReduxSearchData = useSelector(state => state.hotels.hsearch);
  const CurrencyRates = useSelector(state => state.hotels.Currency);
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency);
  const [Loadingpage, setLoadingpage] = useState(false);
  const [visibleHotels, setVisibleHotels] = useState(10);
  const [hotelDetails, setHotelDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [baseCName, setBaseCName] = useState('GBP');
  const [baseCurrency, setBaseCurrency] = useState([]);
  const [hotelsListing, setHotelsListing] = useState( hotels.hotels_list);
  const [showPrice, setShowPrice] = useState(true);
  
  useEffect(() => {
  }, [ReduxSearchData])

  const [currentPage, setCurrentPage] = useState(1);
  const TransferPerPage = 15; // Number of hotels to display per page
  const indexOfLastTransfer = currentPage * TransferPerPage;
  const indexOfFirstTransfer = indexOfLastTransfer - TransferPerPage;
  const currentHotels =hotelsListing.slice(indexOfFirstTransfer, indexOfLastTransfer);
  const pagesCount = Math.ceil(hotelsListing.length / TransferPerPage);
  const validCurrentPage = currentPage < 1 ? 1 : currentPage;
  const maxPagesToShow =8;

  const startHotelIndex = indexOfFirstTransfer + 1;
  const endHotelIndex = Math.min(indexOfLastTransfer, hotelsListing.length);
  const paginate = (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const items = [];
    let startPage = currentPage - Math.floor(maxPagesToShow / 2);
    startPage = Math.max(startPage, 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, pagesCount);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => paginate(i)} href="#">
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };
  useEffect(() => {
    // Adjust the current page if it exceeds the total number of pages
    if (currentPage < 1) {
      setCurrentPage(1);
    } else if (currentPage > pagesCount) {
      setCurrentPage(pagesCount);
    }
  }, [ currentPage, pagesCount]); 

  useEffect(() => {
    setCurrentPage(1);
  }, [ hotelsListing]); 

  useEffect(() => {
    if (hotelid !== '') {
      setLoadingpage(true)
      if (typeof hotelid === 'number' || typeof hotelid === 'string') {
        filteredHotels = hotels.hotels_list.filter(
          x => Number(x.hotel_id) === Number(hotelid)
        )
        setHotelsListing(filteredHotels)
        setLoadingpage(false)
      } else if (hotelid.type === 'Stars') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const hotelRating = hotel.stars_rating
          if (hotelRating !== '') {
            return Object.keys(hotelid).some(
              ratingKey => Number(hotelRating) === Number(hotelid[ratingKey])
            )
          }
        })
        setHotelsListing(filteredHotels)
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      } else if (hotelid.type === 'meal') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          // Check if any room in the hotel's rooms_options array has the specified boardtype
          return hotel.rooms_options.some(room => {
            for (const mealKey in hotelid) {
              if (room.board_id === hotelid[mealKey]) {
                return true // Found a match, include the hotel
              }
            }
            return false // No match found for any meal key
          })
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      } else if (hotelid.type === 'facility') {
        const filteredHotels = []
        currentHotels.forEach(hotel => {
          Facilityfilter(hotel.hotel_id, hotel.hotel_provider, metaData => {
            // Check if the hotel has the desired facilities (e.g., wifi and safe).
            for (const mealKey in hotelid) {
              if (metaData.facilities.includes(hotelid[mealKey])) {
                filteredHotels.push(hotel)
              }
            }
          })
        })
        setLoadingpage(false)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setHotelsListing(filteredHotels)
      } else if (hotelid.type === 'price') {
        const filteredHotels = hotels.hotels_list.filter(hotel => {
          const price = hotel.min_price
          return price >= hotelid.min && price <= hotelid.max
        })
        setHotelsListing(filteredHotels)
        setHotelDetails({})
        // filteredHotels.forEach(hotel => {
        //   fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        // })
        setLoadingpage(false)
      }
    }
  }, [hotelid])

  const Facilityfilter = async (hotelId, provider, callback) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }
    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      callback(response.data.details_data)
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };

  useEffect(() => {
    setSearchData(prevdata => ({
      ...prevdata,
      adult: ReduxSearchData.adult,
      child: ReduxSearchData.child,
      checkin: moment(ReduxSearchData.check_in).format('Do MMM '),
      checkout: moment(ReduxSearchData.check_out).format('Do MMM ')
    }))
  }, [])

  useEffect(() => {
    var curency = hotels.hotels_list[0]?.hotel_curreny;
    AllCurrency(curency);
  }, []);
  const RoomDetailPage = async (id, indexNO) => {
    const hotelRoomdetail = hotels.hotels_list.filter(
      item => item.hotel_id == id
    )
    const index = hotels.hotels_list.findIndex(item => item.hotel_id === id);

    setLoadingpage(true);
    const data = {
      token: apitoken,
      hotel_search_data: JSON.stringify(hotelRoomdetail[0]),
      hotel_code: String(id),
      lat:ReduxSearchData.lat,
      long:ReduxSearchData.long
    };
    try {
      
      // _Live
      const response = await Axios.post(
        endpoint + '/api/hotels/details_Live',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      // Handle the API response here
      dispatch(fetchHotelDetail(response.data.hotel_details))
      navigate(`/hotel_detail/${id}`, { state: { index } })
      setLoadingpage(false)
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };

  const fetchHotelDetails = async (hotelId, provider) => {
    const data = {
      provider: provider,
      hotel_code: hotelId,
      token: apitoken
    }

    try {
      const response = await Axios.post(endpoint + '/api/hotels/mata_Live', data, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      setHotelDetails(prevDetails => ({
        ...prevDetails,
        [hotelId]: response.data
      }))
      // Handle the API response here
    } catch (error) {
      // Handle errors here
      console.error('Error:', error)
    }
  };
  
  
  useEffect(() => {
    currentHotels.forEach(hotel => {
        if (!hotelDetails[hotel.hotel_id]) {
          fetchHotelDetails(hotel.hotel_id, hotel.hotel_provider)
        }
      })
  }, [currentPage,hotelsListing])


  const renderPrice = price => {
    if (CurrencyRates === undefined) {
      const gbpprice = baseCurrency[baseCName] // Use square brackets to access the property
      var baseprice = (Number(gbpprice) * Number(price))
    } else {
      var select123 = CurrencyRates.selectedcurrency
      const gbpprice = baseCurrency[baseCName]
      var baseprice1 = (Number(gbpprice) * Number(price))
      const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
      var baseprice = (Number(gbpprice2) * Number(baseprice1))
    }
    return baseprice.toFixed(0)
  };

  const AllCurrency = curency => {
    var token = CurrencyConverter()
    const config = {
      method: 'get',
      url: 'https://v6.exchangerate-api.com/v6/' + token + '/latest/' + curency, // Replace with your API URL
      maxBodyLength: Infinity,
      headers: {}
    }
    Axios.request(config)
      .then(response => {
        // Handle the response data
        if(response.data.result!=='error'){
          setBaseCurrency(response.data.conversion_rates)
          localStorage.setItem('HotelCurrency', JSON.stringify(response.data))
        }
      })
      .catch(error => {
        // Handle errors here
        if(localStorage.getItem('HotelCurrency') !== null) {
          localStorage.removeItem('HotelCurrency');
          }
        setShowPrice(false)
        console.error(error)
      })
  };
  const calculateMarkup = (
    price,
    adminmarkup,
    admintype,
    clientmarkup,
    clienttype
  ) => {
    let markupprice = 0
    let adminmarkupprice = 0
    let clientmarkupprice = 0
    let finalpricemarkup = Number(price)
    if (Number(adminmarkup) !== 0) {
      if (admintype === 'Percentage') {
        markupprice = (price * Number(adminmarkup)) / 100
      } else {
        markupprice = Number(adminmarkup)
      }
      adminmarkupprice = markupprice
      finalpricemarkup += markupprice
      // savemarkup.admin=adminmarkupprice;
      // savemarkup.final=finalpricemarkup;
    }
    if (Number(clientmarkup) !== 0) {
      if (clienttype === 'Percentage') {
        markupprice = (finalpricemarkup * Number(clientmarkup)) / 100
      } else {
        markupprice = Number(clientmarkup)
      }
      clientmarkupprice = markupprice
      finalpricemarkup += markupprice
    }
    return finalpricemarkup
  };
  return (
    <>
      {Loadingpage && <Loading />}
      <div>
        <div className='filter-page__content'>
          <div className='fw-bold mb-2'>Showing {startHotelIndex} to {endHotelIndex} of {hotelsListing.length} hotels</div>
            <div className='filter-item-wrapper' id='tours_filter'>
              {currentHotels.map((item, index) => (
                <div key={item.hotel_id} className='row px-3'>
                  {/* <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                    <div data-meso-viewed="true">
                      <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme" data-stid="lodging-card-responsive">
                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-display-grid" >
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className='col-md-12 mb-4 uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme'>
                    <div className='row' onClick={() => RoomDetailPage(item.hotel_id, index)} style={{cursor:'pointer'}}>
                      <div className='col-md-4 item-from px-0'>
                        <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-left-zero uitk-layout-position-relative">
                          <figure class="uitk-image uitk-layout-position uitk-layout-position-zindex-layer2 uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                            <span>
                              <span>
                                    {/* <p className="text-center show-provider">{item.hotel_provider}</p> */}
                                <div class="uitk-gallery-carousel">
                                  <h3 class="is-visually-hidden">Photo gallery for Swissôtel Al Maqam Makkah</h3>
                                  <div class="uitk-gallery-carousel-items ratio-21-9">
                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                      <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                        <div class="uitk-image-placeholder">
                                          {hotelDetails[item.hotel_id] &&
                                            hotelDetails[item.hotel_id].details_data &&
                                            hotelDetails[item.hotel_id].details_data.image ? (
                                              <div>
                                                <img
                                                  class="uitk-image-media"
                                                  src={
                                                    hotelDetails[item.hotel_id].details_data.image
                                                  }
                                                  alt=''
                                                />
                                              </div>
                                            ) : (
                                              // <ShimmerThumbnail height={250} />
                                            <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                              <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-21-9 uitk-image-ratio">
                                                <div class="uitk-image-placeholder">
                                                  <img alt="Room" class="uitk-image-media" src="https://images.trvl-media.com/lodging/17000000/16250000/16249700/16249642/398b696c.jpg?impolicy=resizecrop&amp;rw=455&amp;ra=fit" />
                                                </div>
                                                <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                  <span class="is-visually-hidden">Room</span>
                                                </button>
                                              </figure>
                                            </div>
                                            )}
                                        </div>
                                      </figure>
                                    </div>
                                    <div class="uitk-gallery-carousel-paging-controls">
                                      <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-prev uitk-button-paging uitk-button-paging-overlay">
                                        <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <title id="prev-button-lodging-card-gallery-carousel-16249642-1-title">Show previous image for Swissôtel Al Maqam Makkah</title>
                                          <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                        </svg>
                                      </button>
                                      <button theme="transparent-btn" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-gallery-carousel-button-next uitk-button-paging uitk-button-paging-overlay">
                                        <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next image for Swissôtel Al Maqam Makkah" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <title id="next-button-lodging-card-gallery-carousel-16249642-1-title">Show next image for Swissôtel Al Maqam Makkah</title>
                                          <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </span>
                          </figure>
                          <div class="uitk-layout-position uitk-layout-position-top-zero uitk-layout-position-right-zero uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                            <div data-stid="outlined-save-button">
                              <div class="favorite-button-wrapper">
                                <button type="button" aria-label="Save Swissôtel Al Maqam Makkah to a trip" class="favorite-button favorite-button-button-no-label">
                                  <svg class="uitk-icon favorite-button-fill favorite-button-fill-default" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="m12 22-8.44-8.69a5.55 5.55 0 0 1 0-7.72C4.53 4.59 5.9 4 7.28 4c1.4 0 2.75.59 3.72 1.59l1 1.02 1-1.02c.97-1 2.32-1.59 3.72-1.59 1.4 0 2.75.59 3.72 1.59a5.55 5.55 0 0 1 0 7.72L12 22Z" clip-rule="evenodd"></path>
                                  </svg>
                                  <svg class="uitk-icon favorite-button-border favorite-button-border-default favorite-button-border-color-highlighed" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="m12 22 8.44-8.69a5.55 5.55 0 0 0 0-7.72A5.24 5.24 0 0 0 16.72 4 5.22 5.22 0 0 0 13 5.59L12 6.6l-1-1.02a5.2 5.2 0 0 0-7.44 0 5.55 5.55 0 0 0 0 7.72L12 22Zm0-2.87-7-7.21a3.55 3.55 0 0 1 0-4.94C5.6 6.36 6.44 6 7.28 6c.84 0 1.69.36 2.3.98L12 9.48l2.43-2.5c.6-.62 1.45-.98 2.29-.98.84 0 1.68.36 2.28.98a3.55 3.55 0 0 1 0 4.94l-7 7.2Z" clip-rule="evenodd" opacity=".9"></path>
                                  </svg>
                                </button>
                              </div>
                            </div> 
                          </div>
                          {/* <span class="uitk-badge uitk-badge-base-small uitk-badge-base-has-text uitk-badge-standard uitk-layout-position uitk-layout-position-bottom-three uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute">
                            <span class="uitk-badge-base-text" aria-hidden="false">Ad</span>
                          </span> */}
                        </div>
                      </div>
                      <div className='col-md-8'>
                        <div class="uitk-card-content-section uitk-card-content-section-padded uitk-layout-grid-item uitk-layout-grid-item-has-column-start-by-medium">
                          <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between">
                            <div class="uitk-spacing uitk-spacing-padding-blockend-three uitk-layout-flex-item">
                              <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-rows uitk-layout-grid-display-grid uitk-layout-flex-item">
                                  <h3 class="uitk-heading uitk-heading-5 overflow-wrap uitk-layout-grid-item uitk-layout-grid-item-has-row-start" onClick={() => RoomDetailPage(item.hotel_id, index)}> {item.hotel_name}</h3>
                                </div>
                              </div>
                              <div class="uitk-text uitk-text-spacing-half truncate-lines-2 uitk-type-300 uitk-text-default-theme" aria-hidden="false">
                                {/* <div class='item-address'>
                                  <i class='awe-icon awe-icon-marker-2'>
                                    <FontAwesomeIcon icon={faCheck} />
                                  </i>{' '}
                                  {item.rooms_options.length} Rooms Available{' '}
                                </div> */}
                                  <div class="uitk-text uitk-type-200 uitk-type-bold uitk-text-default-theme py-2">  
                                    {hotelDetails[item.hotel_id] && (
                                      <div>
                                        <i class='awe-icon awe-icon-marker-2'>
                                          <FontAwesomeIcon icon={faGlobe} />
                                        </i>{' '}
                                        {hotelDetails[item.hotel_id].details_data.address}{' '}
                                      </div>
                                      )}
                                  </div>
                              </div>
                              <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-basis-16x">
                                  <div class="uitk-text uitk-type-200 uitk-text-default-theme">
                                    {hotelDetails[item.hotel_id] ? (
                                      <div class='row'>
                                        {hotelDetails[
                                          item.hotel_id
                                        ].details_data.facilities.map((facility, index) => (
                                          <div
                                            key={index}
                                            className=' col-sm-4 col-4 col-md-4 col-lg-4 mt-1'
                                          >
                                            <div class='single-tour-feature d-flex align-items-center mb-3'>
                                              <div class='single-feature-icon icon-element ml-0 flex-shrink-0 mr-3'>
                                                <i class='fas fa-check'>
                                                  <FontAwesomeIcon icon={faCheck} />
                                                </i>
                                              </div>
                                              <div class='single-feature-titles'>
                                                <p class='title fw-bold'>{facility}</p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                        ):(
                                        <div className='placeholder-glow'>
                                          <span className='placeholder col-12'></span>
                                          <span className='placeholder col-12'></span>
                                          <span className='placeholder col-6'></span>

                                        </div>
                                      )}
                                  </div>
                                </div>
                            </div>
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-flex-item rating-flex">
                              <div class="uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-gap-three uitk-spacing uitk-spacing-margin-blockstart-">
                               
                                  <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-gap-one">
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center">
                                      <span class="uitk-badge uitk-badge-base-large uitk-badge-base-has-text uitk-badge-positive uitk-badge-standard">
                                        <span class="uitk-badge-base-text" aria-hidden="true">8.6</span>
                                        <span class="is-visually-hidden">8.6 out of 10</span>
                                      </span>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item">
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-300 uitk-type-medium uitk-text-emphasis-theme" aria-hidden="true">Excellent</div>
                                          <span class="is-visually-hidden">  
                                            <p className='card-star'>
                                              {item.stars_rating === '' ? (
                                                <span className='fw-bold'>No Rating</span>
                                              ) : (
                                                Array(item.stars_rating)
                                                  .fill(0)
                                                  .map((_, index) => (
                                                    <i key={index} className='fa fa-star'>
                                                      <FontAwesomeIcon icon={faStar} />
                                                    </i>
                                                  ))
                                              )}
                                            </p>
                                          </span>
                                        </span>
                                      </div>
                                      <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-wrap-wrap">
                                        <span class="uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                          <div class="uitk-text truncate-lines-2 uitk-type-200 uitk-type-regular uitk-text-default-theme" aria-hidden="true">555 reviews</div>
                                          <span class="is-visually-hidden">(555 reviews)</span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-grid-item uitk-layout-grid-item-align-self-end uitk-layout-grid-item-has-column-start uitk-layout-grid-item-justify-self-end">
                                <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                  <div>
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                      <div class="uitk-spacing uitk-spacing-padding-block-half">
                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">The price is {showPrice ? (
                                          <h6>
                                            <super>
                                              {CurrencyRates === undefined
                                                ? baseCName
                                                : CurrencyRates.selectedcurrency}{' '}
                                              {renderPrice(
                                                calculateMarkup(
                                                  item.min_price,
                                                  item.admin_markup,
                                                  item.admin_markup_type,
                                                  item.customer_markup,
                                                  item.customer_markup_type
                                                )
                                              )}
                                            </super>
                                            {/* <sub>PP</sub> */}
                                          </h6>
                                          ) : (
                                            <h6>
                                              <super>
                                                {item?.hotel_curreny}{' '}
                                                {calculateMarkup(
                                                  item.min_price,
                                                  item.admin_markup,
                                                  item.admin_markup_type,
                                                  item.customer_markup,
                                                  item.customer_markup_type
                                                ).toFixed(2)}
                                              </super>
                                              {/* <sub>PP</sub> */}
                                            </h6>
                                          )}
                                        </div>
                                        <span aria-hidden="true">
                                          <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">{showPrice ? (
                                            <p>
                                              <super>
                                                {CurrencyRates === undefined
                                                  ? baseCName
                                                  : CurrencyRates.selectedcurrency}{' '}
                                                {renderPrice(
                                                  calculateMarkup(
                                                    item.min_price,
                                                    item.admin_markup,
                                                    item.admin_markup_type,
                                                    item.customer_markup,
                                                    item.customer_markup_type
                                                  )
                                                )}
                                              </super>
                                              {/* <sub>PP</sub> */}
                                            </p>
                                            ) : (
                                              <p>
                                                <super>
                                                  {item?.hotel_curreny}{' '}
                                                  {calculateMarkup(
                                                    item.min_price,
                                                    item.admin_markup,
                                                    item.admin_markup_type,
                                                    item.customer_markup,
                                                    item.customer_markup_type
                                                  ).toFixed(2)}
                                                </super>
                                                {/* <sub>PP</sub> */}
                                              </p>
                                            )}
                                          </div>
                                        </span>
                                      </div>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                      <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme"> 
                                        {showPrice ? (
                                          <h6>
                                            <super>
                                              {CurrencyRates === undefined
                                                ? baseCName
                                                : CurrencyRates.selectedcurrency}{' '}
                                              {renderPrice(
                                                calculateMarkup(
                                                  item.min_price,
                                                  item.admin_markup,
                                                  item.admin_markup_type,
                                                  item.customer_markup,
                                                  item.customer_markup_type
                                                )
                                              )}
                                            </super>
                                            {/* <sub>PP</sub> */}
                                          </h6>
                                          ) : (
                                            <h6>
                                              <super>
                                                {item?.hotel_curreny}{' '}
                                                {calculateMarkup(
                                                  item.min_price,
                                                  item.admin_markup,
                                                  item.admin_markup_type,
                                                  item.customer_markup,
                                                  item.customer_markup_type
                                                ).toFixed(2)}
                                              </super>
                                              {/* <sub>PP</sub> */}
                                            </h6>
                                          )}
                                      </div>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                      <div class="uitk-text uitk-type-end uitk-type-200 uitk-text-default-theme">includes taxes &amp; fees</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        {/* <h6 className='departure-date mb-0'>
                          {searchData.checkin}{' '}
                          <FontAwesomeIcon icon={faArrowRight} />{' '}
                          {searchData.checkout}
                        </h6> */}
                        {/* <button
                          className='btn btn-primary select-styling search-btn1 form-control'
                          onClick={() => RoomDetailPage(item.hotel_id, index)}
                        >
                          Book Now
                        </button> */}
                      <a rel="noopener" data-stid="open-hotel-information" target="_blank" onClick={() => RoomDetailPage(item.hotel_id, index)} class="uitk-card-link">
                        <span class="is-visually-hidden">Opens Swissôtel Al Maqam Makkah in new tab</span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          <div className='row pagination_Div'>
            <div className='col-md-6 col-sm-12  col-12'>
               <div className='fw-bold  m-2'>Showing {startHotelIndex} to {endHotelIndex} of {hotelsListing.length} hotels</div>                   
            </div>
            <div className='col-md-6 col-sm-12 col-12'>
               <Pagination aria-label="Page navigation example ">
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink previous onClick={() => paginate(validCurrentPage  - 1)} />
                  </PaginationItem>
                  {renderPaginationItems()}
                  <PaginationItem disabled={currentPage === pagesCount}>
                    <PaginationLink next onClick={() => paginate(validCurrentPage  + 1)} />
                  </PaginationItem>
                </Pagination>            
            </div>
          </div>  
          </div>
      </div>
    </>
  )
}

export default HotelCard
