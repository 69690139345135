import React, { useState, useEffect } from 'react'
import img1 from '../../Images/Packages/umrah-package.jpg'
import img2 from '../../Images/Packages/AlAqsa-package.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeadset,
  faPersonWalkingLuggage,
  faPlaneArrival,
  faPlaneDeparture,
  faSackDollar,
  faUmbrellaBeach,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import CIcon from '@coreui/icons-react'
import { cilMoon } from '@coreui/icons'
import { useTransition } from 'react'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { ViewTourDetail } from '../../Redux/Actions/actions'
import { useDispatch } from 'react-redux'
// import {
//   Hotelapitoken,
//   CustomerDomainName,
//   ApiEndPoint
// } from '../GlobalData/GlobalData'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import Axios from 'axios'
import { useTranslation } from 'react-i18next'
function IndexComponents () {
  const {t,i18n}=useTranslation();
  const language=i18n.language;
  const [isloading, setLoading] = useState(false)
  const [packagescard, setPackagescard] = useState([])
  const [allcateogries, setAllCateogries] = useState([])
  const [allTours, setAllTours] = useState([])
  const [showTours, setShowTours] = useState([])
  const [itemsToShow, setItemsToShow] = useState(4)
  const Dispatch = useDispatch()
  const navigation = useNavigate()
  const [activeItem, setActiveItem] = useState(0) // Initialize activeItem with the default value (0).
  
  const handleCategorySelect = index => {
    setActiveItem(index)
    var filter = allTours.filter(
      tour => Number(tour.categories) === allcateogries[index].id
    )
    
    setShowTours(filter.sort((a, b) => new Date(a.start_date ) - new Date(b.start_date )))
    // Set the selected category as the active one.
  }
 
  useEffect(() => {
    // Add an event listener to track window width changes
    function handleResize () {
      if (window.innerWidth > 1000) {
        setItemsToShow(4) // For smaller screens, show 1 item
      } else if (window.innerWidth > 768 && window.innerWidth < 1000) {
        setItemsToShow(3) // For smaller screens, show 1 item
      } else if (window.innerWidth > 530 && window.innerWidth < 768) {
        setItemsToShow(2) // For smaller screens, show 1 item
      } else if (window.innerWidth < 530) {
        setItemsToShow(1) // For larger screens, show 4 items (you can adjust this)
      }
    }

    // Initialize the number of items based on the current window width
    handleResize()

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);


  return (
    <>
      <div className='container-fluid mt-4'>
        <div class="uitk-spacing bex-homepage-module SimpleContainer">
            <div data-testid="one-key-banner-1" id="one-key-banner-1">
                <div>
                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-padded uitk-card-has-global-loyalty-standard-theme" data-stid="one-key-message-card">
                        <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-block-three">
                            <div class="uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item">
                                <div class="uitk-spacing uitk-spacing-margin-small-inlineend-three uitk-spacing-margin-medium-inlineend-six">
                                    <img class="uitk-mark uitk-mark-landscape-oriented" alt="" src="https://a.travel-assets.com/egds/marks/onekey__standard__always_light.svg" id="onekey__standard__always_light" />
                                </div>
                            </div>
                            <div class="uitk-card-content-section uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two uitk-layout-flex-flex-wrap-wrap">
                                    <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-item uitk-layout-flex-item-flex-basis-88x uitk-layout-flex-item-flex-grow-1">
                                        <div class="uitk-spacing uitk-spacing-padding-inlineend-two">
                                            <h2 class="uitk-heading uitk-heading-6 uitk-layout-flex-item uitk-text-inverse-theme">Save 10% or more on over 100,000 hotels with Member Prices. Also, members save up to 30% when you add a hotel to a flight</h2>
                                        </div>
                                    </div>
                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-justify-content-flex-end uitk-layout-flex-gap-four uitk-layout-flex-item">
                                        <div class="uitk-layout-flex uitk-spacing uitk-layout-flex-item">
                                           <a  target="_self" data-stid="one-key-message-card-UIPrimaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-unset">Sign in</a>
                                           <a  target="_self" data-stid="one-key-message-card-UITertiaryButton" class="uitk-button uitk-button-small uitk-button-has-text uitk-button-as-link uitk-button-tertiary uitk-layout-flex-item uitk-spacing uitk-spacing-margin-inlinestart-two uitk-button-tertiary-inverse">Learn about One Key</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="uitk-spacing whiteBackground bex-homepage-module SimpleContainer">
            <div class="uitk-spacing SimpleContainer">
                <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-four uitk-spacing-padding-inline-three"></div>
            </div>
            <div class="uitk-spacing SimpleContainer">
                <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three">
                    <div>
                        <div class="uitk-layout-position uitk-layout-position-relative uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                <figure class="uitk-image uitk-image-ratio-21-9 uitk-image-ratio">
                                <div class="uitk-image-placeholder uitk-image-placeholder-image">
                                    <img alt="" class="uitk-image-media" src="https://forever.travel-assets.com/flex/flexmanager/mediaasset/705848-0_2-BEX-US-SpringLTO-HP-Hero-D-928x398.avif?impolicy=fcrop&amp;w=1400&amp;h=600&amp;q=mediumHigh" loading="lazy" />
                                </div>
                            </figure>
                            <div class="uitk-scrim overlay">
                                <div class="uitk-layout-position uitk-layout-position-top-center uitk-layout-position-absolute uitk-spacing uitk-spacing-padding-inline-twelve"> 
                                    <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-center">
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid">
                                            <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                                <h2 class="uitk-heading uitk-heading-2 in-overlay-scrim">Big spring savings: Save 25% or more</h2>
                                            </div>
                                        </div>
                                        <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-blockstart-three">
                                            <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                                <span class="uitk-text uitk-type-400 uitk-text-white-space-pre-line in-overlay-scrim">Take a trip over the school holidays. Enjoy 25% off with One Key Member Prices on select hotels. Book by March 25.</span>
                                            </div>
                                        </div>
                                        <a  class="uitk-link uitk-spacing uitk-spacing-padding-block-three uitk-layout-flex-item uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">
                                            <button type="button" class="uitk-button uitk-button-large uitk-button-has-text uitk-button-primary uitk-spacing uitk-spacing-padding-block-two">Unlock spring deals</button>
                                        </a>
                                    </div> 
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three">
                <h3 class="uitk-heading uitk-heading-4 dn">Discover your new favorite stay</h3>
                <div class="uitk-layout-flex uitk-layout-flex-gap-six uitk-spacing uitk-spacing-padding-blockstart-six">
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for family friendly Properties">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="Family friendly icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 22v-1c0-2-1.59-3-4-3-2.5 0-4 1-4 3v1H2v-8.98C2 11.07 5.67 10 7.5 10c1.23 0 3.28.48 4.5 1.39A8.67 8.67 0 0 1 16.5 10c1.83 0 5.5 1.07 5.5 3.02V22h-6zM7 8C5.37 8 4 6.63 4 5s1.37-3 3-3 3 1.37 3 3-1.37 3-3 3zm10 0c-1.63 0-3-1.37-3-3s1.37-3 3-3 3 1.37 3 3-1.37 3-3 3zm-5 9c1.09 0 2-.91 2-2 0-1.09-.91-2-2-2-1.09 0-2 .91-2 2 0 1.09.91 2 2 2z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Family friendly</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for resorts">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="Resort icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"    >
                                            <path d="M16.5 2.05a3.9 3.9 0 0 0-4.5 1.5 3.9 3.9 0 0 0-4.5-1.5l2.48 2.47A4.46 4.46 0 0 0 5.5 7h4.05a3.9 3.9 0 0 0-1.5 4.5l3.14-3.15-.5 5.65H7a4 4 0 0 0-4 4h1c1.6 0 3.02-.88 4-2 .98 1.12 2.4 2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h1a4 4 0 0 0-4-4h-3.68l-.52-5.65 3.15 3.15a3.9 3.9 0 0 0-1.5-4.5h4.05a4.46 4.46 0 0 0-4.48-2.48l2.48-2.47zM16 18.68A7.03 7.03 0 0 0 20 20h1v2h-1a8.7 8.7 0 0 1-4-.99 8.75 8.75 0 0 1-8 0A8.83 8.83 0 0 1 4 22H3v-2h1c1.39 0 2.78-.47 4-1.32a6.88 6.88 0 0 0 8 0z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Resort</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for all-inclusive properties">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="all inclusive icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.6 6.62c-1.44 0-2.8.56-3.77 1.53L10.49 12 7.8 14.39a3.38 3.38 0 1 1 .04-4.74l1.13 1 1.51-1.34L9.22 8.2A5.4 5.4 0 0 0 0 12a5.4 5.4 0 0 0 9.17 3.85l2.83-2.5 4.2-3.74a3.38 3.38 0 1 1-.04 4.74l-1.14-1.01-1.51 1.34 1.27 1.12A5.4 5.4 0 0 0 24 11.99a5.38 5.38 0 0 0-5.4-5.37z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">All inclusive</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for properties with hot tubs">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="hot tub icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M18.65 5.86c-.6-.6-.9-1.44-.74-2.27L18 3h-1.89l-.06.43a4.27 4.27 0 0 0 1.37 3.78c.57.62.82 1.41.67 2.2l-.11.59h1.91l.06-.43a4.24 4.24 0 0 0-1.3-3.71zM9 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm2 6h11v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-8h3v-.75A2.28 2.28 0 0 1 7.28 9c.6 0 1.24.28 1.65.73L11 12zm-4 8H5v-6h2v6zm2 0h2v-6H9v6zm6-6v6h-2v-6h2zm4 6v-6h-2v6h2zM14.65 5.86a4.24 4.24 0 0 1 1.3 3.71l-.06.43h-1.91l.11-.59c.15-.79-.1-1.58-.67-2.2a4.27 4.27 0 0 1-1.37-3.78l.06-.43H14l-.09.59c-.16.83.15 1.68.74 2.27z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Hot tub</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for Pet friendly Properties">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="pet friendly icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-4.5 4a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm13-6.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zm2 6.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm-9.7-.61-5.15 5.58A3 3 0 0 0 6.85 22h.65l1.19-.13a30 30 0 0 1 6.62 0l1.19.13h.65a3 3 0 0 0 2.2-5.03l-5.15-5.58a3 3 0 0 0-4.4 0z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Pet friendly</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for cabins">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="cabin icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 19h12v-2H6v2Zm0-4h12v-2H6v2Zm0-4h12v-.9L16.55 9h-9.1L6 10.1v.9Zm4.05-4h3.9L12 5.525 10.05 7ZM4 21v-9.375L2.2 13 1 11.4l3-2.3V6h2v1.575L12 3l11 8.4-1.2 1.575-1.8-1.35V21H4ZM4 5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 7 2a.97.97 0 0 0 .713-.288A.968.968 0 0 0 8 1h2c0 .833-.292 1.542-.875 2.125A2.893 2.893 0 0 1 7 4a.968.968 0 0 0-.713.287A.968.968 0 0 0 6 5H4Z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Cabin</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for properties with an ocean view">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="ocean view icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.98 14H22h-.02ZM5.35 13c1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.4.98 3.31 1v-2c-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1v2c1.9 0 2.17-1 3.35-1Zm13.32 2c-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.1 1-3.34 1-1.24 0-1.38-1-3.33-1-1.95 0-2.1 1-3.34 1v2c1.95 0 2.11-1 3.34-1 1.24 0 1.38 1 3.33 1 1.95 0 2.1-1 3.34-1 1.19 0 1.42 1 3.33 1 1.94 0 2.09-1 3.33-1 1.19 0 1.42 1 3.33 1v-2c-1.24 0-1.38-1-3.33-1ZM5.35 9c1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.42 1 3.33 1 1.95 0 2.09-1 3.33-1 1.19 0 1.4.98 3.31 1V8c-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1-1.95 0-2.09 1-3.33 1-1.19 0-1.42-1-3.33-1C3.38 7 3.24 8 2 8v2c1.9 0 2.17-1 3.35-1Z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Ocean view</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for properties with a spa on site">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="spa icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M12.06 2a11.8 11.8 0 0 1 3.43 7.63A14.36 14.36 0 0 0 12 12.26a13.87 13.87 0 0 0-3.49-2.63A12.19 12.19 0 0 1 12.06 2zM2 10a12.17 12.17 0 0 0 10 12 12.17 12.17 0 0 0 10-12c-4.18 0-7.85 2.17-10 5.45A11.95 11.95 0 0 0 2 10z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Spa</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for villas">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="villa icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 10v2H8v-2h2Zm6 2v-2h-2v2h2Zm5 2v8H3v-8h1v-4a8 8 0 1 1 16 0v4h1ZM7 16H5v4h2v-4Zm4 0H9v4h2v-4Zm0-11.92A6 6 0 0 0 6 10v4h5V4.08ZM13 14h5v-4a6 6 0 0 0-5-5.92V14Zm2 2h-2v4h2v-4Zm4 0h-2v4h2v-4Z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Villa</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for houseboats">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="Houseboat icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M3.95 19H4c1.6 0 3.02-.88 4-2 .98 1.12 2.4 2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h.05l1.89-6.68a1.02 1.02 0 0 0-.66-1.28L20 10.62V6a2 2 0 0 0-2-2h-3V1H9v3H6a2 2 0 0 0-2 2v4.62l-1.29.42c-.53.16-.84.75-.66 1.28L3.95 19zM20 21a7.03 7.03 0 0 1-4-1.32 6.88 6.88 0 0 1-8 0A7.03 7.03 0 0 1 4 21H2v2h2c1.38 0 2.74-.35 4-.99a8.75 8.75 0 0 0 8 0 8.7 8.7 0 0 0 4 .99h2v-2h-2zM18 6H6v3.97L12 8l6 1.97V6z" clip-rule="evenodd"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">House­boat</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for chalets">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="Chalet icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 21v-3H3v-2h2v-3H2l10-9 10 9h-3v3h2v2h-2v3h-2v-3h-4v3h-2v-3H7v3H5zm2-5h4V7.6l-4 3.6V16zm6 0h4v-4.8l-4-3.6V16z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Chalet</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-stid="tracked-secondary-buttons">
                        <div>
                            <div class="button-secondary-custom-with-label" role="button" tabindex="0" aria-label="search for properties with pool">
                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-layout-grid-justify-items-center">
                                    <button aria-label="Pool icon" tabindex="-1" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-button-secondary button-secondary-custom-class">
                                        <svg class="uitk-icon uitk-icon uitk-icon-leading" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.82 11.64h.01a4.15 4.15 0 0 1 4.36 0h.01c.76.46 1.54.47 2.29 0l.41-.23L10.48 5C8.93 3.45 7.5 2.99 5 3v2.5c1.82-.01 2.89.39 4 1.5l1 1-3.25 3.25c.27.1.52.25.77.39.75.47 1.55.47 2.3 0z"></path>
                                            <path fill-rule="evenodd" d="M21.98 16.5c-1.1 0-1.71-.37-2.16-.64h-.01a2.08 2.08 0 0 0-2.29 0 4.13 4.13 0 0 1-4.36 0h-.01a2.08 2.08 0 0 0-2.29 0 4.13 4.13 0 0 1-4.36 0h-.01a2.08 2.08 0 0 0-2.29 0l-.03.02c-.47.27-1.08.62-2.17.62v-2c.56 0 .78-.13 1.15-.36a4.13 4.13 0 0 1 4.36 0h.01c.76.46 1.54.47 2.29 0a4.13 4.13 0 0 1 4.36 0h.01c.76.46 1.54.47 2.29 0a4.13 4.13 0 0 1 4.36 0h.01c.36.22.6.36 1.14.36v2z" clip-rule="evenodd"></path>
                                            <path d="M19.82 20.36c.45.27 1.07.64 2.18.64v-2a1.8 1.8 0 0 1-1.15-.36 4.13 4.13 0 0 0-4.36 0c-.75.47-1.53.46-2.29 0h-.01a4.15 4.15 0 0 0-4.36 0h-.01c-.75.47-1.55.47-2.3 0a4.15 4.15 0 0 0-4.36 0h-.01A1.8 1.8 0 0 1 2 19v2c1.1 0 1.72-.36 2.18-.63l.01-.01a2.07 2.07 0 0 1 2.3 0c1.39.83 2.97.82 4.36 0h.01a2.08 2.08 0 0 1 2.29 0h.01c1.38.83 2.95.83 4.34.01l.02-.01a2.08 2.08 0 0 1 2.29 0h.01zM19 5.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                        </svg>
                                    </button>
                                    <div class="uitk-text uitk-type-center overflow-wrap uitk-type-400 uitk-text-default-theme button-secondary-text-custom-class">Pool</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SimpleContainer">
               <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three"></div>
            </div>
            <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-three uitk-spacing-padding-inline-three">
                <div class="lazyload-wrapper LazyLoadRegion">
                    <div>
                        <div id="retail-recommendation-module-1">
                            <div>
                                <h2 class="uitk-heading uitk-heading-4 uitk-spacing uitk-spacing-margin-blockstart-unset" aria-hidden="false">Explore stays in trending destinations</h2>
                                <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                    <div class="uitk-carousel no-inset no-touch has-persistent-nav lg" data-stid="carousel-wrapper">
                                        <div class="uitk-carousel-container use-container-sizing" data-stid="carousel-container" tabindex="0">
                                            <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="1 out of 8">
                                                <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column">
                                                    <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                        <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                                            <div>
                                                                <div>
                                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                                        <figure class="uitk-image uitk-image-roundcorner-startstart uitk-image-roundcorner-startend uitk-image-ratio-16-9 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Singapore Gardens By The Bay which includes modern architecture, a park and night scenes" class="uitk-image-media" src="https://a.travel-assets.com/findyours-php/viewfinder/images/res70/39000/39529-Singapore-Gardens-By-The-Bay.jpg?impolicy=fcrop&amp;w=1000&amp;h=563&amp;p=1&amp;q=medium" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-card-content-section">
                                                                    <div class="uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-three">
                                                                        <div class="is-visually-hidden">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Singapore</div>
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Singapore</div>
                                                                        </div>
                                                                        <h3 class="uitk-heading uitk-heading-6 truncate-lines-3 uitk-spacing uitk-spacing-margin-blockstart-unset" aria-hidden="true">Singapore</h3>
                                                                        <div class="uitk-text uitk-text-spacing-one truncate-lines-3 uitk-type-300 uitk-text-default-theme" aria-hidden="true">Singapore</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-13&amp;endDate=2024-03-14&amp;regionId=3168&amp;adults=2" target="_blank" rel="noopener noreferrer" aria-label="" class="uitk-card-link">
                                                                <span class="is-visually-hidden"></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="2 out of 8">
                                                <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column">
                                                    <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                        <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                                            <div>
                                                                <div>
                                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                                        <figure class="uitk-image uitk-image-roundcorner-startstart uitk-image-roundcorner-startend uitk-image-ratio-16-9 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Double Six Beach showing tropical scenes and a beach" class="uitk-image-media" src="https://a.travel-assets.com/findyours-php/viewfinder/images/res70/42000/42454-Double-Six-Beach-Seminyak.jpg?impolicy=fcrop&amp;w=1000&amp;h=563&amp;p=1&amp;q=medium" />
                                                                            </div>
                                                                        </figure> 
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-card-content-section">
                                                                    <div class="uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-three">
                                                                        <div class="is-visually-hidden">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Seminyak</div>
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Indonesia</div>
                                                                        </div>
                                                                        <h3 class="uitk-heading uitk-heading-6 truncate-lines-3 uitk-spacing uitk-spacing-margin-blockstart-unset" aria-hidden="true">Seminyak</h3>
                                                                        <div class="uitk-text uitk-text-spacing-one truncate-lines-3 uitk-type-300 uitk-text-default-theme" aria-hidden="true">Indonesia</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-13&amp;endDate=2024-03-14&amp;regionId=6047747&amp;adults=2" target="_blank" rel="noopener noreferrer" aria-label="" class="uitk-card-link">
                                                                <span class="is-visually-hidden"></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="3 out of 8">
                                                <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column">
                                                    <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                        <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                                            <div>
                                                                <div>
                                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                                        <figure class="uitk-image uitk-image-roundcorner-startstart uitk-image-roundcorner-startend uitk-image-ratio-16-9 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Luxembourg Gardens featuring a sunset and a garden as well as a couple" class="uitk-image-media" src="https://a.travel-assets.com/findyours-php/viewfinder/images/res70/20000/20122-Luxembourg-Gardens.jpg?impolicy=fcrop&amp;w=1000&amp;h=563&amp;p=1&amp;q=medium" />
                                                                            </div>
                                                                        </figure> 
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-card-content-section">
                                                                    <div class="uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-three">
                                                                        <div class="is-visually-hidden">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Paris</div>
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">France</div>
                                                                        </div>
                                                                        <h3 class="uitk-heading uitk-heading-6 truncate-lines-3 uitk-spacing uitk-spacing-margin-blockstart-unset" aria-hidden="true">Paris</h3>
                                                                        <div class="uitk-text uitk-text-spacing-one truncate-lines-3 uitk-type-300 uitk-text-default-theme" aria-hidden="true">France</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-13&amp;endDate=2024-03-14&amp;regionId=2734&amp;adults=2" target="_blank" rel="noopener noreferrer" aria-label="" class="uitk-card-link">
                                                                <span class="is-visually-hidden"></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="4 out of 8">
                                                <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column">
                                                    <div class="uitk-layout-flex-item uitk-layout-flex-item-flex-grow-1">
                                                        <div class="uitk-layout-flex uitk-layout-flex-block-size-full-size uitk-layout-flex-flex-direction-column uitk-layout-flex-justify-content-space-between uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                                                            <div>
                                                                <div>
                                                                    <div class="uitk-layout-position uitk-layout-position-relative">
                                                                        <figure class="uitk-image uitk-image-roundcorner-startstart uitk-image-roundcorner-startend uitk-image-ratio-16-9 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="" class="uitk-image-media" src="https://mediaim.expedia.com/destination/1/574dfc983d34cf0ca1ffb20a45aca0de.jpg?impolicy=fcrop&amp;w=1000&amp;h=563&amp;p=1&amp;q=medium" />
                                                                            </div>
                                                                        </figure> 
                                                                    </div>
                                                                </div>
                                                                <div class="uitk-card-content-section">
                                                                    <div class="uitk-spacing uitk-spacing-padding-inline-three uitk-spacing-padding-blockstart-three uitk-spacing-padding-blockend-three">
                                                                        <div class="is-visually-hidden">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Mecca</div>
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">Saudi Arabia</div>
                                                                        </div>
                                                                        <h3 class="uitk-heading uitk-heading-6 truncate-lines-3 uitk-spacing uitk-spacing-margin-blockstart-unset" aria-hidden="true">Mecca</h3>
                                                                        <div class="uitk-text uitk-text-spacing-one truncate-lines-3 uitk-type-300 uitk-text-default-theme" aria-hidden="true">Saudi Arabia</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-13&amp;endDate=2024-03-14&amp;regionId=178043&amp;adults=2" target="_blank" rel="noopener noreferrer" aria-label="" class="uitk-card-link">
                                                                <span class="is-visually-hidden"></span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="uitk-carousel-controls">
                                            <button data-stid="carousel-nav-prev" aria-label="Show previous card" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-prev uitk-button-paging">
                                                <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show previous card" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <title id="prev-button-title">Show previous card</title>
                                                    <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                                </svg>
                                            </button>
                                            <button data-stid="carousel-nav-next" aria-label="Show next card" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-next uitk-button-paging">
                                                <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Show next card" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"    >
                                                    <title id="next-button-title">Show next card</title>
                                                    <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                                </svg>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                <div class="lazyload-wrapper LazyLoadRegion">
                    <div>
                        <div>
                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column">
                                <div class="uitk-spacing uitk-spacing-margin-blockstart-two">
                                    <div class="uitk-layout-flex uitk-layout-flex-justify-content-space-between uitk-layout-flex-gap-two">
                                        <h3 class="uitk-heading uitk-heading-4 overflow-wrap uitk-layout-flex-item">Last-minute weekend deals</h3>
                                        <a  class="uitk-button uitk-button-medium uitk-button-has-text uitk-button-as-link uitk-button-primary uitk-layout-flex-item-align-self-flex-start uitk-layout-flex-item uitk-layout-flex-item-flex-shrink-0">See all deals
                                            <span class="is-visually-hidden"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="uitk-spacing uitk-spacing-margin-blockstart-six">
                                <div>
                                    <span class="uitk-text uitk-type-400 uitk-text-default-theme uitk-spacing uitk-spacing-margin-inlineend-one">Showing deals for:</span>
                                    <span class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing-margin-inlineend-one">Mar 1 - Mar 3</span>
                                </div>
                                <div class="uitk-carousel no-inset no-touch items-max-height uitk-spacing uitk-spacing-margin-blockstart-three lg" data-stid="carousel-wrapper">
                                    <div class="uitk-carousel-container use-container-sizing" data-stid="carousel-container" tabindex="0">
                                        <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="1 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative">
                                                         <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for AJWA Sultanahmet</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/16000000/15470000/15463400/15463349/5913e946_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/16000000/15470000/15463400/15463349/5913e946_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                                                <span class="is-visually-hidden">Exterior</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/16000000/15470000/15463400/15463349/5913e946_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/16000000/15470000/15463400/15463349/5913e946_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                         </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex">
                                                                <span class="uitk-badge uitk-badge-large uitk-badge-vip uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                                    <span class="uitk-badge-text" aria-hidden="false">VIP Access</span>
                                                                </span>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.8/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Exceptional (600 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">AJWA Sultanahmet</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Istanbul</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"    >
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $352</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$352</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $489</div>
                                                                                         <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$489</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$788 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$788 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=15463349&amp;adults=&amp;children=" class="uitk-card-link">
                                                        <span class="is-visually-hidden">Click for more information on AJWA Sultanahmet and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="2 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Ashburn Hotel</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Front of property" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/120000/116000/115911/92fbe641_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Front of property" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/120000/116000/115911/92fbe641_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high"/>
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                                                <span class="is-visually-hidden">Front of property</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Front of property" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/120000/116000/115911/92fbe641_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Front of property" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/120000/116000/115911/92fbe641_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">8.8/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Excellent (1001 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Ashburn Hotel</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">London</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $140</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$140</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $251</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$251</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$337 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$337 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=115911&amp;adults=&amp;children=" class="uitk-card-link">
                                                        <span class="is-visually-hidden">Click for more information on Ashburn Hotel and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="3 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative">
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for The Seasons Hotels</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/96000000/95260000/95257800/95257736/8f220d60_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/96000000/95260000/95257800/95257736/8f220d60_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link">
                                                                                <span class="is-visually-hidden">Reception</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/96000000/95260000/95257800/95257736/8f220d60_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/96000000/95260000/95257800/95257736/8f220d60_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute">
                                                            <div class="uitk-layout-flex"></div>
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">7.0/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Good (6 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">The Seasons Hotels</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Medina</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $120</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$120</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $173</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$173</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$283 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$283 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=95257736&amp;adults=&amp;children=" class="uitk-card-link">
                                                        <span class="is-visually-hidden">Click for more information on The Seasons Hotels and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="uitk-carousel-item" data-stid="carousel-item" aria-label="4 out of 15" aria-hidden="true" tabindex="-1">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for The Mark</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Bar (on property)" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/19800/19712/b38d9baf_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Bar (on property)" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/19800/19712/b38d9baf_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Bar (on property)</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Bar (on property)" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/19800/19712/b38d9baf_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Bar (on property)" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/19800/19712/b38d9baf_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.6/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Exceptional (623 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">The Mark</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">New York</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge uitk-badge-large uitk-badge-deal-generic uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <span class="uitk-badge-text" aria-hidden="false">27% off</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $795</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$795</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"    >
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $1,095</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$1,095</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$1,832 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$1,832 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=19712&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on The Mark and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="5 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative">
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Lara Barut Collection - Ultra All Inclusive</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Beach bar" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1210000/1207100/1207007/62555c3d_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Beach bar" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1210000/1207100/1207007/62555c3d_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Beach bar</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Beach bar" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1210000/1207100/1207007/62555c3d_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Beach bar" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1210000/1207100/1207007/62555c3d_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute">
                                                            <div class="uitk-layout-flex">
                                                                <span class="uitk-badge uitk-badge-large uitk-badge-vip uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                                    <span class="uitk-badge-text" aria-hidden="false">VIP Access</span>
                                                                </span>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.6/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Exceptional (666 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Lara Barut Collection - Ultra All Inclusive</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Antalya</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $253</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$253</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $432</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$432</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$566 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$566 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=1207007&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Lara Barut Collection - Ultra All Inclusive and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="6 out of 15">
                                            <div>   
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Hotel Yllen Eiffel</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="View from room" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/10400/10361/eeeac806_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="View from room" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/10400/10361/eeeac806_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high"/>
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">View from room</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="View from room" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/10400/10361/eeeac806_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="View from room" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/10400/10361/eeeac806_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.6/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Exceptional (139 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Hotel Yllen Eiffel</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Paris</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $192</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$192</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"    >
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $270</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$270</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$459 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$459 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=10361&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Hotel Yllen Eiffel and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="7 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Omni Los Angeles Hotel at California Plaza</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/18600/18595/52d26995_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/18600/18595/52d26995_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Terrace/patio</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/18600/18595/52d26995_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/1000000/20000/18600/18595/52d26995_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.0/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Wonderful (1823 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Omni Los Angeles Hotel at California Plaza</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Los Angeles</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $177</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$177</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $222</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$222</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$492 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$492 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=18595&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Omni Los Angeles Hotel at California Plaza and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="8 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative">
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for The Branksome Hotel And Residences</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/20000000/19630000/19627600/19627566/b54b7337_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/20000000/19630000/19627600/19627566/b54b7337_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Exterior</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/20000000/19630000/19627600/19627566/b54b7337_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Exterior" class="uitk-image-media" src="https://images.trvl-media.com/lodging/20000000/19630000/19627600/19627566/b54b7337_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex">
                                                                <span class="uitk-badge uitk-badge-large uitk-badge-vip uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                                    <span class="uitk-badge-text" aria-hidden="false">VIP Access</span>
                                                                </span>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.2/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Wonderful (1033 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">The Branksome Hotel And Residences</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Sydney</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge uitk-badge-large uitk-badge-deal-generic uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <span class="uitk-badge-text" aria-hidden="false">27% off</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $247</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$247</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $338</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$338</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$549 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$549 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=19627566&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on The Branksome Hotel And Residences and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="9 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Alison Hotel Baku</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/86000000/85660000/85653400/85653322/f6dab415_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/86000000/85660000/85653400/85653322/f6dab415_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Reception</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/86000000/85660000/85653400/85653322/f6dab415_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/86000000/85660000/85653400/85653322/f6dab415_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">8.8/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Excellent (13 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Alison Hotel Baku</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Baku</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge uitk-badge-large uitk-badge-deal-generic uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <span class="uitk-badge-text" aria-hidden="false">26% off</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $32</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$32</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $44</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$44</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$80 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$80 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=85653322&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Alison Hotel Baku and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="10 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Ramee California Juffair</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Lobby" class="uitk-image-media" src="https://images.trvl-media.com/lodging/4000000/3690000/3688200/3688162/9bc1a54b_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Lobby" class="uitk-image-media" src="https://images.trvl-media.com/lodging/4000000/3690000/3688200/3688162/9bc1a54b_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Lobby</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Lobby" class="uitk-image-media" src="https://images.trvl-media.com/lodging/4000000/3690000/3688200/3688162/9bc1a54b_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Lobby" class="uitk-image-media" src="https://images.trvl-media.com/lodging/4000000/3690000/3688200/3688162/9bc1a54b_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">7.8/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Good (67 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Ramee California Juffair</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Manama</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $138</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$138</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $203</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$203</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$351 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$351 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=3688162&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Ramee California Juffair and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="11 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Trump International Hotel &amp; Tower Chicago</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1730000/1723300/1723215/a79e1f87_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1730000/1723300/1723215/a79e1f87_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" />
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Terrace/patio</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1730000/1723300/1723215/a79e1f87_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Terrace/patio" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1730000/1723300/1723215/a79e1f87_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex">
                                                                <span class="uitk-badge uitk-badge-large uitk-badge-vip uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                                    <span class="uitk-badge-text" aria-hidden="false">VIP Access</span>
                                                                </span>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.2/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Wonderful (1108 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Trump International Hotel &amp; Tower Chicago</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Chicago</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge uitk-badge-large uitk-badge-deal-generic uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <span class="uitk-badge-text" aria-hidden="false">20% off</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $384</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$384</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $480</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$480</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$902 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$902 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=1723215&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Trump International Hotel &amp; Tower Chicago and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="12 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for 1 Hotel Toronto</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/3000000/2930000/2920200/2920159/23374630_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/3000000/2930000/2920200/2920159/23374630_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high"/>
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Reception</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/3000000/2930000/2920200/2920159/23374630_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Reception" class="uitk-image-media" src="https://images.trvl-media.com/lodging/3000000/2930000/2920200/2920159/23374630_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.4/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Exceptional (259 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">1 Hotel Toronto</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Toronto</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0.51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $424</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$424</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $707</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$707</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$1,016 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$1,016 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=2920159&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on 1 Hotel Toronto and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="13 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Adelphi Forty-Nine</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/11000000/10620000/10610400/10610394/ab173cb4_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true" />
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/11000000/10620000/10610400/10610394/ab173cb4_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high"/>
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Pool</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/11000000/10620000/10610400/10610394/ab173cb4_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/11000000/10620000/10610400/10610394/ab173cb4_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex">
                                                                <span class="uitk-badge uitk-badge-large uitk-badge-vip uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                                    <span class="uitk-badge-text" aria-hidden="false">VIP Access</span>
                                                                </span>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.4/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Exceptional (836 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Adelphi Forty-Nine</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Bangkok</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $127</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$127</div>
                                                                            </span>
                                                                        </div>
                                                                    l  <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $201</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$201</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$299 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$299 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=10610394&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Adelphi Forty-Nine and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="14 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Al Raha Beach Hotel</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Infinity pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1280000/1276300/1276282/3c036a55_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Infinity pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1280000/1276300/1276282/3c036a55_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high"/>
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Infinity pool</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Infinity pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1280000/1276300/1276282/3c036a55_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Infinity pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/2000000/1280000/1276300/1276282/3c036a55_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex">
                                                                <span class="uitk-badge uitk-badge-large uitk-badge-vip uitk-badge-has-text uitk-spacing uitk-spacing-margin-inlineend-two uitk-layout-flex-item">
                                                                    <span class="uitk-badge-text" aria-hidden="false">VIP Access</span>
                                                                </span>
                                                            </div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">8.8/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Excellent (732 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Al Raha Beach Hotel</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Abu Dhabi</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $209</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$209</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"    >
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $298</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$298</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$516 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$516 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=1276282&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Al Raha Beach Hotel and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div aria-hidden="true" tabindex="-1" class="uitk-carousel-item" data-stid="carousel-item" aria-label="15 out of 15">
                                            <div>
                                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-spacing uitk-spacing-padding-blockend-three uitk-card-has-primary-theme">
                                                    <div class="uitk-layout-position uitk-layout-position-relative"> 
                                                        <div class="uitk-card-content-section uitk-card uitk-card-roundcorner-all uitk-card-has-primary-theme">
                                                            <div class="uitk-gallery-carousel">
                                                                <h3 class="is-visually-hidden">Image gallery for Millennium Place Doha</h3>
                                                                <div class="uitk-gallery-carousel-items ratio-4-3">
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-prev" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Indoor pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/102000000/101440000/101436100/101436077/5b63cc33_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-current" aria-hidden="false">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Indoor pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/102000000/101440000/101436100/101436077/5b63cc33_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high"/>
                                                                            </div>
                                                                            <button type="button" data-testid="uitk-gallery-item-current-trigger" class="uitk-image-link" tabindex="-1">
                                                                                <span class="is-visually-hidden">Indoor pool</span>
                                                                            </button>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-next" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Indoor pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/102000000/101440000/101436100/101436077/5b63cc33_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                    <div class="uitk-gallery-carousel-item uitk-gallery-carousel-item-queued" aria-hidden="true">
                                                                        <figure class="uitk-image uitk-image-fit-cover uitk-image-ratio-4-3 uitk-image-ratio">
                                                                            <div class="uitk-image-placeholder">
                                                                                <img alt="Indoor pool" class="uitk-image-media" src="https://images.trvl-media.com/lodging/102000000/101440000/101436100/101436077/5b63cc33_w.jpg?impolicy=fcrop&amp;w=600&amp;h=400&amp;p=1&amp;q=high" data-loaded="true"/>
                                                                            </div>
                                                                        </figure>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="uitk-layout-position uitk-layout-position-top-four uitk-layout-position-left-three uitk-layout-position-zindex-layer2 uitk-layout-position-absolute"> 
                                                            <div class="uitk-layout-flex"></div> 
                                                        </div> 
                                                    </div>
                                                    <div class="uitk-card-content-section">
                                                        <div class="uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-text uitk-type-300 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">9.6/10</span>
                                                            <span class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-spacing uitk-spacing-margin-blockstart-three uitk-spacing-margin-inlineend-one">Exceptional (5 reviews)</span>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column uitk-spacing uitk-spacing-margin-blockstart-one">
                                                            <h3 class="uitk-heading uitk-heading-6 truncate-lines-2 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Millennium Place Doha</h3>
                                                            <h4 class="uitk-heading uitk-heading-7 uitk-spacing uitk-spacing-margin-blockstart-one uitk-layout-flex-item">Doha</h4>
                                                        </div>
                                                        <div class="uitk-layout-flex uitk-layout-flex-align-items-flex-start uitk-layout-flex-flex-direction-column-reverse uitk-layout-flex-flex-wrap-wrap uitk-spacing uitk-spacing-margin-blockstart-three">
                                                            <span class="uitk-badge-base uitk-badge-base-large uitk-badge-base-has-text uitk-badge-loyalty-global-lowtier uitk-spacing uitk-spacing-margin-inlineend-two uitk-spacing-margin-blockstart-two">
                                                                <svg class="uitk-icon uitk-icon-small" aria-describedby="mod-description" aria-label="mod" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                    <title id="mod-title">mod</title>
                                                                    <desc id="mod-description">mod</desc>
                                                                    <path fill-rule="evenodd" d="m17.1 16.05 1.22-1.18 3.48-3.48a.78.78 0 0 0 .2-.6l-1.3-7.1a.57.57 0 0 0-.42-.42l-7.06-1.26a.78.78 0 0 0-.61.19L2.1 12.7a.36.36 0 0 0 0 .51l8.68 8.69c.14.13.37.14.5 0l4.24-4.23a2.88 2.88 0 0 0 4.9-2.26v-.1c0-.28-.03-1.02-.26-1.5L19 14.9a1.54 1.54 0 1 1-3 .62c-.03-.5.19-2.34.5-4.07a26.11 26.11 0 0 1 .56-2.48l.02.04a1.62 1.62 0 1 0-1.42-.12c-.13.57-.26 1.26-.41 2.1a29.62 29.62 0 0 0-.57 4.88l-.83.83-6.56-6.55 6.04-6.04c.07-.08.2-.12.3-.1l5.2.94c.09.01.18.1.2.2l.98 5.18c.02.1-.03.23-.1.3l-3.1 3.1c-.2.75-.43 2.05.3 2.31zm-6.24 3.4-6.29-6.32a.18.18 0 0 1 0-.25l1.72-1.72 6.56 6.56-1.74 1.73a.18.18 0 0 1-.25 0z" clip-rule="evenodd"></path>
                                                                </svg>
                                                                <span class="uitk-badge-base-text" aria-hidden="false">Member Price available</span>
                                                            </span>
                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-column" data-test-id="price-summary">
                                                                <div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-block-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price is $257</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-500 uitk-type-medium uitk-text-emphasis-theme">$257</div>
                                                                            </span>
                                                                        </div>
                                                                        <button type="button" class="uitk-more-info-trigger uitk-more-info-trigger-size-medium uitk-more-info-trigger-weight-regular uitk-more-info-trigger-theme-default uitk-more-info-trigger-align-leading" data-stid="disclaimer-dialog-link" tabindex="-1">
                                                                            <div class="uitk-layout-flex uitk-layout-flex-flex-direction-row-reverse">
                                                                                <svg class="uitk-icon uitk-layout-flex-item uitk-icon-small" aria-hidden="true" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"    >
                                                                                    <path fill-rule="evenodd" d="M2 12a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm11-1v6h-2v-6h2zm-1 9a8.01 8.01 0 0 1 0-16 8.01 8.01 0 0 1 0 16zm1-13v2h-2V7h2z" clip-rule="evenodd"></path>
                                                                                </svg>
                                                                                <div class="uitk-spacing uitk-spacing-padding-inline-half uitk-layout-flex-item">
                                                                                    <del>
                                                                                        <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">Price was $343</div>
                                                                                        <span aria-hidden="true">
                                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme">$343</div>
                                                                                        </span>
                                                                                    </del>
                                                                                </div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">per night</div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-spacing uitk-spacing-padding-inline-half">
                                                                            <div class="uitk-text uitk-type-300 uitk-text-default-theme is-visually-hidden">$514 total</div>
                                                                            <span aria-hidden="true">
                                                                                <div class="uitk-text uitk-type-200 uitk-type-regular uitk-text-default-theme">$514 total</div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="uitk-layout-flex uitk-layout-flex-align-items-center uitk-layout-flex-flex-direction-row uitk-layout-flex-justify-content-flex-start uitk-layout-flex-gap-one uitk-layout-flex-flex-wrap-wrap" data-test-id="price-summary-message-line">
                                                                        <div class="uitk-text uitk-type-start uitk-type-200 uitk-type-regular uitk-text-default-theme">includes taxes &amp; fees</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a href="https://www.expedia.com/Hotel-Search?startDate=2024-03-01&amp;endDate=2024-03-03&amp;selected=101436077&amp;adults=&amp;children=" class="uitk-card-link" tabindex="-1">
                                                        <span class="is-visually-hidden">Click for more information on Millennium Place Doha and other properties</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    {/* <div class="uitk-carousel-controls">
                                        <button data-stid="carousel-nav-prev" aria-label="Previous" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-prev uitk-button-paging">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Previous" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title id="prev-button-title">Previous</title>
                                                <path d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"></path>
                                            </svg>
                                        </button>
                                        <button data-stid="carousel-nav-next" aria-label="Next" tabindex="0" type="button" class="uitk-button uitk-button-medium uitk-button-only-icon uitk-carousel-button-paging uitk-carousel-button-next uitk-button-paging">
                                            <svg class="uitk-icon uitk-icon-leading uitk-icon-directional" aria-label="Next" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title id="next-button-title">Next</title>
                                                <path d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z"></path>
                                            </svg>
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SimpleContainer">
                <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                        <figure class="uitk-image uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-image-ratio-3-2 uitk-image-ratio figure-tree">
                            <div class="uitk-image-placeholder">
                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/footer/bnaBanners/BEX_Footer.jpg"/>
                            </div>
                        </figure>
                        <div class="uitk-card-content-section uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-tree">
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-padding-six">
                                <div role="region" aria-labelledby="marquee-title" class="uitk-layout-grid-item uitk-layout-grid-item-align-self-start uitk-layout-grid-item-has-column-start width-q">
                                    <h2 class="uitk-heading uitk-heading-3" id="marquee-title">Go further with the Mufti Travel app</h2>
                                    <div class="uitk-text uitk-type-300 uitk-text-default-theme uitk-spacing uitk-type-paragraph-300 uitk-spacing-padding-blockstart-two uitk-spacing-padding-inlineend-four">Save on select hotels and earn OneKeyCash on bookings in the app. Our app deals help you to save on trips so you can travel more and manage it all on the go.</div>
                                    <div class="uitk-text uitk-type-400 uitk-type-bold uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockstart-twelve uitk-spacing-padding-inlineend-four uitk-spacing-padding-blockend-two">Scan the QR code with your device camera and download our app</div>
                                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid switch">
                                        <div class="uitk-spacing uitk-spacing-padding-blockstart-six uitk-layout-grid-item uitk-layout-grid-item-has-column-start">
                                            <a id="download-btn" name="download-btn" href="https://expe.app.link/?~customer_ad_set_name=EXPEDIA-US&amp;~customer_ad_name=BRAND.EXPEDIA.OWNED.BRANCH.DEFAULT.MAD&amp;~customer_campaign=BRAND&amp;~customer_placement=BEX-HP-MARQUEEDESKTOP&amp;~placement_id=Homepage&amp;%24canonical_url=https%3A%2F%2Fwww.expedia.com%2F&amp;%24desktop_url=https%3A%2F%2Fwww.expedia.com%2Fapp&amp;custom_web_attribute=add49b7a-17c6-412c-957e-78218a70f45a" class="uitk-button uitk-button-large uitk-button-fullWidth uitk-button-has-text uitk-button-as-link uitk-button-primary">Switch to the app</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start QR-code">
                                    <div class="uitk-spacing uitk-spacing-padding-inlinestart-four">
                                        <figure class="uitk-image uitk-image-ratio-1-1 uitk-image-ratio">
                                            <div class="uitk-image-placeholder">
                                                <img alt="QR code" class="uitk-image-media" src="https://a.travel-assets.com/mad-service/qr-code/footer_qr_hp/1_QR_SHEET_CONTROL_FOOTER_HP.png"/>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SimpleContainer">
                <div class="uitk-spacing NativeAdContainerRegion uitk-spacing-padding-medium-inline-three uitk-spacing-padding-small-inlinestart-three" id="native-ad-NMC1">
                    <div class="">
                        <meso-native class="is-visually-hidden" aria-hidden="true" uci="NMC1" nativetype="native-ad" isfluid="true" adslot="NMC1" data-testid="meso-native" adunitpath="/expedia.us_en/home/all" timeout="5000" slot="NMC1"></meso-native>
                        <div class="fallback" aria-hidden="false" data-testid="native-ad-container"></div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SpacingContainer inlineSpacingContainerUnset uitk-spacing-padding-block-six uitk-spacing-padding-inline-unset">
                <div class="uitk-spacing SimpleContainer">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid GridContainer">
                        <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start" >
                            <div class="uitk-spacing SimpleContainer">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"   >
                                    <div class="uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset" id="editorial-2" data-fm="editorial-2">
                                        <div>
                                            <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme">
                                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid"    >
                                                    <div data-testid="image-media-content" class="uitk-layout-grid-item">
                                                        <figure class="uitk-image uitk-card-roundcorner-all uitk-image-ratio-3-2 uitk-image-ratio">
                                                            <div class="uitk-image-placeholder">
                                                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/travel-assets-manager/cread-2739-image-resize-prop/Madrid_0390_384x256.jpg"/>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                    <div data-testid="editorial-content" class="editorialContent ">
                                                        <div class="uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three" data-fm-content="63937265" id="63937265">
                                                            <div class="uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one">EARN UP TO 10X ONEKEYCASH</div>
                                                            <h2 class="uitk-heading uitk-heading-6">Earn more OneKeyCash on your flight when you book a package</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="https://www.expedia.com/Vacation-Packages?rfrr=Loyalty.HP.TileStack3x1" class="uitk-card-link">
                                                    <span class="is-visually-hidden">EARN UP TO 10X ONEKEYCASH</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                            <div class="uitk-spacing SimpleContainer">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"   >
                                    <div class="uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset" id="editorial-3" data-fm="editorial-3">
                                        <div>
                                            <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme">
                                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid"    >
                                                    <div data-testid="image-media-content" class="uitk-layout-grid-item">
                                                        <figure class="uitk-image uitk-card-roundcorner-all uitk-image-ratio-3-2 uitk-image-ratio">
                                                            <div class="uitk-image-placeholder">
                                                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/travel-assets-manager/cread-2735/BEX-BucketList2024-HP-Card-DM-384x256.jpg"/>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                    <div data-testid="editorial-content" class="editorialContent ">
                                                        <div class="uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three" data-fm-content="64077986" id="64077986">
                                                            <div class="uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one">2024 BEST BUCKET LIST TRIPS</div>
                                                            <h2 class="uitk-heading uitk-heading-6">See our curated list</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="https://www.expedia.com/lp/b/dream-destinations-2024?rfrr=BucketList.HP.TileStack3x2" class="uitk-card-link">
                                                    <span class="is-visually-hidden">2024 BEST BUCKET LIST TRIPS</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                            <div class="uitk-spacing SimpleContainer">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"   >
                                    <div class="uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset" id="editorial-4" data-fm="editorial-4">
                                        <div>
                                            <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme">
                                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid"    >
                                                    <div data-testid="image-media-content" class="uitk-layout-grid-item">
                                                        <figure class="uitk-image uitk-card-roundcorner-all uitk-image-ratio-3-2 uitk-image-ratio">
                                                            <div class="uitk-image-placeholder">
                                                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/travel-assets-manager/cread-2595/BEX-TripPlanner-HP-Card-DM-384x256.jpg"/>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                    <div data-testid="editorial-content" class="editorialContent ">
                                                        <div class="uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three" data-fm-content="64077991" id="64077991">
                                                            <div class="uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one">INTRODUCING TRIP PLANNER</div>
                                                            <h2 class="uitk-heading uitk-heading-6">Save, collaborate, and book with Trip Planner</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="https://www.expedia.com/why/trip-planning?rfrr=TripPlanner.HP.TileStack3x3" class="uitk-card-link">
                                                    <span class="is-visually-hidden">book for spring</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SpacingContainer inlineSpacingContainerUnset uitk-spacing-padding-block-six uitk-spacing-padding-inline-unset">
                <div class="uitk-spacing SimpleContainer">
                    <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-display-grid GridContainer uitk-two-element">
                        <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                            <div class="uitk-spacing SimpleContainer">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"    >
                                    <div class="uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset" id="editorial-5" data-fm="editorial-5">
                                        <div>
                                            <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme">
                                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid"    >
                                                    <div data-testid="image-media-content" class="uitk-layout-grid-item">
                                                        <figure class="uitk-image uitk-card-roundcorner-all uitk-image-ratio-16-9 uitk-image-ratio">
                                                            <div class="uitk-image-placeholder">
                                                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/travel-assets-manager/cread-3037/BEX-TopSpring-US-CA-AU-NZ-HP-Card-DM-457x257.jpg"/>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                    <div data-testid="editorial-content" class="editorialContent ">
                                                        <div class="uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three" data-fm-content="64077897" id="64077897">
                                                            <div class="uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one">STANDOUT SPRING ESCAPES</div>
                                                            <h2 class="uitk-heading uitk-heading-6">Get planning</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="https://www.expedia.com/magazine/top-spring-destinations/?rfrr=TopSpringDestinations.HP.TileStack2x1" class="uitk-card-link">
                                                    <span class="is-visually-hidden">STANDOUT SPRING ESCAPES</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                            <div class="uitk-spacing SimpleContainer">
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"    >
                                    <div class="uitk-spacing Editorial editorialSpacing uitk-spacing-padding-small-blockend-twelve uitk-spacing-padding-medium-blockend-unset" id="editorial-6" data-fm="editorial-6">
                                        <div>
                                            <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link noBackground cardWithLinkText uitk-card-has-primary-theme">
                                                <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-display-grid"    >
                                                    <div data-testid="image-media-content" class="uitk-layout-grid-item">
                                                        <figure class="uitk-image uitk-card-roundcorner-all uitk-image-ratio-16-9 uitk-image-ratio">
                                                            <div class="uitk-image-placeholder">
                                                                <img alt="" class="uitk-image-media" src="https://a.travel-assets.com/travel-assets-manager/cread-2923/BEX_DB_GreatValueFlights-HP-Card-DM-457x257.jpg"/>
                                                            </div>
                                                        </figure>
                                                    </div>
                                                    <div data-testid="editorial-content" class="editorialContent ">
                                                        <div class="uitk-layout-grid-item uitk-text uitk-type-300 uitk-type-regular uitk-text-default-theme uitk-spacing uitk-spacing-padding-block-three" data-fm-content="63930692" id="63930692">
                                                            <div class="uitk-text uitk-type-200 uitk-text-default-theme uitk-spacing uitk-spacing-padding-blockend-one">FLIGHT DEALS FOR 2024</div>
                                                            <h2 class="uitk-heading uitk-heading-6">Fly somewhere warm, sunny, and bright</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="https://www.expedia.com/lp/b/greatvalueflights?rfrr=GreatValueFlights.HP.TileStack2x2" class="uitk-card-link">
                                                    <span class="is-visually-hidden">FLIGHT DEALS FOR 2024</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uitk-spacing SimpleContainer">
                <div id="partner-gallery-1" data-testid="partner-gallery">
                    <div data-eg-sc="true" data-meso-viewed="true">
                        <div class="LazyLoad is-visible">
                            <hr class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr"/>
                            <div class="uitk-layout-grid uitk-layout-grid-has-areas uitk-layout-grid-has-areas-by-medium uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-medium uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing uitk-spacing-margin-block-three uitk-style"    >
                                <div class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-layout-grid-item-has-column-end uitk-layout-grid-item-has-row-start uitk-layout-grid-item-has-row-end">
                                    <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                        <span class="is-visually-hidden">Open in same window</span>
                                        <h2 class="uitk-heading uitk-heading-5">Find your slice of paradise in Punta Cana</h2>
                                    </a>
                                    <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                        <span class="is-visually-hidden">Open in same window</span>
                                        <p class="uitk-paragraph uitk-paragraph-2">Create unforgettable memories with an idyllic escape - these hotels are awaiting your arrival.</p>
                                    </a>
                                </div>
                            </div>
                            <div class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-space uitk-layout-grid-display-grid">
                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-card-has-primary-theme">
                                    <figure class="uitk-image uitk-image-ratio-21-9 uitk-image-ratio">
                                        <div class="uitk-image-placeholder">
                                            <img alt="Ad" class="uitk-image-media" src="https://tpc.googlesyndication.com/simgad/828231536056687653?"/>
                                        </div>
                                        <div class="uitk-scrim fade-bottom">
                                            <div class="uitk-text in-bottom-scrim">Riu Hotels and Resorts</div>
                                        </div>
                                        <a class="uitk-image-link" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                            <span class="is-visually-hidden">Open in same window</span>
                                            <span class="is-visually-hidden"></span>
                                        </a>
                                    </figure>
                                    <a class="uitk-card-link" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                        <span class="is-visually-hidden">Open in same window</span>
                                        <span class="is-visually-hidden"></span>
                                    </a>
                                </div>
                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-card-has-primary-theme"    >
                                    <figure class="uitk-image uitk-image-ratio-21-9 uitk-image-ratio">
                                        <div class="uitk-image-placeholder">
                                            <img alt="Ad" class="uitk-image-media" src="https://tpc.googlesyndication.com/simgad/14321566993403784233?"/>
                                        </div>
                                        <div class="uitk-scrim fade-bottom">
                                            <div class="uitk-text in-bottom-scrim">Secrets Resorts and Spas</div>
                                        </div>
                                        <a class="uitk-image-link" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                            <span class="is-visually-hidden">Open in same window</span>
                                            <span class="is-visually-hidden"></span>
                                        </a>
                                    </figure>
                                    <a class="uitk-card-link" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                        <span class="is-visually-hidden">Open in same window</span>
                                        <span class="is-visually-hidden"></span>
                                    </a>
                                </div>
                                <div class="uitk-card uitk-card-roundcorner-all uitk-card-has-link uitk-layout-grid-item uitk-layout-grid-item-has-column-start uitk-card-has-primary-theme"    >
                                    <figure class="uitk-image uitk-image-ratio-21-9 uitk-image-ratio">
                                        <div class="uitk-image-placeholder">
                                            <img alt="Ad" class="uitk-image-media" src="https://tpc.googlesyndication.com/simgad/15108923715973843726?"/>
                                        </div>
                                        <div class="uitk-scrim fade-bottom">
                                            <div class="uitk-text in-bottom-scrim">More hotels</div>
                                        </div>
                                        <a class="uitk-image-link" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                            <span class="is-visually-hidden">Open in same window</span>
                                            <span class="is-visually-hidden"></span>
                                        </a>
                                    </figure>
                                    <a class="uitk-card-link" target="_blank" href="https://adclick.g.doubleclick.net/pcs/click?xai=AKAOjsuqlZhsidR9F6TouyXB9lkxoknuWPmVA6fOl9y-hFGhH_R5GioZ579kPly1Tdxe4SHbX7EZlL_D09tR53RdxXPiBW6uty5J3g8F2XzuHeJmtFC6MFlkR3UbFv__3M6LNkBd_e_CNESe8SPtaPGK4-BncNBU1bCz3XiMOYLhVyKw1rTD_2aJUbnkgqDqnPKeFY9La7C_6W7bO5h0toQBdG7CejbL6zGYaxLq3BBl7F0g4cwrbBzQV5jG2KlNhRdhdbjSUW7sL-ew36ea5pfFVOXgquEMRyG5TEgFpPZ6iOEZ34nlKB-2xFUxUeATzw-6c2okaEJMWoc0dScj&amp;sai=AMfl-YTplEjlSQEBzj0Z5nN4CEA6-GqV6UtVDJ5Rx8PVISoRzduvUEZRA1XtxU1MFmGFr-jgxme_sY6_YWdUm4g1KBnYVkiruTsjrj-scqyhNlA&amp;sig=Cg0ArKJSzFLz9o7_E4G-EAE&amp;fbs_aeid=%5Bgw_fbsaeid%5D&amp;urlfix=1&amp;adurl=https://expedia.com/see/puntacana2024?utm_source=native&amp;utm_medium=onsite&amp;utm_campaign=puntacana2024&amp;utm_campaign=v1home" rel="noopener noreferrer">
                                        <span class="is-visually-hidden">Open in same window</span>
                                        <span class="is-visually-hidden"></span>
                                    </a>
                                </div>
                            </div>
                            <hr class="uitk-spacing uitk-spacing-margin-block-six uitk-spacing-border-blockstart uitk-spacing-hr"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="uitk-spacing SpacingContainer uitk-spacing-padding-block-six uitk-spacing-padding-inline-three">
                <div class="TabGroup" data-fm="1948240">
                    <div class="uitk-card-content-section uitk-card-content-section-padded uitk-card uitk-card-roundcorner-all uitk-card-has-border uitk-card-has-primary-theme">
                        <div>
                            <div class="uitk-tabs-container">
                                <ul class="uitk-tabs uitk-tabs-natural background-primary uitk-tabs-default uitk-tabs-with-border" role="tablist">
                                    <li data-stid="uitk-tab-active" role="presentation" class="uitk-tab active">
                                        <a href="#" class="uitk-tab-anchor uitk-tab-anchor-selected" aria-controls="tab-57xwexkkd-0" aria-selected="true" id="tab-tab-group-1-tab-1" data-section-id="#section-tab-group-1-tab-1" role="tab" data-toggle="tab" draggable="false">
                                            <span class="uitk-tab-text">Explore a world of travel with Expedia</span>
                                        </a>
                                    </li>
                                    <li aria-hidden="true" class="uitk-tab-highlighter"></li>
                                </ul>
                                <div class="uitk-tabs-content">
                                    <div role="tabpanel" class="uitk-tabs-pane active" id="tab-57xwexkkd-0">
                                        <div>
                                            <div class="uitk-spacing Links" id="links-1" data-fm="links-1">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Hotel deals on top domestic destinations</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two">
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Las Vegas hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New York hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Orlando hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Miami hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Los Angeles hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Chicago hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Nashville hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Myrtle Beach hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New Orleans hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Key West hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">San Francisco hotels </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-2" data-fm="links-2">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Book hotels internationally</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cabo San Lucas hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">London hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Paris hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Barcelona hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cancun hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Dubai hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Tokyo hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">San Juan hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Los Cabos hotels </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Riviera Maya hotels </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-3" data-fm="links-3">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top Domestic Flights</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Las Vegas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Hawaii </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to New York </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Orlando </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Miami </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Los Angeles </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Maui </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Houston </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Dallas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Denver </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-4" data-fm="links-4">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top International Flights</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Puerto Rico </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Tokyo </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Kannur </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Mexico City </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to London </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Cancun </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Manila </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Cabo San Lucas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Bali </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Flights to Bora Bora </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-5" data-fm="links-5">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Save today on vacation packages</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Las Vegas vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Hawaii vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New York vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Miami vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Disneyland vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Florida vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Orlando vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">New Orleans vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Key West vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Maui vacation packages </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>  
                                            <div class="uitk-spacing Links" id="links-6" data-fm="links-6">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Book international flights &amp; hotels together to save</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cancun vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Punta Cana vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Aruba vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Europe vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Caribbean vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Bahamas vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Italy vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">London vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Turks and Caicos vacation packages </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Paris vacation packages </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-7" data-fm="links-7">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top places to visit domestically</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Los Angeles </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Las Vegas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit New York </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Chicago </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Orlando </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Dallas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Atlanta </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Phoenix </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Miami </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Houston </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-8" data-fm="links-8">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Visit places internationally</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit the Bahamas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Vancouver </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Mexico </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Cabo San Lucas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Cancun </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Punta Cana </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Australia </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit London </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Aruba </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Visit Paris </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-9" data-fm="links-9">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Airport &amp; car rental deals</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Los Angeles </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Orlando </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Las Vegas </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Denver </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Phoenix </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Chicago </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Fort Lauderdale </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in New York </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Atlanta </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Houston </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="uitk-spacing Links" id="links-10" data-fm="links-10">
                                                <div class="uitk-card-content-section">
                                                    <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Find cheap car rentals at international airports</h3>
                                                    <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Dublin </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Vancouver </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Toronto </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Toronto </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Dubai </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Paris </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Cancun </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Puerto Rico </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Rome </a>
                                                        </li>
                                                        <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                            <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Car rentals in Calgary </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        <div class="uitk-spacing Links" id="links-11" data-fm="links-11">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Top Vacation Rental Destinations</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">United States Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Florida Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals in North Carolina </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals in South Carolina </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Outer Banks Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Myrtle Beach Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Destin Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Panama City Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Hilton Head Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Gulf Shores Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Key West Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Maui Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Orlando Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Pigeon Forge Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Anna Maria Island vacation rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Lake of the Ozarks Vacation Rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Rentals by the beach </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Luxury vacation rentals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation rentals near by </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-12" data-fm="links-12">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Popular cruise destinations</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Bahamas </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Mexico </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Hawaii </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Alaska </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Caribbean </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Europe </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Bermuda </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Africa </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Australia &amp; New Zealand </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises to Canada &amp; New England </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-13" data-fm="links-13">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Cruise departure ports nearby</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Galveston </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Miami </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from New York </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Tampa </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Long Beach </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Boston </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Charleston </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Port Canaveral </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from San Francisco </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Cruises from Norfolk </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-14" data-fm="links-14">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">Other Vacation ideas</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Travel Trends 2024 </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Christmas Vacation Deals </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Weekend Getaways </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Plan your Vacation </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Vacation Deposit and Payment Plan </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Expedia Group wildlife guidelines </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">All Inclusive Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Ski Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Beach Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Golf Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Kid Friendly Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Luxury Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Romantic Vacations </a>
                                                    </li>
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a  class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">Adventure Vacations </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="uitk-spacing Links" id="links-15" data-fm="links-15">
                                            <div class="uitk-card-content-section">
                                                <h3 class="uitk-heading uitk-heading-5 uitk-spacing uitk-spacing-margin-inline-three">More destinations</h3>
                                                <ul class="uitk-layout-grid uitk-layout-grid-has-auto-columns uitk-layout-grid-has-columns uitk-layout-grid-has-columns-by-large uitk-layout-grid-has-space uitk-layout-grid-display-grid uitk-spacing no-bullet uitk-spacing-padding-inline-two uitk-spacing-padding-block-two"    >
                                                    <li class="uitk-layout-grid-item uitk-layout-grid-item-has-column-start"  >
                                                        <a class="uitk-link uitk-link-align-left uitk-link-layout-default uitk-link-medium" role="link">All Destinations </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
      </div>
    </>
  )
}

export default IndexComponents;
